import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Container } from "react-bootstrap";

import { AlertInfo } from "../components/alerts/AlertInfo";

import { getHomePageInfo } from "../store/homePageInfo/selectors";
import { changeHomePageInfo } from "../store/homePageInfo/actions";
import { parseJwt } from "../utils/parseJwt";
import { getHomePageByRole } from "../utils/getHomePageByRole";
import { getTokenAge } from "../utils/getTokenAge";
import { refreshToken } from "../utils/refreshToken";

import { tokenUpdatePeriod } from "../types/tokenUpdatePeriod";
import { Pages } from "../types/pages";

class InitialPresenter extends PureComponent {
    render() {
        const homePage = this.props.homePage;

        if (homePage === "/") {
            this.refreshToken();

            return (
                <Container>
                    <AlertInfo text="Ваша учётная запись проходит проверку администратором" />
                </Container>
            );
        }

        if (homePage) {
            return <Redirect to={homePage} />;
        }

        return <Redirect to={Pages.AUTHORIZATION} />;
    }

    refreshToken() {
        if (getTokenAge() < tokenUpdatePeriod.unverifiedAccount) {
            return;
        }

        refreshToken().then(result => {
            if (!result) {
                return;
            }

            const userInfo = parseJwt(result.accessToken);
            const homePage = getHomePageByRole(userInfo);

            this.props.changeHomePageInfo(homePage);

            if (homePage !== "/") {
                window.location = "/";
            }
        });
    }
}

const mapStateToProps = state => ({
    homePage: getHomePageInfo(state)
});

const mapDispatchToProps = dispatch => ({
    changeHomePageInfo: hp => dispatch(changeHomePageInfo(hp))
});

export const Initial = connect(
    mapStateToProps,
    mapDispatchToProps
)(InitialPresenter);
