import React, { PureComponent } from "react";
import { connect } from "react-redux";

import FormControl from "react-bootstrap/FormControl";

import { ServerErrorMessages } from "../types/serverErrorMessages";
import { authorizedFetch } from "../utils/authorizedFetch";
import { changeWarning } from "../store/warning/actions";
import config from "../config";

import "./FieldUpdateTextInput.scss";

// TODO: Add comments and examples to regular expressions
export const patterns = {
    integer: "^\\s*\\d+\\s*$",
    number: "^\\s*\\d+(?:[.,]\\d+)?\\s*$",
    phone:
        "^(?:(?:(?:(?:(?:\\+7)|8)[\\s\\-‐−‒-―]{0,3}(?:\\d[\\s\\-‐−‒-―]{0,3}){3})?(?:[0-9][\\s\\-‐−‒-―]{0,3}){7})|(?:[\\-‐−‒-―]{0,20}))$",
    time: "^(?:[0-1][0-9]|2[0-3]):[0-5][0-9]$",
    carNumber: "^[хреновмускат][0-9]{3}[хреновмускат]{2}\\s{0,3}[0-9]{2,3}$"
};

const partialPatterns = {
    integer: /^\s*\d+\s*$/,
    number: /^\s*\d+(?::[.,](?::\d+)?)?\s*$/,
    phone: /^\+?[\d\s\-‐−‒-―]{0,20}$/,
    time: /^(?:[0-1][0-9]?|2[0-3]?)(?::(?:[0-5][0-9]?)?)?$/,
    carNumber: /^[хреновмускат\d\s]{0,13}?$/
};

export class FieldUpdateTextInputPresenter extends PureComponent {
    constructor(props) {
        super(props);

        const value =
            props.value === undefined || props.value === null
                ? ""
                : props.value;
        this.state = {
            value: value,
            savedValue: value,
            style: {},
            isValid: this.isValidValue(value)
        };

        this.controlRef = React.createRef();
        this.saveThrottlingTimerId = null;
        this.errorValue = undefined;

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.saveValue = this.saveValue.bind(this);
        this.onBlurHandler = this.onBlurHandler.bind(this);
        this.onFocusHandler = this.onFocusHandler.bind(this);
        this.adaptHeight = this.adaptHeight.bind(this);
    }

    componentDidMount() {
        if (this.props.multiline) {
            this.adaptHeight();
        }
    }

    render() {
        return (
            <FormControl
                ref={this.controlRef}
                as={this.props.multiline ? "textarea" : "input"}
                className={`text-input ${this.state.isValid ? "" : "invalid"} ${
                    this.state.value !== this.state.savedValue
                        ? "saving"
                        : "saving-done"
                } ${this.props.className || ""}`}
                style={this.state.style}
                type={this.props.type}
                pattern={
                    this.props.patternType && patterns[this.props.patternType]
                }
                placeholder={this.props.placeholder}
                title={this.props.title}
                maxLength={this.props.maxLength}
                value={this.state.value}
                onChange={this.onChangeHandler}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
            />
        );
    }

    onChangeHandler(event) {
        const partialPattern =
            !this.props.notStrictPattern &&
            this.props.patternType &&
            partialPatterns[this.props.patternType];
        const value = event.target.value;

        if (
            partialPattern &&
            value.length > this.state.value.length &&
            !partialPattern.test(value)
        ) {
            return;
        }

        if (value === this.state.savedValue) {
            this.errorValue = undefined;
        }

        if (this.props.onInput) {
            this.props.onInput(value);
        }

        this.setState({ value: value, isValid: true });
    }

    onFocusHandler() {
        this.saveThrottlingTimerId = setInterval(() => {
            this.saveValue();
        }, 1000);
    }

    onBlurHandler() {
        clearTimeout(this.saveThrottlingTimerId);
        this.saveThrottlingTimerId = null;
        this.saveValue();
    }

    saveValue() {
        if (
            !this.props.url ||
            this.state.value === this.state.savedValue ||
            this.state.value === this.errorValue
        ) {
            return;
        }

        const that = this;
        const value = this.state.value || (this.props.nullable ? null : "");

        authorizedFetch(
            `${config.protocolAndHost}/api/data/${this.props.url}`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    [this.props.fieldName]: value
                })
            }
        ).then(
            result => {
                if (result.status === 200) {
                    this.errorValue = undefined;

                    that.setState({
                        savedValue: value || "",
                        isValid: that.isValidValue(value)
                    });

                    if (that.props.onChange) {
                        that.props.onChange(value);
                    }
                } else {
                    that.onError(ServerErrorMessages[result.status]);
                }
            },
            () => {
                that.onError();
            }
        );
    }

    onError(msg = "Не удалось сохранить изменения") {
        this.props.changeWarning(msg);
        this.errorValue = this.state.value;
    }

    isValidValue(value) {
        if (this.props.required && !value && value !== 0) {
            return false;
        }

        const pattern =
            this.props.patternType && patterns[this.props.patternType];

        return value && pattern ? RegExp(pattern).test(value) : true;
    }

    adaptHeight() {
        this.setState({
            style: {
                height: this.controlRef.current.scrollHeight || "auto"
            }
        });
    }
}

const mapDispatchToProps = dispatch => ({
    changeWarning: warning => dispatch(changeWarning(warning))
});

export const updateInputConnect = Component => {
    return connect(null, mapDispatchToProps, null, { forwardRef: true })(
        Component
    );
};

export const FieldUpdateTextInput = updateInputConnect(
    FieldUpdateTextInputPresenter
);
