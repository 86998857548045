import React from "react";
import { connect } from "react-redux";

import { Button, Modal } from "react-bootstrap";
import { Header } from "./components/Header";
import { MainRouting } from "./pages/MainRouting";

import { getTokenAge } from "./utils/getTokenAge";
import { refreshToken } from "./utils/refreshToken";
import { parseJwt } from "./utils/parseJwt";
import { getHomePageByRole } from "./utils/getHomePageByRole";
import { changeHomePageInfo } from "./store/homePageInfo/actions";
import { getWarning } from "./store/warning/selectors";
import { removeWarning } from "./store/warning/actions";

import { tokenUpdatePeriod } from "./types/tokenUpdatePeriod";

import "./App.css";
import "./theme.scss";

export function AppPresenter(props) {
    if (
        localStorage.getItem("token") &&
        getTokenAge() >= tokenUpdatePeriod.verifiedAccount
    ) {
        refreshToken().then(result => {
            if (!result) {
                return;
            }

            const userInfo = parseJwt(result.accessToken);
            const homePage = getHomePageByRole(userInfo);

            props.changeHomePageInfo(homePage);
        });
    }

    return (
        <div>
            <Header />
            <MainRouting />

            <Modal
                key="warning"
                show={Boolean(props.warning)}
                onHide={props.removeWarning}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ошибка</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.warning}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.removeWarning}>
                        Ок
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

const mapStateToProps = state => ({
    warning: getWarning(state)
});

const mapDispatchToProps = dispatch => ({
    removeWarning: () => dispatch(removeWarning()),
    changeHomePageInfo: hp => dispatch(changeHomePageInfo(hp))
});

export const App = connect(mapStateToProps, mapDispatchToProps)(AppPresenter);
