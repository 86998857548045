import React from "react";
import { MdAccessTime, MdCached, MdDone } from "react-icons/md";

export const Status = {
    WAITING: "WAITING",
    DONE: "DONE",
    RELOADING: "RELOADING"
};

export const StatusIcon = {
    [Status.WAITING]: <MdAccessTime size={30} className="text-primary" />,
    [Status.DONE]: <MdDone size={30} className="text-secondary" />,
    [Status.RELOADING]: <MdCached size={30} className="text-success" />
};
