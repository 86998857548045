import { Roles } from "../types/roles";
import { HomePages } from "../types/homePages";

export function getHomePageByRole(userInfo) {
    const roles = userInfo.roles;

    if (roles.indexOf(Roles.ADMIN) !== -1) {
        return HomePages[Roles.ADMIN];
    }

    if (roles.indexOf(Roles.DISPATCHER) !== -1) {
        return HomePages[Roles.DISPATCHER];
    }

    if (roles.indexOf(Roles.COORDINATOR) !== -1) {
        const homePointId = userInfo.homePointId;
        return homePointId
            ? `/points/${homePointId}`
            : HomePages[Roles.COORDINATOR];
    }

    return "/";
}
