import React from "react";
import { Switch, Route } from "react-router-dom";
import { Points } from "./Points";
import { Point } from "./Point";

export function PointsRouting() {
    return (
        <Switch>
            <Route path="/points/:pointId" component={Point} />
            <Route exact path="/points" component={Points} />
        </Switch>
    );
}
