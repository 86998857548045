import React, { PureComponent } from "react";

import "./SuggestWrapper.scss";

export class SuggestWrapper extends PureComponent {
    constructor(props) {
        super(props);

        this.onSelectChange = this.onSelectChange.bind(this);
    }

    render() {
        return (
            <div
                className={`suggest-wrapper ${
                    this.props.showSuggest ? "" : "hide-suggest"
                }`}
                onClick={this.props.onShow}
                onBlur={this.props.onHide}
            >
                {this.props.children}
                <ul className={"suggest"}>
                    {this.props.suggestValues.map(value => (
                        <li
                            key={value}
                            data-value={value}
                            className={"suggest-option"}
                            onMouseDown={this.onSelectChange}
                        >
                            {value}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    onSelectChange(event) {
        const value = event.currentTarget.getAttribute("data-value");

        this.props.onChange(value);
    }
}
