import { Component } from "react";
import React from "react";
import { MdPhoneInTalk } from "react-icons/md";

import "./Buttons.css";

const noPropagation = e => e.stopPropagation();

export class CallButton extends Component {
    render() {
        return (
            <a
                className="btn btn-contact btn-outline-primary"
                href={`tel:${this.props.phoneNumber}`}
                onClick={noPropagation}
            >
                <MdPhoneInTalk className="btn-inner" />
            </a>
        );
    }
}
