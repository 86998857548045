import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { Initial } from "./Initial";
import { Admin } from "./Admin";
import { GoogleDocsSync } from "./GoogleDocsSync";
import { Dispatcher } from "./Dispatcher";
import { Authorization } from "./Authorization";
import { Registration } from "./Registration";
import { Movement } from "./Movement";
import { Volunteers } from "./Volunteers";
import { Privacy } from "./Privacy";
import { NotFound } from "./NotFound";
import { PointsRouting } from "./PointsRouting";

import { Pages } from "../types/pages";

export function MainRouting() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Initial} />
                <Route path={Pages.AUTHORIZATION} component={Authorization} />
                <Route path={Pages.REGISTRATION} component={Registration} />
                <Route path={Pages.PRIVACY} component={Privacy} />
                <Route path={Pages.ADMIN} component={Admin} />
                <Route
                    path={Pages.GOOGLE_DOCS_SYNC}
                    component={GoogleDocsSync}
                />
                <Route path={Pages.DISPATCHER} component={Dispatcher} />
                <Route path={Pages.POINTS} component={PointsRouting} />
                <Route
                    path={`${Pages.MOVEMENTS}/:movementId`}
                    component={Movement}
                />
                <Route
                    path={`${Pages.VOLUNTEERS}/:pointId`}
                    component={Volunteers}
                />
                <Route path="" component={NotFound} />
            </Switch>
        </BrowserRouter>
    );
}
