import React, { PureComponent } from "react";
import { Col, Container, ListGroup, Row, Spinner } from "react-bootstrap";
import { MdError, MdWarning } from "react-icons/md";

import { getData } from "../../utils/getData";
import config from "../../config";

import "./ChecksView.scss";

export class ChecksView extends PureComponent {
    state = {};

    constructor(props) {
        super(props);

        this.renderItem = this.renderItem.bind(this);
    }

    componentDidMount() {
        this.getChecks();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedEvent !== this.props.selectedEvent) {
            this.setState({ checks: undefined });
            this.getChecks();
        }
    }

    render() {
        return (
            <Container fluid>
                <Row className="mb-3 pt-4 pr-3">
                    <Col>
                        <h3>Предупреждения</h3>
                    </Col>
                </Row>
                {this.renderErrors()}
            </Container>
        );
    }

    renderErrors() {
        if (!this.state.checks) {
            return (
                <div className="spinner-wrapper">
                    <Spinner
                        animation="border"
                        role="status"
                        variant="secondary"
                    >
                        <span className="sr-only">Загрузка...</span>
                    </Spinner>
                </div>
            );
        }

        if (this.state.checks.length === 0) {
            return (
                <ListGroup variant="flush">
                    <ListGroup.Item>Всё идеально!</ListGroup.Item>
                </ListGroup>
            );
        }

        return (
            <ListGroup variant="flush">
                {this.state.checks.map(this.renderItem)}
            </ListGroup>
        );
    }

    renderItem(item) {
        return (
            <ListGroup.Item>
                {item.type === "ERROR" ? (
                    <MdError className="checks-error" />
                ) : (
                    <MdWarning className="checks-warning" />
                )}
                <span className="ml-1">{item.message}</span>
            </ListGroup.Item>
        );
    }

    getChecks() {
        getData({
            url: `${config.protocolAndHost}/api/check/event?eventId=${this.props.selectedEvent}`
        })
            .then(result => {
                this.setState({
                    checks: result
                });
            })
            .catch(() => {
                this.props.onError(
                    "Что-то пошло не так, попробуйте обновить страницу"
                );
            });
    }
}
