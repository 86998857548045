import { getData } from "../../utils/getData";
import config from "../../config";

function getStoredJSON(key, defaultValue) {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : defaultValue;
}

function setStoredJSON(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

export function getDistanceInfo(fromBasePointId, toBasePointId) {
    const key =
        "rslogs_cache_distances_" + fromBasePointId + "_" + toBasePointId;
    const entry = getStoredJSON(key, null);
    const expirationTimeout = 86400; // seconds

    if (
        entry &&
        (new Date() - new Date(entry.timestamp)) / 1000 < expirationTimeout
    ) {
        return Promise.resolve(entry.value);
    } else {
        return getData({
            url: `${config.protocolAndHost}/api/view/distances?fromBasePointId=${fromBasePointId}&toBasePointId=${toBasePointId}`
        })
            .then(result => {
                if (result && (result.mapTime || result.actualMedianTime50)) {
                    setStoredJSON(key, {
                        timestamp: new Date(),
                        value: result
                    });
                }
                return result;
            })
            .catch(() => {
                return Promise.resolve(null);
            });
    }
}
