import React, { PureComponent } from "react";
import { Button, Container, FormControl, Table } from "react-bootstrap";

import { AlertDanger } from "../components/alerts/AlertDanger";
import { getData } from "../utils/getData";
import { authorizedFetch } from "../utils/authorizedFetch";
import { Roles } from "../types/roles";
import config from "../config";

import "./Admin.css";

export class Admin extends PureComponent {
    state = {
        users: [],
        points: [],
        savedRoles: {},
        savedPoints: {},
        error: null
    };

    static roles = [
        <option key={" "} value={""}></option>,
        ...Object.keys(Roles).map(role => (
            <option key={Roles[role]} value={Roles[role]}>
                {role}
            </option>
        ))
    ];

    constructor(props) {
        super(props);

        this.changeRole = this.changeRole.bind(this);
        this.changeHomePoint = this.changeHomePoint.bind(this);
        this.renderUserInfo = this.renderUserInfo.bind(this);
        this.onError = this.onError.bind(this);

        getData({
            url: `${config.protocolAndHost}/api/data/basepoints/names`
        })
            .then(result => {
                this.setState({
                    points: result
                });
            })
            .catch(this.onError);
    }

    componentDidMount() {
        getData({
            url: `${config.protocolAndHost}/api/view/users`
        })
            .then(result => {
                this.setState({
                    users: result
                });
            })
            .catch(this.onError);
    }

    render() {
        if (this.state.error) {
            return (
                <div className="main-content">
                    <AlertDanger text={this.state.error} />
                </div>
            );
        }

        return (
            <Container>
                <Button href="/googledocssync" className="mb-3">
                    Google Docs sync
                </Button>
                <Table hover>
                    <thead>
                        <tr>
                            <th>Пользователь</th>
                            <th>Роль</th>
                            <th>Точка</th>
                        </tr>
                    </thead>
                    {<tbody>{this.state.users.map(this.renderUserInfo)}</tbody>}
                </Table>
            </Container>
        );
    }

    renderUserInfo(user) {
        const savedRole = this.state.savedRoles[user.id];
        const role = typeof savedRole === "undefined" ? user.roles : savedRole;

        return (
            <tr key={user.id} className={"user"}>
                <td>
                    {user.vkPhotoUrl ? (
                        <a
                            href={user.vkUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={user.vkPhotoUrl}
                                alt={user.vkName}
                                className="avatar"
                            />
                        </a>
                    ) : (
                        <img alt="" className="avatar no-avatar" />
                    )}
                    <div>
                        {user.vkName ? (
                            <a
                                href={user.vkUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {user.vkName}
                            </a>
                        ) : null}
                        <div>{user.login}</div>
                    </div>
                </td>
                <td>
                    <FormControl
                        as="select"
                        name={user.id}
                        value={role}
                        onChange={this.changeRole}
                    >
                        {Admin.roles}
                    </FormControl>
                </td>
                <td>
                    <FormControl
                        as="select"
                        name={user.id}
                        disabled={role !== Roles.COORDINATOR}
                        value={
                            this.state.savedPoints[user.id] ||
                            user.homePointId ||
                            ""
                        }
                        onChange={this.changeHomePoint}
                    >
                        <option disabled value=""></option>
                        {this.state.points.map(point => (
                            <option key={point.id} value={point.id}>
                                {point.name}
                            </option>
                        ))}
                    </FormControl>
                </td>
            </tr>
        );
    }

    changeRole(event) {
        const that = this;
        const id = event.target.name;
        const role = event.target.value;

        authorizedFetch(`${config.protocolAndHost}/api/data/users/${id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                roles: role
            })
        })
            .then(response => {
                return Promise.all([response.status, response.json()]);
            })
            .then(([status, body]) => {
                if (status !== 200) {
                    throw new Error(JSON.stringify(body));
                }

                const newRoles = { ...that.state.savedRoles };
                newRoles[id] = role;
                this.setState({ savedRoles: newRoles });
            })
            .catch(err => {
                alert(err.messages);
            });
    }

    changeHomePoint(event) {
        const that = this;
        const id = event.target.name;
        const homePointId = event.target.value;

        authorizedFetch(`${config.protocolAndHost}/api/data/users/${id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                homePointId: homePointId
            })
        })
            .then(response => {
                return Promise.all([response.status, response.json()]);
            })
            .then(([status, body]) => {
                if (status !== 200) {
                    throw new Error(JSON.stringify(body));
                }

                const newPoints = { ...that.state.savedPoints };
                newPoints[id] = homePointId;
                this.setState({ savedPoints: newPoints });
            })
            .catch(err => {
                alert(err.messages);
            });
    }

    onError(error) {
        this.setState({ error: error.message });
    }
}
