import React, { PureComponent } from "react";
import { Button, FormControl, Table } from "react-bootstrap";
import { GoTrash } from "react-icons/go";

import { ActionToggler } from "./ActionToggler";

import { TimeUpdateInput } from "../TimeUpdateInput";
import { FieldUpdateTextInput } from "../FieldUpdateTextInput";
import { DeleteEntityModal } from "../DeleteEntityModal";

import { ServerErrorMessages } from "../../types/serverErrorMessages";
import { authorizedFetch } from "../../utils/authorizedFetch";
import config from "../../config";

import "./Movements.css";
import { formatRange, formatTime } from "../../utils/time";
import { getDistanceInfo } from "../DispatcherComponents/getDistanceInfo";

export class CarrierMovements extends PureComponent {
    constructor(props) {
        super(props);

        this.addPoint = this.addPoint.bind(this);
        this.changePoint = this.changePoint.bind(this);
        this.deleteMovement = this.deleteMovement.bind(this);
        this.hideDeleteMovementModal = this.hideDeleteMovementModal.bind(this);
        this.showDeleteMovementModal = this.showDeleteMovementModal.bind(this);

        this.state = {
            movements: props.movements || [],
            actions: prepareActions(props.movements),
            showDeleteMovementModal: false,
            distances: []
        };
    }

    componentDidMount() {
        for (let i = 1; i < this.props.movements.length; i++) {
            this.updateDistanceInfo(i);
        }
    }

    updateDistanceInfo = index => {
        if (!index || index < 1 || index >= this.state.movements.length) {
            return;
        }

        const fromBasePointId = this.getBasePointId(
            this.state.movements[index - 1].movement.pointId
        );
        const toBasePointId = this.getBasePointId(
            this.state.movements[index].movement.pointId
        );

        getDistanceInfo(fromBasePointId, toBasePointId).then(info => {
            const updated = [...this.state.distances];
            updated[index] = info;

            this.setState({
                distances: updated
            });
        });
    };

    render() {
        return (
            <Table responsive className="movements">
                <thead className="thead-light">
                    <tr>
                        <th colSpan={100}>{this.props.carrierName}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderMovements(
                        this.state.movements,
                        this.state.distances
                    )}
                    <tr key="last">
                        <td key="point-selector" className="wide-col">
                            <FormControl
                                as="select"
                                value=""
                                onChange={this.addPoint}
                            >
                                <option disabled value="">
                                    Добавить точку
                                </option>
                                {this.props.points.map(point => (
                                    <option key={point.id} value={point.id}>
                                        {point.name}
                                    </option>
                                ))}
                            </FormControl>
                        </td>
                        <td key="timeArrivalExpectedStub"></td>
                        <td key="timeDepartureExpectedStub"></td>
                        {this.props.matters.map(matter => (
                            <td key={matter.id}></td>
                        ))}
                        <td key="commentStub" className="comment-col"></td>
                        <td key="delButtonStub"></td>
                    </tr>
                </tbody>
                {this.renderDeleteMovementModal()}
            </Table>
        );
    }

    renderMovements(movements, distances) {
        let result = [];

        for (let i = 0; i < movements.length; i++) {
            result.push(this.renderMovement(movements[i], distances[i], i));
        }

        return result;
    }

    renderMovement(movement, distanceInfo, index) {
        let timeHint = null;
        let timeTitle = null;

        if (distanceInfo?.actualMedianTime50) {
            const time = (distanceInfo.actualMedianTime50 / 60).toFixed(0);
            const timeMin = (distanceInfo.actualMedianTime25 / 60).toFixed(0);
            const timeMax = (distanceInfo.actualMedianTime75 / 60).toFixed(0);

            timeHint = formatTime(time);
            timeTitle = "Фактическое время: " + formatRange(timeMin, timeMax);
            if (distanceInfo.mapTime) {
                timeTitle +=
                    "\nВремя по карте: " +
                    (distanceInfo.mapTime / 60).toFixed(0) +
                    " мин";
            }
        } else if (distanceInfo?.mapTime) {
            const time = (distanceInfo.mapTime / 60).toFixed(0);
            timeHint = formatTime(time);
            timeTitle = "Время по карте: " + time + " мин";
        }

        const distanceHint = distanceInfo?.mapDistance
            ? (distanceInfo.mapDistance / 1000).toFixed(1) + " км"
            : null;

        return (
            <tr key={movement.movement.id}>
                <td key="point" className="wide-col">
                    {distanceHint && (
                        <div className="route-info">
                            <span className="info-details" title={timeTitle}>
                                {timeHint}
                            </span>
                            <span
                                className="info-details ml-3"
                                title="Расстояние по карте"
                            >
                                {distanceHint}
                            </span>
                        </div>
                    )}
                    <FormControl
                        as="select"
                        movementid={movement.movement.id}
                        pointid={movement.movement.pointId}
                        value={movement.movement.pointId}
                        index={index}
                        onChange={this.changePoint}
                        title={
                            this.props.pointsInfo[movement.movement.pointId]
                                ?.address
                        }
                    >
                        {this.props.points.map(point => (
                            <option key={point.id} value={point.id}>
                                {point.name}
                            </option>
                        ))}
                    </FormControl>
                </td>
                <td key="timeArrivalExpected">
                    <TimeUpdateInput
                        value={movement.movement.timeArrivalExpected}
                        placeholder="приб"
                        title="Плановое время прибытия"
                        required
                        fieldName="timeArrivalExpected"
                        url={`movements/${movement.movement.id}`}
                    />
                </td>
                <td key="timeDepartureExpected">
                    <TimeUpdateInput
                        value={movement.movement.timeDepartureExpected}
                        placeholder="отпр"
                        title="Плановое время отправления"
                        fieldName="timeDepartureExpected"
                        url={`movements/${movement.movement.id}`}
                    />
                </td>
                {this.props.matters.map(matter => (
                    <td key={matter.id}>
                        <ActionToggler
                            matter={matter}
                            action={
                                this.state.actions[movement.movement.id][
                                    matter.id
                                ] || { action: {} }
                            }
                            movementId={movement.movement.id}
                            onError={this.props.onError}
                        />
                    </td>
                ))}
                <td key="comment" className="comment-col">
                    <FieldUpdateTextInput
                        value={movement.movement.comment}
                        placeholder="Комментарий"
                        fieldName="comment"
                        url={`movements/${movement.movement.id}`}
                        multiline
                    />
                </td>
                <td key="delButton">
                    <Button
                        variant="outline-danger"
                        data-movement-id={movement.movement.id}
                        data-movement-name={
                            this.props.pointsInfo[movement.movement.pointId]
                                ?.name
                        }
                        onClick={this.showDeleteMovementModal}
                    >
                        <GoTrash />
                    </Button>
                </td>
            </tr>
        );
    }

    renderDeleteMovementModal() {
        return (
            <DeleteEntityModal
                key="deleteMovement"
                show={this.state.showDeleteMovementModal}
                title="Удалить точку маршрута?"
                entityType="movements"
                entityName={this.state.toDeleteMovementName}
                entityId={this.state.toDeleteMovementId}
                onHide={this.hideDeleteMovementModal}
                onDelete={this.deleteMovement}
            />
        );
    }

    hideDeleteMovementModal() {
        this.setState({
            showDeleteMovementModal: false,
            toDeleteMovementId: null,
            toDeleteMovementName: null
        });
    }

    showDeleteMovementModal(event) {
        const movementId = Number(
            event.currentTarget.getAttribute("data-movement-id")
        );
        const movementName = event.currentTarget.getAttribute(
            "data-movement-name"
        );

        this.setState({
            showDeleteMovementModal: true,
            toDeleteMovementId: movementId,
            toDeleteMovementName: movementName
        });
    }

    deleteMovement() {
        this.setState(
            {
                movements: this.state.movements.filter(
                    item => item.movement.id !== this.state.toDeleteMovementId
                )
            },
            () => {
                for (let i = 1; i < this.state.movements.length; i++) {
                    this.updateDistanceInfo(i);
                }
            }
        );
    }

    addPoint(event) {
        const that = this;
        const pointId = parseInt(event.target.value, 10);
        const index = this.state.movements.length;

        authorizedFetch(`${config.protocolAndHost}/api/data/movements`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                carrierId: this.props.carrierId,
                pointId: pointId
            })
        })
            .then(response => {
                return Promise.all([
                    response.status,
                    response.json()
                ]).then(([status, body]) => ({ body, status }));
            })
            .then(result => {
                if (result.status === 201) {
                    that.setState(
                        {
                            movements: [
                                ...that.state.movements,
                                { actions: [], movement: result.body }
                            ],
                            actions: {
                                ...that.state.actions,
                                [result.body.id]: {}
                            }
                        },
                        () => {
                            this.updateDistanceInfo(index);
                        }
                    );
                } else if (that.props.onError) {
                    that.props.onError(
                        ServerErrorMessages[result.status] ||
                            "Не удалось добавить точку в маршрут"
                    );
                }
            })
            .catch(() => {
                if (that.props.onError) {
                    that.props.onError("Не удалось добавить точку в маршрут");
                }
            });
    }

    changePoint(event) {
        const that = this;
        const pointId = parseInt(event.target.value, 10);
        const movementId = Number(
            event.currentTarget.getAttribute("movementid")
        );
        const index = Number(event.currentTarget.getAttribute("index"));

        authorizedFetch(
            `${config.protocolAndHost}/api/data/movements/${movementId}`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    pointId: pointId
                })
            }
        )
            .then(response => {
                return Promise.all([
                    response.status,
                    response.json()
                ]).then(([status, body]) => ({ body, status }));
            })
            .then(result => {
                if (result.status === 200) {
                    const movements = [...that.state.movements];
                    const changedMovement = movements.find(
                        mv => mv.movement.id === movementId
                    );
                    changedMovement.movement.pointId = pointId;

                    that.setState(
                        {
                            movements
                        },
                        () => {
                            this.updateDistanceInfo(index);
                            this.updateDistanceInfo(index + 1);
                        }
                    );
                } else if (that.props.onError) {
                    that.props.onError(
                        ServerErrorMessages[result.status] ||
                            "Не удалось изменить точку"
                    );
                }
            })
            .catch(() => {
                if (that.props.onError) {
                    that.props.onError("Не удалось изменить точку");
                }
            });
    }

    getBasePointId = pointId => {
        return this.props.pointsInfo[pointId]?.basePointId;
    };
}

function prepareActions(movements) {
    const result = {};

    movements.forEach(movement => {
        result[movement.movement.id] = result[movement.movement.id] || {};

        movement.actions.forEach(action => {
            result[movement.movement.id][action.matterId] = action;
        });
    });

    return result;
}
