import React from "react";

import {
    FieldUpdateTextInputPresenter,
    updateInputConnect
} from "./FieldUpdateTextInput";

import "./TimeUpdateInput.css";

function addStartZeros(value, expectedLength = 2) {
    return (new Array(expectedLength).join("0") + value).slice(-expectedLength);
}

class TimeUpdateInputPresenter extends FieldUpdateTextInputPresenter {
    constructor(props) {
        super(props);

        this.setCurrentTime = this.setCurrentTime.bind(this);
    }

    render() {
        return (
            <div
                className="d-inline time-input"
                onClick={this.props.currentAsDefault && this.setCurrentTime}
            >
                {super.render()}
            </div>
        );
    }

    onChangeHandler(event) {
        let value = event.target.value;

        if (value.length > this.state.value.length) {
            if (value.length === 1 && value > "2") {
                value = "0" + value;
            }

            if (value.length === 2) {
                value = value[1] === ":" ? "0" + value : value + ":";
            }

            if (value.length === 4 && value[3] > "5") {
                const parts = value.split("");
                parts.splice(3, 0, "0");
                value = parts.join("");
            }

            event.target.value = value;
        }

        super.onChangeHandler(event);
    }

    setCurrentTime() {
        if (!this.state.value) {
            const now = new Date();
            const newValue = `${addStartZeros(now.getHours())}:${addStartZeros(
                now.getMinutes()
            )}`;
            this.setState({ value: newValue }, this.saveValue);
        }
    }
}

const TimeUpdateInputConnected = updateInputConnect(TimeUpdateInputPresenter);

export function TimeUpdateInput(props) {
    return (
        <TimeUpdateInputConnected {...props} type={"tel"} patternType="time" />
    );
}
