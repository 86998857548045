import React, { PureComponent } from "react";

import { FormInput } from "./FormInput";
import { SuggestWrapper } from "./SuggestWrapper";

export class FormInputWithSuggest extends PureComponent {
    state = {
        showSuggest: false
    };

    constructor(props) {
        super(props);

        this.onInput = this.onInput.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.showSuggest = this.showSuggest.bind(this);
        this.hideSuggest = this.hideSuggest.bind(this);
    }

    render() {
        return (
            <SuggestWrapper
                suggestValues={this.props.suggestValues}
                showSuggest={this.state.showSuggest}
                onShow={this.showSuggest}
                onHide={this.hideSuggest}
                onChange={this.onSelectChange}
            >
                <FormInput {...this.props} onChange={this.onInput} />
            </SuggestWrapper>
        );
    }

    showSuggest() {
        this.setState({ showSuggest: true });
    }

    hideSuggest() {
        this.setState({ showSuggest: false });
    }

    onInput(newValue, extraData) {
        this.props.onChange(newValue, extraData);

        this.setState({ showSuggest: !Boolean(newValue) });
    }

    onSelectChange(value) {
        this.props.onChange(value, this.props.extra);
    }
}
