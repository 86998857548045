export function getMatterSummary(item, bagTypesMap) {
    const matterAmount = getMatterSum(item.items, bagTypesMap);

    return {
        matterId: item.matterId,
        matterName: item.matter,
        amount: matterAmount
    };
}

export function getMatterSum(items, bagTypesMap) {
    return items
        .map(e => {
            if (!e.value || (!e.bagTypeId && !e.bagSize)) {
                return 0;
            }

            const v = parseFloat(e.value.replace(",", ".")); // TODO: test for different locales

            if (isNaN(v)) {
                return 0;
            }

            const size = e.bagSize
                ? e.bagSize
                : bagTypesMap[e.bagTypeId]?.size || 0;

            return v * size;
        })
        .reduce((a, b) => a + b, 0);
}
