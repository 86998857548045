import {
    FieldUpdateTextInputPresenter,
    updateInputConnect
} from "../FieldUpdateTextInput";

class FieldUpdateBagsCountInputPresenter extends FieldUpdateTextInputPresenter {
    saveValue() {
        if (this.state.value || this.props.item.bagSize) {
            super.saveValue();
        }
    }

    onBlurHandler() {
        this.props.onBlur();
        super.onBlurHandler();
    }
}

export const FieldUpdateBagsCountInput = updateInputConnect(
    FieldUpdateBagsCountInputPresenter
);
