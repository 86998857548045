import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Button, Container, Form } from "react-bootstrap";

import { AlertDanger } from "../components/alerts/AlertDanger";
import {
    changeHomePageInfo,
    removeHomePageInfo
} from "../store/homePageInfo/actions";
import { getSelectedCarriersLocalStorageKey } from "../utils/getSelectedCarriersLocalStorageKey";
import { parseJwt } from "../utils/parseJwt";
import { getHomePageByRole } from "../utils/getHomePageByRole";
import { getSearchParams } from "../utils/getSearchParams";

import { Pages } from "../types/pages";
import config from "../config";
import vkLogo from "../assets/img/vk.png";

import "./Authorization.css";

class AuthorizationPresenter extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            error: this.getVkError()
        };

        // vk auth flow:
        // 1) user opens this page
        // 2) user clicks link to vk auth page, which has params:
        //   - redirect url [current page without params]
        //   - state [current params encoded]
        //   - etc tech params
        // 3) vk redirects to this page with special params (state, code, error, error_reason, error_description)
        // 4) we handle response:
        // - if login succeed, send code to backend and receive token
        // - if login failed, show message about it
        this.handleVkParams();
        this.restoreUrlFromVkParams();
        this.setVkUrl();

        this.onSubmit = this.onSubmit.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        const token = localStorage.getItem("token");

        if (!token) {
            this.props.removeHomePageInfo();
        }
    }

    render() {
        return (
            <Container className="authorization-form">
                {this.state.error ? (
                    <AlertDanger text={this.state.error} />
                ) : null}
                <Form onSubmit={this.onSubmit}>
                    <Form.Group controlId="username">
                        <Form.Label>Логин</Form.Label>
                        <Form.Control
                            name="username"
                            value={this.state.username}
                            onChange={this.onInputChange}
                            placeholder="Логин"
                        />
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control
                            name="password"
                            type="password"
                            value={this.state.password}
                            onChange={this.onInputChange}
                            placeholder="Пароль"
                        />
                    </Form.Group>
                    <Button
                        variant="primary"
                        type="submit"
                        className="registration-btn"
                    >
                        Войти
                    </Button>
                    <a className="link" href={Pages.REGISTRATION}>
                        Создать учётную запись
                    </a>
                </Form>

                <div className="delimiter text-muted">
                    <span className="delimiter-content">или</span>
                </div>

                <Button className="vk-login" href={this.vkUrl}>
                    <img
                        alt="ВК"
                        src={vkLogo}
                        style={{ height: 25 }}
                        className="mr-3"
                    />
                    Войти через ВК
                </Button>
                <div className="privacy-policy text-muted">
                    Нажимая "Войти через ВК", вы принимаете{" "}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={Pages.PRIVACY}
                    >
                        соглашение об обработке персональных данных
                    </a>
                </div>
            </Container>
        );
    }

    onInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({ [name]: value });
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.state.username || !this.state.password) {
            this.setState({
                error: "Кажется, вы забыли ввести логин и/или пароль"
            });

            return;
        }

        this.authorize("api/auth/signin", {
            username: this.state.username,
            password: this.state.password
        });
    }

    signInViaVk(code, redirectUri) {
        this.authorize("api/auth/vk", {
            code: code,
            redirectUri: redirectUri
        });
    }

    authorize(request, requestParams) {
        const that = this;

        fetch(`${config.protocolAndHost}/${request}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestParams)
        })
            .then(response => {
                return Promise.all([
                    response.status,
                    response.json()
                ]).then(([status, body]) => ({ ...body, status }));
            })
            .then(result => {
                if (result.status === 200) {
                    localStorage.setItem(
                        "token",
                        `${result.tokenType} ${result.accessToken}`
                    );
                    this.cleanupLocalStorage();

                    const userInfo = parseJwt(result.accessToken);
                    const homePage = getHomePageByRole(userInfo);
                    const searchParams = getSearchParams();

                    that.props.changeHomePageInfo(homePage);

                    if (searchParams.from) {
                        that.props.history.push(searchParams.from);
                    } else {
                        that.props.history.push(homePage);
                    }
                } else if (result.status === 400 || result.status === 401) {
                    this.setState({
                        error: "Неверный логин и/или пароль"
                    });
                } else {
                    this.setState({
                        error:
                            "Что-то пошло не так, обратитесь к администратору."
                    });
                }
            })
            .catch(() => {
                this.setState({
                    error:
                        "У нас что-то сломалось, пожалуйста, попробуйте авторизоваться позже"
                });
            });
    }

    handleVkParams() {
        // checks 'code' param indicating that a user logs in via VK
        // passes the code and redirect url to authorize via VK API on backend
        const code = getSearchParams().code;
        if (code) {
            const clearUrl = window.location.href.split("?")[0];
            this.signInViaVk(code, clearUrl);
        }
    }

    restoreUrlFromVkParams() {
        // deletes technical VK params from URL
        const searchParams = getSearchParams();
        if (searchParams.code || searchParams.error) {
            const clearUrl = window.location.href.split("?")[0];
            const state = searchParams.state;
            window.history.replaceState(
                window.history.state,
                document.title,
                `${clearUrl}${state ? `?${state.replace(";", "&")}` : ""}`
            );
        }
    }

    setVkUrl() {
        // constructs an URL to pass to 'Authorize via VK' button
        const clearUrl = window.location.href.split("?")[0];
        const state = getSearchParams().state;
        const stateValue = state ? state : this.getSelfParams();

        this.vkUrl = `https://oauth.vk.com/authorize?client_id=${
            config.vkAppId
        }&redirect_uri=${clearUrl}&response_type=code&v=5.122${
            stateValue ? `&state=${stateValue}` : ""
        }`;
    }

    getSelfParams() {
        const selfParams = ["from"];
        const searchParams = getSearchParams();

        return selfParams
            .map(key => {
                const value = searchParams[key];
                return value ? `${key}=${value}` : null;
            })
            .filter(item => item)
            .join(";");
    }

    getVkError() {
        if (!window.location.href.includes("error")) {
            return "";
        }

        return getSearchParams().error_reason === "user_denied"
            ? "Для входа через ВК дайте разрешение"
            : "Вход не выполнен";
    }

    // time to time remove not selected values from localStorage
    // to prevent localStorage overflow
    cleanupLocalStorage() {
        // dispatcher page
        const selectedEventCarriersKey = getSelectedCarriersLocalStorageKey();

        for (let i = 0; i < localStorage.length; ++i) {
            const key = localStorage.key(i);

            if (
                /dispatcher_event_\d+_selectedCarriers/.test(key) &&
                key !== selectedEventCarriersKey
            ) {
                localStorage.removeItem(key);
            }
        }

        // TODO: remove in a month or two
        localStorage.removeItem("dispatcher_selectedCarriers");
    }
}

const mapDispatchToProps = dispatch => ({
    changeHomePageInfo: hp => dispatch(changeHomePageInfo(hp)),
    removeHomePageInfo: () => dispatch(removeHomePageInfo())
});

export const Authorization = compose(
    withRouter,
    connect(undefined, mapDispatchToProps)
)(AuthorizationPresenter);
