import React, { PureComponent } from "react";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocalUtils from "react-day-picker/moment";
import moment from "moment";

import "moment/locale/ru";

import { AlertDanger } from "../alerts/AlertDanger";

import { ServerErrorMessages } from "../../types/serverErrorMessages";
import { authorizedFetch } from "../../utils/authorizedFetch";
import config from "../../config";

import "react-day-picker/lib/style.css";
import "./NewEventModal.css";

export class NewEventModal extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            eventId: props.events[0] && props.events[0].id,
            date: getFirstSaturday(),
            today: new Date()
        };

        this.onSave = this.onSave.bind(this);
        this.onHide = this.onHide.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.changeEvent = this.changeEvent.bind(this);
        this.isPast = this.isPast.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.events.length !== this.props.events.length) {
            this.setState({
                eventId: this.props.events[0] && this.props.events[0].id
            });
        }
    }

    render() {
        return (
            <Modal
                className="new-event-modal"
                show={this.props.show}
                onHide={this.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Добавить акцию</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.error && (
                        <AlertDanger text={this.state.error} />
                    )}
                    <Form.Group key="date">
                        <Form.Label>Дата</Form.Label>
                        <DayPickerInput
                            value={this.state.date}
                            formatDate={MomentLocalUtils.formatDate}
                            parseDate={MomentLocalUtils.parseDate}
                            format="yyyy-MM-DD"
                            placeholder="YYYY-MM-DD"
                            onDayChange={this.changeDate}
                            inputProps={{
                                class: "form-control"
                            }}
                            dayPickerProps={{
                                firstDayOfWeek: 1,
                                fromMonth: this.state.today,
                                locale: "ru",
                                localeUtils: MomentLocalUtils,
                                disabledDays: this.isPast
                            }}
                        />
                    </Form.Group>
                    <Form.Group key="event">
                        <Form.Label>Копировать из</Form.Label>
                        <FormControl
                            as="select"
                            className="new-event-modal__event-selector"
                            value={this.state.eventId}
                            onChange={this.changeEvent}
                        >
                            {this.props.events.map(event => (
                                <option key={event.id} value={event.id}>
                                    {event.date}
                                </option>
                            ))}
                        </FormControl>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.onHide}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={this.onSave}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    onSave() {
        if (!this.state.date) {
            this.setState({
                error: "Пожалуйста, укажите дату акции"
            });
            return;
        }

        const that = this;
        const date = moment(this.state.date).format("yyyy-MM-DD");

        authorizedFetch(
            `${config.protocolAndHost}/api/data/events/${this.state.eventId}/copy/${date}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then(response => {
                return Promise.all([
                    response.status,
                    response.json()
                ]).then(([status, body]) => ({ ...body, status }));
            })
            .then(result => {
                if (result.status === 201) {
                    that.props.onSave(result);
                    that.props.onHide();
                } else if (result.code === 400003) {
                    that.setState({
                        error: "Акция на выбранную дату уже существует"
                    });
                } else {
                    that.setState({
                        error:
                            ServerErrorMessages[result.status] ||
                            "Не удалось создать акцию"
                    });
                }
            })
            .catch(() => {
                that.setState({ error: "Не удалось создать акцию" });
            });
    }

    onHide() {
        this.setState({
            error: null,
            date: getFirstSaturday()
        });

        this.props.onHide();
    }

    changeDate(date) {
        if (date?.toISOString() !== this.state.date?.toISOString()) {
            this.setState({ error: null });
        }

        this.setState({ date });
    }

    changeEvent(event) {
        const eventId = parseInt(event.target.value, 10);

        this.setState({ eventId });
    }

    isPast(date) {
        return date < this.state.today;
    }
}

function getFirstSaturday() {
    const currentDay = new Date();
    const firstSaturdayInCurrentMonth = getFirstSaturdayInMonth(
        currentDay.getFullYear(),
        currentDay.getMonth()
    );

    return firstSaturdayInCurrentMonth > currentDay
        ? firstSaturdayInCurrentMonth
        : getFirstSaturdayInMonth(
              currentDay.getFullYear(),
              currentDay.getMonth() + 1
          );
}

function getFirstSaturdayInMonth(year, month) {
    const firstDay = new Date(year, month, 1);
    const day = firstDay.getDay();

    return new Date(year, month, 7 - day, 12, 0, 0);
}
