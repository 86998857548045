export const selectedEventKey = "dispatcher_selectedEvent";

export function getSelectedPointsLocalStorageKey() {
    const selectedEvent = localStorage.getItem(selectedEventKey);
    return getSelectedPointsLocalStorageKeyForEvent(selectedEvent);
}

export function getSelectedPointsLocalStorageKeyForEvent(eventId) {
    return `dispatcher_event_${eventId}_selectedPoints`;
}
