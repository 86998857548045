import React, { Component } from "react";
import { Card, Col, Container, ListGroup, Row, Spinner } from "react-bootstrap";
import {
    MdComment,
    MdDirectionsCar,
    MdRoom,
    MdPersonOutline,
    MdPhoneInTalk
} from "react-icons/md";
import { FiTruck } from "react-icons/fi";

import { ListIconItem } from "../components/ListIconItem";
import { CollapsibleCard } from "../components/CollapsibleCard";
import { CallButton } from "../components/buttons/CallButton";
import {
    FieldUpdateTextInput,
    patterns
} from "../components/FieldUpdateTextInput";
import { TimeUpdateInput } from "../components/TimeUpdateInput";
import { RangeInput } from "../components/RangeInput";
import { CarNumberUpdateInput } from "../components/CarNumberUpdateInput";
import { AlertDanger } from "../components/alerts/AlertDanger";

import { getData } from "../utils/getData";
import { getStatus } from "../utils/getStatus";
import { StatusIcon } from "../types/status";
import config from "../config";

import "./Movement.scss";

const phoneRegExp = new RegExp(patterns.phone);

export class Movement extends Component {
    state = {
        error: null,
        isLoaded: false,
        info: {},
        movementActions: []
    };

    constructor(props) {
        super(props);

        this.updateDriverPhone = this.updateDriverPhone.bind(this);
        this.updateTimeArrivalActual = this.updateTimeArrivalActual.bind(this);
        this.updateTimeDepartureActual = this.updateTimeDepartureActual.bind(
            this
        );
    }

    componentDidMount() {
        const params = this.props.match.params;
        getData({
            url: `${config.protocolAndHost}/api/view/coordinator/movements/${params.movementId}`
        })
            .then(result => {
                this.setState({
                    isLoaded: true,
                    point: result.point,
                    carrier: result.movementAction.carrier,
                    movement: result.movementAction.movement,
                    movementActions: result.movementAction.actions,
                    route: result.movementAction.route,
                    status: getStatus(
                        result.movementAction.movement.timeArrivalActual,
                        result.movementAction.movement.timeDepartureActual
                    )
                });
            })
            .catch(error => {
                this.setState({
                    isLoaded: true,
                    error
                });
            });
    }

    renderMovementAction(movementAction) {
        return (
            <ListGroup.Item className="d-flex flex-row" key={movementAction.id}>
                <Col className="w-50">{movementAction.matter}</Col>
                <Col>
                    <FieldUpdateTextInput
                        value={movementAction.comment}
                        fieldName="comment"
                        placeholder="Комментарий"
                        url={`actions/${movementAction.id}`}
                        multiline={!!movementAction.comment}
                    />
                </Col>
            </ListGroup.Item>
        );
    }

    renderDownloadMovementActions(movementActions) {
        const maxOrder = Math.max.apply(
            Math,
            movementActions.map(function(item) {
                return item.order;
            })
        );
        const minOrder = 1;

        const needGroupActions = maxOrder !== minOrder;

        if (needGroupActions) {
            let groupedActions = [];
            groupedActions[minOrder] = (
                <div>
                    <ListGroup.Item className="rounded-0 group-action-label">
                        В глубину
                    </ListGroup.Item>
                    {movementActions
                        .filter(
                            movementAction => movementAction.order === minOrder
                        )
                        .map(movementAction =>
                            this.renderMovementAction(movementAction)
                        )}
                </div>
            );
            for (let i = minOrder + 1; i < maxOrder; i++) {
                groupedActions[i] = (
                    <div>
                        <ListGroup.Item className="rounded-0 group-action-label">
                            Затем
                        </ListGroup.Item>
                        {movementActions
                            .filter(
                                movementAction => movementAction.order === i
                            )
                            .map(movementAction =>
                                this.renderMovementAction(movementAction)
                            )}
                    </div>
                );
            }
            groupedActions[maxOrder] = (
                <div>
                    <ListGroup.Item className="rounded-0 group-action-label">
                        К выходу
                    </ListGroup.Item>
                    {movementActions
                        .filter(
                            movementAction => movementAction.order === maxOrder
                        )
                        .map(movementAction =>
                            this.renderMovementAction(movementAction)
                        )}
                </div>
            );
            return groupedActions;
        }
        return movementActions.map(movementAction =>
            this.renderMovementAction(movementAction)
        );
    }

    render() {
        const {
            error,
            isLoaded,
            carrier,
            route,
            movement,
            movementActions,
            status
        } = this.state;
        const unloadActions = movementActions.unload;
        const downloadActions = movementActions.load;

        if (error) {
            return (
                <Container>
                    <AlertDanger text={error.message} />
                </Container>
            );
        } else if (!isLoaded) {
            return (
                <div className="spinner-wrapper">
                    <Spinner
                        animation="border"
                        role="status"
                        variant="secondary"
                    >
                        <span className="sr-only">Загрузка...</span>
                    </Spinner>
                </div>
            );
        } else {
            return (
                <div className="movement">
                    <Card className="mb-1">
                        <Card.Header className="carrier-card-header border-bottom-0 align-items-center d-flex">
                            <Row className="w-100 align-items-center">
                                <Col sm="auto" xs="auto" className="pr-1">
                                    {StatusIcon[status]}
                                </Col>
                                <Col>{carrier.name}</Col>
                                <Col sm={1} xs={1} className="pr-1 pl-0">
                                    {carrier.driverPhone &&
                                        phoneRegExp.test(
                                            carrier.driverPhone
                                        ) && (
                                            <CallButton
                                                phoneNumber={
                                                    carrier.driverPhone
                                                }
                                            />
                                        )}
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <ListGroup>
                                {route.map((route, i) => (
                                    // In our case it is ok, because route doesn't change.
                                    // And yes, react set the same keys (index) by default,
                                    // but we should write it because of console message.
                                    <ListGroup.Item key={i}>
                                        {route.movementId === movement.id ? (
                                            <a href={`/points/${route.id}`}>
                                                <MdRoom className="mb-1" />{" "}
                                                {route.name}
                                            </a>
                                        ) : (
                                            <a
                                                href={`/movements/${route.movementId}`}
                                            >
                                                {route.name}
                                            </a>
                                        )}
                                        <div className="text-muted">
                                            {route.address}
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card.Body>
                    </Card>
                    <CollapsibleCard
                        title="Машина"
                        arrowPosition="right"
                        className="mb-1"
                        isOpen={true}
                        body={
                            <ListGroup>
                                <ListIconItem
                                    icon={MdDirectionsCar}
                                    info={
                                        <CarNumberUpdateInput
                                            value={carrier.carNumber}
                                            url={`carriers/${carrier.id}`}
                                            fieldName="carNumber"
                                        />
                                    }
                                />
                                <ListIconItem
                                    icon={MdPersonOutline}
                                    info={
                                        <FieldUpdateTextInput
                                            value={carrier.driverName}
                                            placeholder="Имя водителя"
                                            url={`carriers/${carrier.id}`}
                                            fieldName="driverName"
                                        />
                                    }
                                />
                                <ListIconItem
                                    icon={MdPhoneInTalk}
                                    info={
                                        <FieldUpdateTextInput
                                            value={carrier.driverPhone}
                                            placeholder="8 900 000 00 00"
                                            url={`carriers/${carrier.id}`}
                                            fieldName="driverPhone"
                                            patternType="phone"
                                            onChange={this.updateDriverPhone}
                                        />
                                    }
                                />
                                {carrier.carType && (
                                    <ListIconItem
                                        icon={FiTruck}
                                        info={carrier.carType}
                                    />
                                )}
                                <ListIconItem
                                    icon={MdComment}
                                    info={
                                        <FieldUpdateTextInput
                                            value={carrier.comment}
                                            placeholder="Комментарий к водителю и машине"
                                            url={`carriers/${carrier.id}`}
                                            fieldName="comment"
                                            multiline
                                        />
                                    }
                                />
                            </ListGroup>
                        }
                    />
                    <CollapsibleCard
                        title="Перемещение"
                        arrowPosition="right"
                        className="mb-1"
                        isOpen={true}
                        body={
                            <ListGroup>
                                <ListGroup.Item
                                    variant="primary"
                                    className="rounded-0"
                                >
                                    Время
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex flex-row">
                                    <Container>
                                        <Row>
                                            <Col xs={4} sm={5}>
                                                План:
                                            </Col>
                                            <Col xs={3} sm={3}>
                                                {movement.timeArrivalExpected}
                                            </Col>
                                            <Col xs={1} sm={1}>
                                                -
                                            </Col>
                                            <Col xs={3} sm={3}>
                                                {movement.timeDepartureExpected}
                                            </Col>
                                        </Row>
                                    </Container>
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex flex-row">
                                    <Container>
                                        <Row>
                                            <Col xs={4} sm={5}>
                                                Факт:
                                            </Col>
                                            <Col xs={3} sm={3}>
                                                <TimeUpdateInput
                                                    value={
                                                        movement.timeArrivalActual
                                                    }
                                                    placeholder="приб"
                                                    fieldName="timeArrivalActual"
                                                    url={`movements/${movement.id}`}
                                                    currentAsDefault
                                                    onChange={
                                                        this
                                                            .updateTimeArrivalActual
                                                    }
                                                />
                                            </Col>
                                            <Col xs={1} sm={1}>
                                                -
                                            </Col>
                                            <Col xs={3} sm={3}>
                                                <TimeUpdateInput
                                                    value={
                                                        movement.timeDepartureActual
                                                    }
                                                    placeholder="отпр"
                                                    fieldName="timeDepartureActual"
                                                    url={`movements/${movement.id}`}
                                                    currentAsDefault
                                                    onChange={
                                                        this
                                                            .updateTimeDepartureActual
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </ListGroup.Item>
                                <ListGroup.Item variant="primary">
                                    Наполнение машины
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <RangeInput
                                        fieldName="loadIncome"
                                        description="Прибытие"
                                        url={`movements/${movement.id}`}
                                        value={movement.loadIncome}
                                        className="load"
                                    />
                                    <RangeInput
                                        fieldName="loadOutcome"
                                        description="Отправление"
                                        url={`movements/${movement.id}`}
                                        value={movement.loadOutcome}
                                        className="load"
                                    />
                                </ListGroup.Item>
                                <ListGroup.Item variant="primary">
                                    Комментарий
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Col>
                                        <FieldUpdateTextInput
                                            value={movement.comment}
                                            placeholder="Комментарий"
                                            fieldName="comment"
                                            url={`movements/${movement.id}`}
                                            multiline
                                        />
                                    </Col>
                                </ListGroup.Item>
                            </ListGroup>
                        }
                    />
                    <CollapsibleCard
                        title="Действия"
                        arrowPosition="right"
                        className="mb-2"
                        isOpen={true}
                        body={
                            <ListGroup>
                                {unloadActions.length > 0 ? (
                                    <ListGroup.Item
                                        variant="primary"
                                        className="rounded-0"
                                    >
                                        Выгрузить
                                    </ListGroup.Item>
                                ) : null}
                                {unloadActions.length > 0
                                    ? unloadActions.map(movementAction =>
                                          this.renderMovementAction(
                                              movementAction
                                          )
                                      )
                                    : null}
                                {downloadActions.length > 0 ? (
                                    <ListGroup.Item
                                        variant="primary"
                                        className="rounded-0"
                                    >
                                        Загрузить
                                    </ListGroup.Item>
                                ) : null}
                                {downloadActions.length > 0
                                    ? this.renderDownloadMovementActions(
                                          downloadActions
                                      )
                                    : null}
                            </ListGroup>
                        }
                    />
                </div>
            );
        }
    }

    updateDriverPhone(newValue) {
        const newCarrier = { ...this.state.carrier };
        newCarrier.driverPhone = newValue;
        this.setState({ carrier: newCarrier });
    }

    updateTimeArrivalActual(newValue) {
        const newMovement = { ...this.state.movement };
        newMovement.timeArrivalActual = newValue;
        this.setState({
            movement: newMovement,
            status: getStatus(
                newMovement.timeArrivalActual,
                newMovement.timeDepartureActual
            )
        });
    }

    updateTimeDepartureActual(newValue) {
        const newMovement = { ...this.state.movement };
        newMovement.timeDepartureActual = newValue;
        this.setState({
            movement: newMovement,
            status: getStatus(
                newMovement.timeArrivalActual,
                newMovement.timeDepartureActual
            )
        });
    }
}
