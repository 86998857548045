import React, { PureComponent } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import { FormInput } from "../FormInput";
import { FormInputWithSuggest } from "../FormInputWithSuggest";
import { AlertDanger } from "../alerts/AlertDanger";

import { ServerErrorMessages } from "../../types/serverErrorMessages";
import { authorizedFetch } from "../../utils/authorizedFetch";
import { setFocus } from "../../utils/setFocus";
import {
    carPlanPopularValues,
    carTypePopularValues
} from "../../types/popularValues";
import config from "../../config";

const emptyState = {
    name: "",
    carNumber: "",
    carPlan: "",
    carType: "",
    driverName: "",
    driverPhone: "",
    comment: "",
    error: ""
};

export class NewCarrierModal extends PureComponent {
    state = emptyState;

    constructor(props) {
        super(props);

        this.focusInputRef = React.createRef();

        this.onHide = this.onHide.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            setFocus(this.focusInputRef.current);
        }
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Новый перевозчик</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.error && (
                        <AlertDanger text={this.state.error} />
                    )}
                    {/* TODO: mark as required */}
                    <Form.Group key="name">
                        <Form.Label>Название</Form.Label>
                        <FormInput
                            placeholder="Название перевозчика"
                            extra={{ name: "name" }}
                            value={this.state.name}
                            inputRef={this.focusInputRef}
                            onChange={this.onInputChange}
                            onSubmit={this.onSave}
                        />
                    </Form.Group>
                    <Form.Group key="carNumber">
                        <Form.Label>Номер машины</Form.Label>
                        <FormInput
                            placeholder="х000хх 000"
                            extra={{ name: "carNumber" }}
                            value={this.state.carNumber}
                            onChange={this.onInputChange}
                            onSubmit={this.onSave}
                        />
                    </Form.Group>
                    <Form.Group key="carPlan">
                        <Form.Label>Тариф</Form.Label>
                        <FormInputWithSuggest
                            placeholder="Тариф"
                            extra={{ name: "carPlan" }}
                            value={this.state.carPlan}
                            suggestValues={carPlanPopularValues}
                            onChange={this.onInputChange}
                            onSubmit={this.onSave}
                        />
                    </Form.Group>
                    <Form.Group key="carType">
                        <Form.Label>Тип</Form.Label>
                        <FormInputWithSuggest
                            placeholder="Тип"
                            extra={{ name: "carType" }}
                            value={this.state.carType}
                            suggestValues={carTypePopularValues}
                            onChange={this.onInputChange}
                            onSubmit={this.onSave}
                        />
                    </Form.Group>
                    <Form.Group key="driverName">
                        <Form.Label>Имя водителя</Form.Label>
                        <FormInput
                            placeholder="ФИО"
                            extra={{ name: "driverName" }}
                            value={this.state.driverName}
                            onChange={this.onInputChange}
                            onSubmit={this.onSave}
                        />
                    </Form.Group>
                    <Form.Group key="driverPhone">
                        <Form.Label>Телефон водителя</Form.Label>
                        <FormInput
                            placeholder="8 900 000 00 00"
                            extra={{ name: "driverPhone" }}
                            value={this.state.driverPhone}
                            patternType="phone"
                            onChange={this.onInputChange}
                            onSubmit={this.onSave}
                        />
                    </Form.Group>
                    <Form.Group key="comment">
                        <Form.Label>Комментарий</Form.Label>
                        <FormInput
                            textarea
                            placeholder="Комментарий"
                            extra={{ name: "comment" }}
                            value={this.state.comment}
                            onChange={this.onInputChange}
                            onSubmit={this.onSave}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.onHide}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={this.onSave}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    onHide() {
        this.setState(emptyState);

        this.props.onHide();
    }

    onInputChange(value, extra) {
        this.setState({ [extra.name]: value });
    }

    onSave() {
        const that = this;

        authorizedFetch(`${config.protocolAndHost}/api/data/carriers`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: this.state.name,
                carNumber: this.state.carNumber,
                carPlan: this.state.carPlan,
                carType: this.state.carType,
                driverName: this.state.driverName,
                driverPhone: this.state.driverPhone,
                comment: this.state.comment,
                eventId: this.props.eventId
            })
        })
            .then(response => {
                return Promise.all([
                    response.status,
                    response.json()
                ]).then(([status, body]) => ({ ...body, status }));
            })
            .then(result => {
                if (result.status === 201) {
                    that.props.onSave(result);
                    that.onHide();
                } else {
                    that.setState({
                        error:
                            ServerErrorMessages[result.status] ||
                            "Не удалось создать перевозчика"
                    });
                }
            })
            .catch(() => {
                // TODO: add more informative messages about errors
                that.setState({ error: "Не удалось создать перевозчика" });
            });
    }
}
