import React, { PureComponent } from "react";
import { Button, Modal } from "react-bootstrap";

import { AlertDanger } from "../alerts/AlertDanger";
import { FormInput } from "../FormInput";

import { authorizedFetch } from "../../utils/authorizedFetch";
import { setFocus } from "../../utils/setFocus";
import { ServerErrorMessages } from "../../types/serverErrorMessages";
import config from "../../config";

export class ActionCommentModal extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || "",
            error: null
        };

        this.focusInputRef = React.createRef();

        this.onHide = this.onHide.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }

        if (!prevProps.show && this.props.show) {
            setFocus(this.focusInputRef.current);
        }
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Комментарий</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.error && (
                        <AlertDanger text={this.state.error} />
                    )}

                    <FormInput
                        textarea
                        placeholder="Комментарий"
                        value={this.state.value}
                        inputRef={this.focusInputRef}
                        onChange={this.onInputChange}
                        onSubmit={this.onSave}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.onHide}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={this.onSave}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    onHide() {
        this.setState({ value: this.props.value });

        this.props.onHide();
    }

    onInputChange(value) {
        this.setState({ value });
    }

    onSave() {
        const that = this;
        const value = this.state.value;

        authorizedFetch(
            `${config.protocolAndHost}/api/data/actions/${this.props.actionId}`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    comment: value
                })
            }
        )
            .then(result => {
                if (result.status === 200) {
                    that.props.onSave(value);
                    that.props.onHide();
                } else {
                    that.setState({
                        error:
                            ServerErrorMessages[result.status] ||
                            "Не удалось сохранить комментарий"
                    });
                }
            })
            .catch(() => {
                that.setState({
                    error: "Не удалось сохранить комментарий"
                });
            });
    }
}
