import React, { PureComponent } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import { AlertDanger } from "./alerts/AlertDanger";
import { authorizedFetch } from "../utils/authorizedFetch";
import config from "../config";
import { ServerErrorMessages } from "../types/serverErrorMessages";

export class EditVolunteerModal extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            firstName: props.volunteer.firstName,
            lastName: props.volunteer.lastName,
            vkLink: props.volunteer.vkLink
        };

        this.onHide = this.onHide.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                onClick={e => e.stopPropagation()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Волонтёр</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.error && (
                        <AlertDanger text={this.state.error} />
                    )}
                    <Form.Group key="firstName">
                        <Form.Label>Имя</Form.Label>
                        <Form.Control
                            placeholder="Имя"
                            name="firstName"
                            value={this.state.firstName}
                            onChange={this.onInputChange}
                        />
                    </Form.Group>
                    <Form.Group key="lastName">
                        <Form.Label>Фамилия</Form.Label>
                        <Form.Control
                            placeholder="Фамилия"
                            name="lastName"
                            value={this.state.lastName}
                            onChange={this.onInputChange}
                        />
                    </Form.Group>
                    <Form.Group key="vkLink">
                        <Form.Label>Ссылка ВК</Form.Label>
                        <Form.Control
                            placeholder="https://vk.com/user"
                            name="vkLink"
                            value={this.state.vkLink}
                            onChange={this.onInputChange}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.onHide}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={this.onSave}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    onSave() {
        if (
            !(
                (this.state.firstName && this.state.lastName) ||
                this.state.vkLink
            )
        ) {
            this.setState({
                error:
                    "Пожалуйста, укажите имя и фамилию или ссылку на ВК волонтёра"
            });
            return;
        }

        const that = this;

        authorizedFetch(
            `${config.protocolAndHost}/api/data/persons/${this.props.volunteer.id}`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    vkLink: this.state.vkLink
                })
            }
        )
            .then(response => {
                return Promise.all([
                    response.status,
                    response.json()
                ]).then(([status, body]) => ({ ...body, status }));
            })
            .then(result => {
                if (result.status === 200) {
                    that.props.onSave(result);
                    that.props.onHide();
                } else if (result.code === 400004) {
                    that.setState({
                        error: "Пользователь с таким VK ID уже существует"
                    });
                } else if (result.code === 400005) {
                    that.setState({
                        error: "Пользователь с таким именем уже существует"
                    });
                } else {
                    that.setState({
                        error:
                            ServerErrorMessages[result.status] ||
                            "Не удалось сохранить волонтёра" + result.status
                    });
                }
            })
            .catch(e => {
                that.setState({ error: "Не удалось сохранить волонтёра" });
            });
    }

    onHide() {
        this.props.onHide();
    }

    onInputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
}
