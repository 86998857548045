import React, { PureComponent } from "react";
import { Button, FormControl, ListGroup, Modal } from "react-bootstrap";
import { AlertDanger } from "../alerts/AlertDanger";

export class BagTypeSuggestModal extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            error: "",
            value: props.bagTypeId ? { id: props.bagTypeId } : {},
            bagSize: props.value
        };

        this.onHide = this.onHide.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.setCustomValue = this.setCustomValue.bind(this);
        this.onBagSizeChange = this.onBagSizeChange.bind(this);
    }

    render() {
        return (
            <Modal
                className="bag-type-suggest-modal"
                show={this.props.showModal}
                onHide={this.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Тип мешков</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.error && (
                        <AlertDanger text={this.state.error} />
                    )}

                    <ListGroup variant="flush">
                        {(this.props.bagTypeId || this.props.value) && (
                            <ListGroup.Item
                                key="empty"
                                className="empty-value"
                                onMouseDown={this.onDelete}
                            >
                                <div className="square float-left mr-2 mt-2 mb-2 ml-3" />
                                <span>(не выбрано)</span>
                            </ListGroup.Item>
                        )}
                        {this.props.suggestValues.map(item => (
                            <ListGroup.Item
                                key={item.id}
                                data-item={JSON.stringify(item)}
                                className="bag-type-suggest-option"
                                active={this.state.value?.id === item.id}
                                onMouseDown={this.onSelectChange}
                            >
                                <div
                                    className="square float-left mr-2 mt-2 mb-2 ml-3"
                                    style={{
                                        background: item.colorBackground
                                    }}
                                />
                                <div className="mt-2">{item.size + " л"}</div>
                            </ListGroup.Item>
                        ))}
                        <ListGroup.Item
                            key="custom"
                            className="custom-value"
                            active={!this.state.value?.id}
                            onMouseDown={this.onSelectChange}
                        >
                            <div className="square float-left mr-2 mt-2 mb-2 ml-3" />
                            <FormControl
                                onBlur={this.setCustomValue}
                                value={
                                    this.state.bagSize != null
                                        ? this.state.bagSize
                                        : ""
                                }
                                onChange={this.onBagSizeChange}
                                className="w-25"
                            />
                            <span>л</span>
                        </ListGroup.Item>
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.onHide}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={this.onSave}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    onHide() {
        this.props.onHide();
    }

    onSave() {
        if (
            this.state.value.id &&
            this.props.bagTypeId === this.state.value.id
        ) {
            this.props.onHide();
            return;
        }

        if (this.state.value.bagSize && !Number(this.state.value.bagSize)) {
            this.setState({ error: "Некорретное значение" });
            return;
        }

        if (!this.state.value.id && this.state.value.bagSize === "") {
            this.onDelete();
            return;
        }

        this.props.onSave(this.state.value);
        this.props.onHide();
    }

    onDelete() {
        if (this.props.value) {
            this.props.onSave({ bagSize: null });
            this.props.onHide();
        }
    }

    onSelectChange(event) {
        const item = event.currentTarget.getAttribute("data-item");

        const value = JSON.parse(item);

        this.setState({ value }, () => {
            if (this.state.value?.id) {
                this.onSave();
            }
        });
    }

    onBagSizeChange(event) {
        const newValue = parseInt(event.target.value, 10);
        this.setState({
            bagSize: isNaN(newValue) || newValue === 0 ? "" : newValue
        });
    }

    setCustomValue(event) {
        const bagSize = event.currentTarget.value;

        this.setState({ value: { bagSize: bagSize }, error: "" });
    }
}
