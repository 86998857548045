/**
 * @param s String in hh:mm format
 * @returns {number} Time in minutes
 */
export function parseTime(s) {
    if (s) {
        const parts = s.split(":");
        return parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10);
    }

    return 0;
}

/**
 *
 * @param timeMins Time in minutes (may be negative)
 * @returns {string} Time string in hh:mm format
 */
export function formatTime(timeMins) {
    const h = Math.floor(Math.abs(timeMins) / 60);
    const m = Math.floor(Math.abs(timeMins) % 60);

    return (
        (timeMins >= 0 ? "" : "-") +
        (h < 10 ? "0" + h : h) +
        ":" +
        (m < 10 ? "0" + m : m)
    );
}

export function formatRange(minMins, maxMins) {
    return minMins === maxMins
        ? "~" + minMins + " мин"
        : minMins + "-" + maxMins + " мин";
}

/**
 * @returns {number} Current time in minutes
 */
export function getCurrentTime() {
    const date = new Date();
    return date.getHours() * 60 + date.getMinutes();
}
