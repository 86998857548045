export const actions = {
    change: "CHANGE_WARNING",
    remove: "REMOVE_WARNING"
};

export function changeWarning(warning) {
    return {
        type: actions.change,
        warning
    };
}

export function removeWarning() {
    return {
        type: actions.remove
    };
}
