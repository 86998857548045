import React, { PureComponent } from "react";
import { Modal, Table } from "react-bootstrap";

import { AlertDanger } from "./alerts/AlertDanger";

export class MattersInfoModal extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            error: null
        };
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Обозначения фракций</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.error && (
                        <AlertDanger text={this.state.error} />
                    )}
                    <Table borderless striped>
                        <thead>
                            <tr>
                                <th>Краткое</th>
                                <th>Полное</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.mattersInfo.map((matter, i) => (
                                <tr key={i}>
                                    <td>{matter.shortName}</td>
                                    <td>{matter.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        );
    }
}
