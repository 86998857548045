import React, { Component } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { MdErrorOutline } from "react-icons/md";

export class AlertDanger extends Component {
    render() {
        return (
            <Alert variant="danger" className={this.props.className}>
                <Row>
                    <Col xs={1}>
                        <MdErrorOutline />
                    </Col>
                    <Col>{this.props.text}</Col>
                </Row>
            </Alert>
        );
    }
}
