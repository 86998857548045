import React, { PureComponent } from "react";
import { FaCommentAlt } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { ActionCommentModal } from "./ActionCommentModal";

import { ServerErrorMessages } from "../../types/serverErrorMessages";
import { authorizedFetch } from "../../utils/authorizedFetch";
import config from "../../config";

import "./ActionToggler.css";

const actions = {
    NONE: "none",
    UNLOAD: "unload",
    DOWNLOAD: "download"
};

export class ActionToggler extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            id: props.action.id,
            action: getAction(props.action.action),
            comment: props.action.comment || "",
            showCommentModal: false
        };

        this.changeAction = this.changeAction.bind(this);
        this.deleteAction = this.deleteAction.bind(this);
        this.showCommentModal = this.showCommentModal.bind(this);
        this.hideCommentModal = this.hideCommentModal.bind(this);
        this.saveComment = this.saveComment.bind(this);
        this.getActionHint = this.getActionHint.bind(this);
    }

    render() {
        return [
            <div
                key="body"
                title={this.getActionHint(
                    this.props.matter.name,
                    this.state.action
                )}
                className={`action-toggler ${this.state.action}`}
                onClick={this.changeAction}
            >
                <span className="matter">{this.props.matter.shortName}</span>
                {this.renderComments()}
                {this.renderDeleteButton()}
            </div>,
            this.renderCommentModal()
        ];
    }

    renderCommentModal() {
        return (
            <ActionCommentModal
                key="changeComment"
                show={this.state.showCommentModal}
                actionId={this.state.id}
                value={this.state.comment}
                onHide={this.hideCommentModal}
                onSave={this.saveComment}
            />
        );
    }

    renderComments() {
        if (this.state.action === actions.NONE) {
            return null;
        }

        const commentIcon = (
            <FaCommentAlt
                onClick={this.showCommentModal}
                className={`comment ${this.state.comment ? "" : "empty"}`}
            />
        );

        // don't show empty tooltip
        if (!this.state.comment) {
            return commentIcon;
        }

        return (
            <OverlayTrigger
                placement="auto"
                overlay={<Tooltip>{this.state.comment}</Tooltip>}
            >
                {commentIcon}
            </OverlayTrigger>
        );
    }

    renderDeleteButton() {
        if (this.state.action === actions.NONE) {
            return null;
        }

        return (
            <MdClose
                className="delete"
                aria-hidden="true"
                onClick={this.deleteAction}
            />
        );
    }

    changeAction() {
        const that = this;

        if (this.state.action === actions.NONE) {
            authorizedFetch(`${config.protocolAndHost}/api/data/actions`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    movementId: this.props.movementId,
                    matter: this.props.matter.name,
                    matterId: this.props.matter.id,
                    action: "Загрузить",
                    comment: this.state.comment
                })
            })
                .then(response => {
                    return Promise.all([
                        response.status,
                        response.json()
                    ]).then(([status, body]) => ({ body, status }));
                })
                .then(result => {
                    if (result.status === 201) {
                        that.setState({
                            id: result.body.id,
                            action: actions.DOWNLOAD
                        });
                    } else if (that.props.onError) {
                        that.props.onError(
                            ServerErrorMessages[result.status] ||
                                "Не удалось сохранить изменение"
                        );
                    }
                })
                .catch(() => {
                    if (that.props.onError) {
                        that.props.onError("Не удалось сохранить изменение");
                    }
                });
        } else {
            const newAction =
                this.state.action === actions.DOWNLOAD
                    ? actions.UNLOAD
                    : actions.DOWNLOAD;

            authorizedFetch(
                `${config.protocolAndHost}/api/data/actions/${this.state.id}`,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        action:
                            newAction === actions.DOWNLOAD
                                ? "Загрузить"
                                : "Выгрузить"
                    })
                }
            )
                .then(response => {
                    if (response.status === 200) {
                        that.setState({
                            action: newAction
                        });
                    } else if (that.props.onError) {
                        that.props.onError(
                            ServerErrorMessages[response.status] ||
                                "Не удалось сохранить изменение"
                        );
                    }
                })
                .catch(() => {
                    if (that.props.onError) {
                        that.props.onError("Не удалось сохранить изменение");
                    }
                });
        }
    }

    deleteAction(event) {
        const that = this;
        event.stopPropagation();

        authorizedFetch(
            `${config.protocolAndHost}/api/data/actions/${this.state.id}`,
            {
                method: "DELETE"
            }
        )
            .then(response => {
                if (response.status === 204) {
                    that.setState({
                        action: actions.NONE
                    });
                } else if (that.props.onError) {
                    that.props.onError(
                        ServerErrorMessages[response.status] ||
                            "Не удалось удалить"
                    );
                }
            })
            .catch(() => {
                if (that.props.onError) {
                    that.props.onError("Не удалось удалить");
                }
            });

        return false;
    }

    hideCommentModal() {
        this.setState({ showCommentModal: false });
    }

    showCommentModal(event) {
        event.stopPropagation();

        this.setState({ showCommentModal: true });

        return false;
    }

    saveComment(comment) {
        this.setState({ comment });
    }

    getActionHint(matter, action) {
        switch (action) {
            case actions.DOWNLOAD:
                return "Загрузить: " + matter;
            case actions.UNLOAD:
                return "Выгрузить: " + matter;
            default:
                return matter;
        }
    }
}

function getAction(description) {
    switch (description) {
        case "Загрузить":
            return actions.DOWNLOAD;
        case "Выгрузить":
            return actions.UNLOAD;
        default:
        // hide linter warning
    }

    return actions.NONE;
}
