import React, { PureComponent } from "react";
import {
    Button,
    Container,
    Form,
    FormControl,
    Col,
    Table,
    Spinner,
    Row,
    Modal
} from "react-bootstrap";
import { MdDone } from "react-icons/md";
import { AiOutlineInfo } from "react-icons/ai";

import { AlertDanger } from "../components/alerts/AlertDanger";

import {
    getSelectedGoogleDocUrlLocalStorageKeyForEvent,
    selectedEventKey
} from "../utils/getSelectedGoogleDocUrlLocalStorageKey";
import { getData } from "../utils/getData";
import { authorizedFetch } from "../utils/authorizedFetch";
import { endsWithSpace } from "../utils/endsWithSpace";
import config from "../config";

import "./GoogleDocsSync.css";

export class GoogleDocsSync extends PureComponent {
    constructor(props) {
        super(props);

        const selectedEvent =
            Number(localStorage.getItem(selectedEventKey)) || "";

        this.state = {
            selectedEvent,
            url:
                (selectedEvent &&
                    localStorage.getItem(
                        getSelectedGoogleDocUrlLocalStorageKeyForEvent(
                            selectedEvent
                        )
                    )) ||
                "",
            events: [],
            diffs: null,
            error: null,
            isLoading: false,
            diffCount: 0,
            toShowDataKey: "",
            toShowDataValue: "",
            showKeyValueModal: false
        };

        this.onError = this.onError.bind(this);
        this.changeSelectedEvent = this.changeSelectedEvent.bind(this);
        this.changeURL = this.changeURL.bind(this);
        this.showDiff = this.showDiff.bind(this);
        this.renderDiff = this.renderDiff.bind(this);
        this.applyDiff = this.applyDiff.bind(this);
        this.showKeyValueModal = this.showKeyValueModal.bind(this);
        this.renderKeyValueModal = this.renderKeyValueModal.bind(this);
        this.onHideKeyValueModal = this.onHideKeyValueModal.bind(this);
    }

    componentDidMount() {
        getData({
            url: `${config.protocolAndHost}/api/data/events`
        })
            .then(events => {
                const event = this.state.selectedEvent;
                const selectedEvent = events.find(item => item.id === event)
                    ? event
                    : events?.[0]?.id;

                this.setState({
                    events: events,
                    selectedEvent
                });
            })
            .catch(this.onError);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedEvent !== this.state.selectedEvent) {
            localStorage.setItem(selectedEventKey, this.state.selectedEvent);
            this.setState({
                url:
                    (this.state.selectedEvent &&
                        localStorage.getItem(
                            getSelectedGoogleDocUrlLocalStorageKeyForEvent(
                                this.state.selectedEvent
                            )
                        )) ||
                    ""
            });
        }

        if (prevState.url !== this.state.url) {
            localStorage.setItem(
                getSelectedGoogleDocUrlLocalStorageKeyForEvent(
                    this.state.selectedEvent
                ),
                this.state.url
            );
        }
    }

    render() {
        return (
            <Container>
                {this.state.error && <AlertDanger text={this.state.error} />}
                <Form>
                    <Form.Group>
                        <Form.Row>
                            <Col xs={2}>
                                <FormControl
                                    as="select"
                                    value={this.state.selectedEvent}
                                    onChange={this.changeSelectedEvent}
                                    className="event-dropdown w-auto"
                                >
                                    {this.state.events.map(event => (
                                        <option key={event.id} value={event.id}>
                                            {event.date}
                                        </option>
                                    ))}
                                </FormControl>
                            </Col>
                            <Col>
                                <FormControl
                                    type="text"
                                    placeholder="Google Sheet URL"
                                    value={this.state.url}
                                    onChange={this.changeURL}
                                />
                            </Col>
                            <Col xs={1}>
                                <Button
                                    variant="primary"
                                    onClick={this.showDiff}
                                    disabled={
                                        !this.state.selectedEvent ||
                                        !this.state.url
                                    }
                                >
                                    Сравнить
                                </Button>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Form>
                {this.state.isLoading && (
                    <div className="spinner-wrapper">
                        <Spinner
                            animation="border"
                            role="status"
                            variant="secondary"
                        >
                            <span className="sr-only">Загрузка...</span>
                        </Spinner>
                    </div>
                )}
                {this.state.diffs !== null && this.state.diffs.length > 0 && (
                    <div>
                        <p>Всего различий: {this.state.diffCount}</p>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Поле</th>
                                    <th>Значение</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.diffs.map(this.renderDiff)}
                            </tbody>
                        </Table>
                    </div>
                )}
                {this.state.diffs !== null && this.state.diffs.length === 0 && (
                    <p>Различий не найдено</p>
                )}
                {this.renderKeyValueModal()}
            </Container>
        );
    }

    renderDiff(diff, index) {
        return (
            <tr key={diff.description}>
                <td>{diff.description}</td>
                <td>
                    <Row title={diff.key1} className="mb-2">
                        <Col lg={8}>
                            <pre
                                className={
                                    endsWithSpace(diff.value1)
                                        ? "border-bottom border-dark"
                                        : ""
                                }
                            >
                                {diff.value1}
                            </pre>
                            <MdDone
                                display={
                                    diff.key2 && diff.state === diff.key2
                                        ? ""
                                        : "none"
                                }
                                className="ml-2"
                            />
                        </Col>
                        <Col>
                            <Button
                                className="mr-2"
                                variant="light"
                                onClick={this.showKeyValueModal}
                                data-key={diff.key1}
                                data-value={diff.value1}
                            >
                                <AiOutlineInfo />
                            </Button>
                            <Button
                                className="float-right"
                                variant={
                                    !diff.key2 ||
                                    typeof diff.state !== "undefined"
                                        ? "light"
                                        : "success"
                                }
                                disabled={
                                    !diff.key2 ||
                                    typeof diff.state !== "undefined"
                                }
                                data-index={index}
                                data-key={diff.key2}
                                data-old-value={diff.value2}
                                data-new-value={diff.value1}
                                onClick={this.applyDiff}
                            >
                                Приложение
                            </Button>
                        </Col>
                    </Row>
                    <Row title={diff.key2}>
                        <Col lg={8}>
                            <pre
                                className={
                                    endsWithSpace(diff.value2)
                                        ? "border-bottom border-dark"
                                        : ""
                                }
                            >
                                {diff.value2}
                            </pre>
                            <MdDone
                                display={
                                    diff.key1 && diff.state === diff.key1
                                        ? ""
                                        : "none"
                                }
                                className="ml-2"
                            />
                        </Col>
                        <Col>
                            <Button
                                variant="light"
                                onClick={this.showKeyValueModal}
                                data-key={diff.key2}
                                data-value={diff.value2}
                            >
                                <AiOutlineInfo />
                            </Button>
                            <Button
                                className="float-right"
                                variant={
                                    !diff.key1 || diff.state ? "light" : "info"
                                }
                                disabled={!diff.key1 || diff.state}
                                data-index={index}
                                data-key={diff.key1}
                                data-old-value={diff.value1}
                                data-new-value={diff.value2}
                                onClick={this.applyDiff}
                            >
                                Google Sheet
                            </Button>
                        </Col>
                    </Row>
                </td>
            </tr>
        );
    }

    showDiff() {
        this.setState({ diffs: null, isLoading: true });
        authorizedFetch(
            `${config.protocolAndHost}/api/data/admin/diff/gs?eventId=${
                this.state.selectedEvent
            }&googleSheetUrl=${encodeURIComponent(this.state.url)}`
        )
            .then(result => {
                if (result.status === 200) {
                    return result.json();
                } else {
                    throw new Error("Something wrong");
                }
            })
            .then(diffs => {
                this.setState({
                    diffs: diffs,
                    isLoading: false,
                    diffCount: diffs.length
                });
            })
            .catch(this.onError);
    }

    applyDiff(event) {
        const index = Number(event.currentTarget.getAttribute("data-index"));

        let newDiffs = [...this.state.diffs];

        if (!newDiffs[index]) {
            return;
        }

        newDiffs[index].state = "preparing";
        this.setState({ diffs: newDiffs });

        const key = event.currentTarget.getAttribute("data-key");
        const oldValue = event.currentTarget.getAttribute("data-old-value");
        const newValue = event.currentTarget.getAttribute("data-new-value");

        authorizedFetch(`${config.protocolAndHost}/api/data/admin/diff`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                key,
                oldValue,
                newValue
            })
        })
            .then(result => {
                newDiffs = [...this.state.diffs];

                if (result.status === 200) {
                    newDiffs[index].state = key;
                    this.setState(prevState => ({
                        diffs: newDiffs,
                        diffCount: prevState.diffCount - 1
                    }));
                } else {
                    newDiffs[index].state = undefined;
                    this.setState({ diffs: newDiffs });
                }
            })
            .catch(this.onError);
    }

    showKeyValueModal(event) {
        const key = event.currentTarget.getAttribute("data-key");
        const value = event.currentTarget.getAttribute("data-value");
        this.setState({
            showKeyValueModal: true,
            toShowDataKey: key,
            toShowDataValue: value
        });
    }

    renderKeyValueModal() {
        return (
            <Modal
                size="lg"
                show={this.state.showKeyValueModal}
                onHide={this.onHideKeyValueModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Информация о значении</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={2}>
                            <b>Ключ: </b>
                        </Col>
                        <Col>{this.state.toShowDataKey}</Col>
                    </Row>
                    <Row>
                        <Col lg={2}>
                            <b>Значение: </b>
                        </Col>
                        <Col>
                            <pre>{this.state.toShowDataValue}</pre>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }

    onHideKeyValueModal() {
        this.setState({
            showKeyValueModal: false,
            toShowDataKey: "",
            toShowDataValue: ""
        });
    }

    changeSelectedEvent(event) {
        const eventId = Number(event.target.value);

        this.setState({
            selectedEvent: eventId
        });
    }

    changeURL(event) {
        this.setState({
            url: event.target.value
        });
    }

    onError(error) {
        this.setState({ error: error.message });
    }
}
