import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";

import { ServerErrorMessages } from "../types/serverErrorMessages";
import { authorizedFetch } from "../utils/authorizedFetch";
import { throttle } from "../utils/throttle";
import { changeWarning } from "../store/warning/actions";
import config from "../config";

import "./RangeInput.scss";

class RangeInputPresenter extends Component {
    constructor(props) {
        super(props);

        const value = props.value || 0;
        this.state = { value: value, savedValue: value };

        this.throttledAfterSetStateFinished = throttle(
            this.throttledAfterSetStateFinished.bind(this),
            1000
        );
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }

    render() {
        let value = this.state.value;
        return (
            <Col className={`range-input ${this.props.className}`}>
                <Row>
                    <Col>
                        <label>{this.props.description}</label>
                        <div className="float-right">
                            <input
                                type="text"
                                value={value}
                                size={3}
                                className="text-right no-outline"
                                readOnly
                            />
                            %
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <input
                            type="range"
                            className="custom-range"
                            min="0"
                            max="100"
                            step="5"
                            value={value}
                            onChange={this.onChangeHandler}
                        />
                    </Col>
                </Row>
            </Col>
        );
    }

    throttledAfterSetStateFinished() {
        const that = this;

        authorizedFetch(
            `${config.protocolAndHost}/api/data/${this.props.url}`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    [this.props.fieldName]: this.state.value || ""
                })
            }
        ).then(
            result => {
                if (result.status === 200) {
                    that.setState({ savedValue: that.state.value });
                } else {
                    if (result.status !== 401) {
                        that.onError();
                    }

                    that.props.changeWarning(
                        ServerErrorMessages[result.status] ||
                            "Не удалось сохранить изменения"
                    );
                }
            },
            error => {
                that.onError();

                that.props.changeWarning("Не удалось сохранить изменения");
            }
        );
    }

    onError() {
        this.setState({ value: this.state.savedValue });
    }

    onChangeHandler(event) {
        const value = event.target.value;

        this.setState({ value: value }, () => {
            this.throttledAfterSetStateFinished();
        });
    }
}

const mapDispatchToProps = dispatch => ({
    changeWarning: warning => dispatch(changeWarning(warning))
});

export const RangeInput = connect(
    null,
    mapDispatchToProps
)(RangeInputPresenter);
