import React, { PureComponent } from "react";
import { Col, Container, FormControl, ListGroup, Row } from "react-bootstrap";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";

import { AlertInfo } from "../alerts/AlertInfo";
import { PointMovements } from "../Movements/PointMovements";

import { getSelectedPointsLocalStorageKeyForEvent } from "../../utils/getSelectedPointsLocalStorageKey";
import { getData, NotFoundError } from "../../utils/getData";
import config from "../../config";

const hideMenuKey = "dispatcher_pointsLogistics_hideMenu";

export class PointsLogisticsView extends PureComponent {
    static defaultState = {
        searchedPoints: [],
        selectedPoints: [],
        movementsList: [],
        searchText: "",
        hideMenu: false
    };

    constructor(props) {
        super(props);

        const state = PointsLogisticsView.defaultState;

        state.searchedPoints = this.props.points;
        state.hideMenu = Boolean(localStorage.getItem(hideMenuKey));

        this.state = state;

        this.toggleMenuItemSelection = this.toggleMenuItemSelection.bind(this);
        this.searchPoints = this.searchPoints.bind(this);
        this.selectPoint = this.selectPoint.bind(this);
        this.showMenu = this.showMenu.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
    }

    componentDidMount() {
        if (this.props.points.length) {
            this.setSelectedPointFromStorage();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.points !== this.props.points) {
            this.setState(
                {
                    searchedPoints: this.state.searchText
                        ? this.props.points.filter(point =>
                              point.name
                                  .toLowerCase()
                                  .includes(this.state.searchText)
                          )
                        : this.props.points,
                    movementsList: []
                },
                () => {
                    this.setSelectedPointFromStorage();
                }
            );
        }

        if (
            prevState.selectedPoints.length !== this.state.selectedPoints.length
        ) {
            localStorage.setItem(
                getSelectedPointsLocalStorageKeyForEvent(
                    this.props.selectedEvent
                ),
                this.state.selectedPoints
            );
        }

        if (prevState.hideMenu !== this.state.hideMenu) {
            if (this.state.hideMenu) {
                localStorage.setItem(hideMenuKey, "yes");
            } else {
                localStorage.removeItem(hideMenuKey);
            }
        }
    }

    render() {
        return (
            <div className="dispatcher-view">
                {this.renderMenu()}
                <Container fluid className="main">
                    <Row className="mb-2 p-2 pt-4">
                        <Col>
                            <h3>Точки акции</h3>
                        </Col>
                    </Row>
                    {this.state.movementsList.map(item => (
                        <div key={item.point.id}>
                            <PointMovements
                                pointId={item.point.id}
                                pointName={this.props.pointsMap[item.point.id]}
                                movements={item.items}
                                matters={this.props.matters}
                                carriers={this.props.carriers}
                                carriersMap={this.props.carriersMap}
                                onError={this.props.onError}
                            />
                        </div>
                    ))}
                </Container>
            </div>
        );
    }

    renderMenu() {
        if (this.state.hideMenu) {
            return (
                <div className="side-menu">
                    <Row className="p-2 pt-4 ml-2 mt-2">
                        <AiOutlineMenuUnfold
                            size="20"
                            className="menu-visibility-toggler"
                            onClick={this.showMenu}
                        />
                    </Row>
                </div>
            );
        }
        return (
            <Container
                key="points"
                className="pl-0 pr-0 side-menu border-right"
            >
                <Row className="p-2 pt-4 pb-4 m-0 border-bottom w-100">
                    <Col lg={10}>
                        <FormControl
                            type="text"
                            placeholder="Поиск"
                            aria-label="Поиск по названию точки"
                            value={this.state.searchText}
                            onChange={this.searchPoints}
                        />
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center p-0">
                        <AiOutlineMenuFold
                            size="20"
                            className="menu-visibility-toggler"
                            onClick={this.hideMenu}
                        />
                    </Col>
                </Row>
                <ListGroup>
                    {this.state.searchText &&
                    this.state.searchedPoints.length === 0 ? (
                        <AlertInfo text="Ничего не нашлось" />
                    ) : null}
                    {this.state.searchedPoints &&
                        this.state.searchedPoints.map(point => (
                            <ListGroup.Item
                                data-pointid={point.id}
                                key={point.id}
                                className="rounded-0 border-right-0"
                                variant={
                                    this.isSelectedPoint(point.id)
                                        ? "success"
                                        : ""
                                }
                                onClick={this.toggleMenuItemSelection}
                            >
                                {point.name}
                            </ListGroup.Item>
                        ))}
                </ListGroup>
            </Container>
        );
    }

    showMenu() {
        this.setState({ hideMenu: false });
    }

    hideMenu() {
        this.setState({ hideMenu: true });
    }

    toggleMenuItemSelection(event) {
        const pointId = Number(event.target.getAttribute("data-pointid"));

        if (this.isSelectedPoint(pointId)) {
            this.deselectPoint(pointId);
        } else {
            this.selectPoint(pointId);
        }
    }

    selectPoint(pointId) {
        this.setState({
            selectedPoints: [...this.state.selectedPoints, pointId]
        });
        this.showDetails(pointId);
    }

    deselectPoint(pointId) {
        this.setState({
            selectedPoints: this.state.selectedPoints.filter(
                item => item !== pointId
            )
        });
        this.removeDetails(pointId);
    }

    isSelectedPoint(pointId) {
        return this.state.selectedPoints.indexOf(pointId) !== -1;
    }

    showDetails(pointId) {
        if (
            this.state.movementsList.findIndex(
                point => point.id === pointId
            ) !== -1
        ) {
            return;
        }

        getData({
            url: `${config.protocolAndHost}/api/view/dispatcher/movements/point?pointId=${pointId}`
        })
            .then(result => {
                if (
                    this.state.movementsList.findIndex(
                        point => point.id === pointId
                    ) !== -1
                ) {
                    return;
                }

                this.setState({
                    movementsList: [
                        ...this.state.movementsList,
                        result
                    ].sort((a, b) =>
                        this.props.pointsMap[a.point.id]?.localeCompare(
                            this.props.pointsMap[b.point.id],
                            "en"
                        )
                    )
                });
            })
            .catch(err => {
                if (err instanceof NotFoundError) {
                    this.deselectPoint(pointId);
                } else {
                    this.props.onError("Не удалось выбрать перевозчика");
                }
            });
    }

    removeDetails(pointId) {
        this.setState({
            movementsList: this.state.movementsList.filter(
                movement => movement.point.id !== pointId
            )
        });
    }

    searchPoints(event) {
        const searchText = event.target.value.toLowerCase();

        this.setState({
            searchText: searchText,
            searchedPoints: this.props.points.filter(point =>
                point.name.toLowerCase().includes(searchText)
            )
        });
    }

    setSelectedPointFromStorage() {
        const selectedPointsString = localStorage.getItem(
            getSelectedPointsLocalStorageKeyForEvent(this.props.selectedEvent)
        );

        if (!selectedPointsString) {
            this.setState({ selectedPoints: [] });
            return;
        }

        const selectedPointsList = selectedPointsString
            .split(",")
            .map(item => Number(item));

        selectedPointsList.forEach(pointId => this.showDetails(pointId));

        this.setState({ selectedPoints: selectedPointsList });
    }
}
