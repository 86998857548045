export const selectedEventKey = "dispatcher_selectedEvent";

export function getSelectedCarriersLocalStorageKey() {
    const selectedEvent = localStorage.getItem(selectedEventKey);
    return getSelectedCarriersLocalStorageKeyForEvent(selectedEvent);
}

export function getSelectedCarriersLocalStorageKeyForEvent(eventId) {
    return `dispatcher_event_${eventId}_selectedCarriers`;
}
