import React, { PureComponent } from "react";

import { BagTypeSuggestModal } from "./BagTypeSuggestModal";

import { authorizedFetch } from "../../utils/authorizedFetch";
import { ServerErrorMessages } from "../../types/serverErrorMessages";
import config from "../../config";

import "./BagTypeSuggest.scss";

export class BagTypeSuggest extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.save = this.save.bind(this);
        this.onHistoryChange = this.onHistoryChange.bind(this);
    }

    componentDidMount() {
        window.addEventListener("popstate", this.onHistoryChange);
    }

    componentWillUnmount() {
        window.removeEventListener("popstate", this.onHistoryChange);

        if (this.state.showModal) {
            window.history.back();
        }
    }

    render() {
        return (
            <>
                <div
                    className={`suggest-wrapper ${
                        !this.props.value ? "empty" : ""
                    }`}
                    key={this.state.showModal}
                    onClick={this.showModal}
                >
                    <div
                        className="square float-left mr-1"
                        style={{
                            background: this.props.background || "transparent"
                        }}
                    />
                    {this.props.value ? (
                        <span className="bag-type-value">
                            {this.props.value} л
                        </span>
                    ) : (
                        <span className="bag-type-placeholder">Объём</span>
                    )}
                </div>
                {this.state.showModal && (
                    <BagTypeSuggestModal
                        bagTypeId={this.props.item.bagTypeId}
                        value={this.props.value}
                        suggestValues={this.props.suggestValues}
                        showModal={this.state.showModal}
                        onSave={this.save}
                        onHide={this.hideModal}
                    />
                )}
            </>
        );
    }

    showModal() {
        window.history.pushState(
            { BagTypeSuggestModal: 1 },
            window.document.title
        );
        this.setState({ showModal: true });
    }

    hideModal() {
        window.history.back();
        this.setState({ showModal: false });
    }

    save(value) {
        if (this.props.id) {
            authorizedFetch(
                `${config.protocolAndHost}/api/data/pointmatteramounts/${this.props.id}`,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(
                        value.id
                            ? {
                                  bagTypeId: value.id,
                                  bagSize: null
                              }
                            : {
                                  bagTypeId: null,
                                  bagSize: value.bagSize
                              }
                    )
                }
            ).then(
                result => {
                    // TODO: подумать, что выводить, если значение не сохранилось
                    if (result.status === 200) {
                        this.props.onChange({
                            bagTypeId: value.id,
                            bagSize: value.bagSize || value.size,
                            colorBackground: value.colorBackground
                        });
                    } else {
                        this.props.onError &&
                            this.props.onError(
                                ServerErrorMessages[result.status]
                            );
                    }
                },
                () => {
                    this.props.onError && this.props.onError();
                }
            );
        } else {
            this.props.onChange({
                bagTypeId: value.id,
                bagSize: value.bagSize || value.size,
                colorBackground: value.colorBackground
            });
        }
    }

    onHistoryChange() {
        if (this.state.showModal) {
            this.setState({ showModal: false });
        }
    }
}
