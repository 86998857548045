import React, { PureComponent } from "react";
import { Modal, Table } from "react-bootstrap";

import config from "../config";

import "./MattersSummaryModal.scss";

export class MattersSummaryModal extends PureComponent {
    render() {
        const total = this.props.summary
            .map(e => e.amount)
            .reduce((a, b) => a + b, 0);

        return (
            <Modal
                className="matters-summary"
                show={this.props.show}
                onHide={this.props.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Итого</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <tbody>
                            {this.props.summary.map(matter => (
                                <tr key={matter.matterName}>
                                    <td>{matter.matterName}</td>
                                    <td className="column-value">
                                        {this.formatInteger(matter.amount)} л
                                    </td>
                                    <td className="column-value">
                                        {this.formatDecimal(
                                            matter.amount /
                                                config.defaultBagSizes
                                        )}{" "}
                                        меш
                                    </td>
                                </tr>
                            ))}
                            <tr key="total" className="total">
                                <td>Всего</td>
                                <td className="column-value">
                                    {this.formatInteger(total)} л
                                </td>
                                <td className="column-value">
                                    {this.formatDecimal(
                                        total / config.defaultBagSizes
                                    )}{" "}
                                    меш
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <span className="mt-2 text-black-50 footnotes">
                        1 стандартный мешок = {config.defaultBagSizes} л
                    </span>
                </Modal.Body>
            </Modal>
        );
    }

    formatDecimal(x) {
        return x > 1 - Number.EPSILON ? Math.ceil(x) : x > 0 ? x.toFixed(1) : 0;
    }

    formatInteger(x) {
        // group thousands (e.g. "12500" -> "12 500")
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
}
