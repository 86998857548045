import React, { PureComponent } from "react";
import { Container, FormControl, ListGroup, Spinner } from "react-bootstrap";
import { AlertDanger } from "../components/alerts/AlertDanger";

import { getData } from "../utils/getData";
import config from "../config";

import "./Points.css";
import { getSearchParams } from "../utils/getSearchParams";

export class Points extends PureComponent {
    state = {
        error: null,
        isLoaded: false,
        points: [],
        filterEvents: [],
        filterEventId: "",
        filterBasePoints: [],
        filterBasePointId: ""
    };

    componentDidMount() {
        const urlParams = getSearchParams();

        this.loadBasePoints();
        this.loadEvents(() => {
            this.setState(
                {
                    filterEventId:
                        urlParams.eventId || urlParams.basePointId
                            ? urlParams.eventId || ""
                            : this.state.filterEvents[0].id,
                    filterBasePointId: urlParams.basePointId || ""
                },
                () => this.loadPoints()
            );
        });
    }

    render() {
        const { error, isLoaded, points } = this.state;

        if (error) {
            return (
                <Container>
                    <AlertDanger text={error.message} />
                </Container>
            );
        } else {
            return (
                <div>
                    <div className="tabs">
                        <FormControl
                            as="select"
                            name="basePointId"
                            value={this.state.filterBasePointId}
                            onChange={this.onChangeBasePoint}
                            className={
                                "tab" +
                                (this.state.filterBasePointId
                                    ? " bold"
                                    : " text-black-50")
                            }
                        >
                            <option className="black" key="" value="">
                                Все точки
                            </option>
                            <option
                                className="black"
                                key="split"
                                value="split"
                                disabled
                            >
                                -
                            </option>
                            {this.state.filterBasePoints.map(e => (
                                <option
                                    className="black"
                                    key={e.id}
                                    value={e.id}
                                >
                                    {e.name}
                                </option>
                            ))}
                        </FormControl>
                        <FormControl
                            as="select"
                            name="eventId"
                            value={this.state.filterEventId}
                            onChange={this.onChangeEvent}
                            className={
                                "tab" +
                                (this.state.filterEventId
                                    ? " bold"
                                    : " text-black-50")
                            }
                        >
                            <option className="black" key="" value="">
                                Все акции
                            </option>
                            <option
                                className="black"
                                key="split"
                                value="split"
                                disabled
                            >
                                -
                            </option>
                            {this.state.filterEvents.map(e => (
                                <option
                                    className="black"
                                    key={e.id}
                                    value={e.id}
                                >
                                    {e.date}
                                </option>
                            ))}
                        </FormControl>
                    </div>
                    {isLoaded ? (
                        <ListGroup key="list" className="points">
                            {points.map(point => (
                                <ListGroup.Item key={point.id}>
                                    <a
                                        className="point row-flex"
                                        href={`/points/${point.id}`}
                                    >
                                        <div>{point.name}</div>
                                        <div className="text-black-50 nowrap">
                                            {point.date}
                                        </div>
                                    </a>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    ) : (
                        <div className="spinner-wrapper">
                            <Spinner
                                animation="border"
                                role="status"
                                variant="secondary"
                            >
                                <span className="sr-only">Загрузка...</span>
                            </Spinner>
                        </div>
                    )}
                </div>
            );
        }
    }

    loadBasePoints = callback => {
        getData({
            url: `${config.protocolAndHost}/api/data/basepoints/names`
        })
            .then(result => {
                this.setState(
                    {
                        isLoaded: true,
                        filterBasePoints: result
                    },
                    callback
                );
            })
            .catch(error => {
                this.setState({
                    isLoaded: true,
                    error
                });
            });
    };

    loadEvents = callback => {
        getData({
            url: `${config.protocolAndHost}/api/data/events`
        })
            .then(result => {
                this.setState(
                    {
                        isLoaded: true,
                        filterEvents: result
                    },
                    callback
                );
            })
            .catch(error => {
                this.setState({
                    isLoaded: true,
                    error
                });
            });
    };

    loadPoints = () => {
        getData({
            url:
                `${config.protocolAndHost}/api/view/points/namesAndDates?` +
                (this.state.filterEventId
                    ? "eventId=" + this.state.filterEventId
                    : "") +
                (this.state.filterBasePointId
                    ? "&basePointId=" + this.state.filterBasePointId
                    : "")
        })
            .then(result => {
                this.setState({
                    isLoaded: true,
                    points: result
                });
            })
            .catch(error => {
                this.setState({
                    isLoaded: true,
                    error
                });
            });
    };

    onChangeBasePoint = event => {
        const value = event.target.value;
        this.setState(
            {
                filterBasePointId: value,
                filterEventId: value ? "" : this.state.filterEventId,
                isLoaded: false
            },
            () => {
                this.loadPoints();
                window.history.replaceState(
                    null,
                    document.title,
                    "?basePointId=" + value
                );
            }
        );
    };

    onChangeEvent = event => {
        const value = event.target.value;
        this.setState(
            {
                filterEventId: value,
                filterBasePointId: value ? "" : this.state.filterBasePointId,
                isLoaded: false
            },
            () => {
                this.loadPoints();
                window.history.replaceState(
                    null,
                    document.title,
                    "?eventId=" + value
                );
            }
        );
    };
}
