import { authorizedFetch } from "./authorizedFetch";
import config from "../config";

export function refreshToken() {
    return authorizedFetch(`${config.protocolAndHost}/api/auth/refresh`, {
        method: "POST"
    })
        .then(response => {
            return Promise.all([
                response.ok,
                response.ok ? response.json() : {}
            ]).then(([ok, body]) => ({ ...body, ok }));
        })
        .then(result => {
            if (!result.ok) {
                return;
            }

            localStorage.setItem(
                "token",
                `${result.tokenType} ${result.accessToken}`
            );

            return result;
        });
}
