import React from "react";
import { Container } from "react-bootstrap";
import { AlertDanger } from "../components/alerts/AlertDanger";

export function NotFound() {
    return (
        <Container>
            <AlertDanger text="Такой страницы не существует" />
        </Container>
    );
}
