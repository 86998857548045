export function authorizedFetch(url, options = {}) {
    const token = localStorage.getItem("token");
    let newOptions = Object.assign(options);

    if (token) {
        newOptions.headers = newOptions.headers || {};
        newOptions.headers["Authorization"] = token;
    }

    return fetch(url, newOptions);
}
