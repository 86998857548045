import React, { PureComponent } from "react";
import { Button, Modal } from "react-bootstrap";

import { AlertDanger } from "./alerts/AlertDanger";

import { ServerErrorMessages } from "../types/serverErrorMessages";
import { authorizedFetch } from "../utils/authorizedFetch";
import config from "../config";

export class DeleteEntityModal extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            error: null
        };

        this.onHide = this.onHide.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.error && (
                        <AlertDanger text={this.state.error} />
                    )}
                    {/*TODO: use "text" instead of "entityName"*/}
                    {this.props.entityName}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.onHide}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={this.onDelete}>
                        Удалить
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    onHide() {
        this.setState({
            error: null
        });

        this.props.onHide();
    }

    onDelete() {
        const that = this;

        authorizedFetch(
            `${config.protocolAndHost}/api/data/${this.props.entityType}/${this.props.entityId}`,
            {
                method: "DELETE"
            }
        )
            .then(response => {
                if (response.status === 204) {
                    that.props.onDelete();
                    that.props.onHide();
                } else {
                    that.setState({
                        error:
                            ServerErrorMessages[response.status] ||
                            "Не удалось удалить"
                    });
                }
            })
            .catch(() => {
                that.setState({
                    error: "Не удалось удалить"
                });
            });
    }
}
