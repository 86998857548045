import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
    MdAccessTime,
    MdComment,
    MdFileDownload,
    MdFileUpload,
    MdLocationCity,
    MdLocationOn,
    MdPersonOutline
} from "react-icons/md";
import { FaRegEdit, FaSms } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import {
    Badge,
    Button,
    Card,
    Col,
    Container,
    ListGroup,
    Row,
    Spinner
} from "react-bootstrap";

import {
    FieldUpdateTextInput,
    patterns
} from "../components/FieldUpdateTextInput";
import { AlertInfo } from "../components/alerts/AlertInfo";
import { AlertDanger } from "../components/alerts/AlertDanger";
import { CollapsibleCard } from "../components/CollapsibleCard";
import { CallButton } from "../components/buttons/CallButton";
import { VKButton } from "../components/buttons/VKButton";
import { ListIconItem } from "../components/ListIconItem";
import { BagsList } from "../components/BagsList/BagsList";
import { MattersInfoModal } from "../components/MattersInfoModal";

import { getData } from "../utils/getData";
import { authorizedFetch } from "../utils/authorizedFetch";
import { getHomePageByRole } from "../utils/getHomePageByRole";
import { getMatterSummary } from "../utils/getMatterSummary";
import { getHomePageInfo } from "../store/homePageInfo/selectors";
import { changeHomePageInfo } from "../store/homePageInfo/actions";
import { Link } from "react-router-dom";
import { Pages } from "../types/pages";

import { getStatus } from "../utils/getStatus";
import { ServerErrorMessages } from "../types/serverErrorMessages";
import { Roles } from "../types/roles";
import { Status, StatusIcon } from "../types/status";
import config from "../config";

import "./Point.scss";
import { MattersSummaryModal } from "../components/MattersSummaryModal";

import {
    hour,
    msToSecond,
    recently,
    secToMinute,
    today
} from "../constants/time";
import { getCurrentTime, parseTime } from "../utils/time";

class PointPresenter extends PureComponent {
    state = {
        error: null,
        lastPointUrl: null,
        lastActiveTime: null,
        onlineInfo: null,
        isLoaded: false,
        baseInfo: {},
        info: {},
        movementActions: [],
        statMatters: [],
        statVolunteers: [],
        bagTypes: [],
        bagTypesMap: {},
        mattersInfo: [],
        isStatFilled: false,
        statFieldError: null,
        showMattersInfoModal: false,
        showMattersSummaryModal: false
    };

    constructor(props) {
        super(props);

        this.pointId = this.props.match.params.pointId;

        this.onStatFieldChange = this.onStatFieldChange.bind(this);
        this.onBagsChange = this.onBagsChange.bind(this);
        this.onMatterChange = this.onMatterChange.bind(this);
        this.syncWithForms = this.syncWithForms.bind(this);
        this.updateState = this.updateState.bind(this);
        this.hasCorrectParticipantsCount = this.hasCorrectParticipantsCount.bind(
            this
        );
        this.hasCorrectVolunteersCount = this.hasCorrectVolunteersCount.bind(
            this
        );
        this.hasCorrectVolunteers = this.hasCorrectVolunteers.bind(this);
        this.hasCorrectDonations = this.hasCorrectDonations.bind(this);
        this.hasCorrectMatters = this.hasCorrectMatters.bind(this);
        this.updateIsStatFilled = this.updateIsStatFilled.bind(this);
        this.onPointMatteraMountInKgSave = this.onPointMatteraMountInKgSave.bind(
            this
        );
        this.showMattersInfoModal = this.showMattersInfoModal.bind(this);
        this.hideMattersInfoModal = this.hideMattersInfoModal.bind(this);
        this.showMattersSummaryModal = this.showMattersSummaryModal.bind(this);
        this.hideMattersSummaryModal = this.hideMattersSummaryModal.bind(this);
        this.onHistoryChange = this.onHistoryChange.bind(this);
    }

    componentDidMount() {
        window.addEventListener("popstate", this.onHistoryChange);

        getData({
            url: `${config.protocolAndHost}/api/view/coordinator/points/${this.pointId}`
        })
            .then(result => {
                let lastPointUrl;
                const lastPointId = result.lastPointId;
                const pageForPoint = PointPresenter.getPageByPointId(
                    this.pointId
                );

                if (String(lastPointId) !== this.pointId) {
                    lastPointUrl = PointPresenter.getPageByPointId(lastPointId);

                    if (this.props.homePage === pageForPoint) {
                        this.props.changeHomePageInfo(lastPointUrl);
                    }
                }

                this.setState(
                    state => ({
                        isLoaded: true,
                        baseInfo: result.basePoint,
                        event: result.event,
                        info: result.point,
                        movementActions: this.updateScheduleShift(
                            result.movementActions
                        ),
                        statMatters: result.statMatters,
                        mattersSummary: result.statMatters.map(item =>
                            getMatterSummary(item, state.bagTypesMap)
                        ),
                        statVolunteers: result.statVolunteers,
                        lastPointUrl,
                        lastActiveTime: result.lastActiveTime,
                        onlineInfo: PointPresenter.buildOnlineInfo(
                            result.lastActiveTime
                        )
                    }),
                    this.updateIsStatFilled
                );

                this.timerId = setInterval(() => {
                    this.setState({
                        movementActions: this.updateScheduleShift(
                            this.state.movementActions
                        ),
                        onlineInfo: PointPresenter.buildOnlineInfo(
                            this.state.lastActiveTime
                        )
                    });
                }, 60 * 1000);
            })
            .catch(error => {
                this.setState({
                    isLoaded: true,
                    error
                });
            });

        getData({
            url: `${config.protocolAndHost}/api/data/bagtypes`
        })
            .then(result => {
                const bagTypesMap = result.reduce((acc, type) => {
                    acc[type.id] = type;

                    return acc;
                }, {});

                this.setState(state => ({
                    bagTypes: result,
                    bagTypesMap,
                    mattersSummary: state.statMatters.map(item =>
                        getMatterSummary(item, bagTypesMap)
                    )
                }));
            })
            .catch(error => {
                this.setState({
                    error
                });
            });

        getData({
            url: `${config.protocolAndHost}/api/data/matters`
        })
            .then(result => {
                this.setState({
                    mattersInfo: result
                });
            })
            .catch(error => {
                this.setState({
                    error
                });
            });
    }

    componentWillUnmount() {
        window.removeEventListener("popstate", this.onHistoryChange);

        clearInterval(this.timerId);
    }

    render() {
        const { error, isLoaded } = this.state;

        if (error) {
            return (
                <Container>
                    <AlertDanger text={error.message} />
                </Container>
            );
        }

        if (!isLoaded) {
            return (
                <div className="spinner-wrapper">
                    <Spinner
                        animation="border"
                        role="status"
                        variant="secondary"
                    >
                        <span className="sr-only">Загрузка...</span>
                    </Spinner>
                </div>
            );
        }

        return (
            <Container className="p-0 m-0 point">
                {this.state.lastPointUrl ? (
                    <AlertDanger
                        text={
                            <span>
                                Эта акция прошла {this.state.event.date}.
                                <br />
                                Для просмотра актуальной информации пройдите по{" "}
                                <a
                                    href={this.state.lastPointUrl}
                                    className="alert-link"
                                >
                                    ссылке
                                </a>
                            </span>
                        }
                        className="m-0"
                    />
                ) : null}
                {this.renderBaseInfo()}
                {this.renderLogistic()}
                {this.renderStatFields()}
                {this.renderMattersInfo()}
                {this.renderMattersSummary()}
            </Container>
        );
    }

    renderBaseInfo() {
        const { baseInfo, info, onlineInfo } = this.state;

        return (
            <CollapsibleCard
                title={baseInfo.name}
                subTitle={onlineInfo}
                buttons={
                    <div className="d-inline-flex float-right">
                        {info.linkVk && <VKButton vkLink={info.linkVk} />}
                        {info.managerPhone && (
                            <CallButton phoneNumber={info.managerPhone} />
                        )}
                    </div>
                }
                arrowPosition="left"
                body={
                    <ListGroup>
                        <ListIconItem
                            icon={MdPersonOutline}
                            info={info.managerName}
                        />

                        <ListIconItem
                            icon={MdLocationOn}
                            info={baseInfo.address}
                        />

                        <ListIconItem
                            icon={MdLocationCity}
                            info={baseInfo.district}
                        />

                        <ListIconItem
                            icon={MdAccessTime}
                            info={info.workingHours}
                        />

                        <ListIconItem
                            icon={MdComment}
                            info={
                                <FieldUpdateTextInput
                                    value={info.comment}
                                    placeholder="Комментарий"
                                    fieldName="comment"
                                    url={`points/${info.id}`}
                                    multiline={!!info.comment}
                                />
                            }
                        />
                        <ListGroup.Item className="d-flex flex-row border-left-0 border-right-0 justify-content-center">
                            <Link
                                className="all-points-link"
                                to={`${Pages.POINTS}?basePointId=${info.basePointId}`}
                            >
                                Все акции
                            </Link>
                        </ListGroup.Item>
                    </ListGroup>
                }
            />
        );
    }

    renderLogistic() {
        const { movementActions } = this.state;

        let nextPointIndices = [];
        if (movementActions) {
            for (let i = 0; i < movementActions.length; i++) {
                let currentPointIndex = movementActions[i].route.findIndex(
                    point => point.movementId === movementActions[i].movement.id
                );
                nextPointIndices.push(currentPointIndex + 1);
            }
        }

        return (
            <>
                {movementActions.length ? (
                    <div className="p-2 text-uppercase bg-primary text-white text-center">
                        Логистика
                    </div>
                ) : (
                    <AlertInfo text="Нет информации о логистике" />
                )}
                <div className="bg-light pt-2">
                    {movementActions.map((movementAction, i) => {
                        const status = getStatus(
                            movementAction.movement.timeArrivalActual,
                            movementAction.movement.timeDepartureActual
                        );

                        return (
                            <Card
                                key={movementAction.movement.id}
                                className="mb-2"
                            >
                                <Card.Header
                                    className={`pt-1 pb-1 border-bottom-0 ${
                                        status === Status.DONE
                                            ? "bg-light"
                                            : "bg-white"
                                    }`}
                                >
                                    <Row>
                                        <Col
                                            sm="auto"
                                            xs="auto"
                                            className="pr-0 align-self-center"
                                        >
                                            {StatusIcon[status]}
                                        </Col>
                                        <Col className="align-self-center text-truncate text-primary">
                                            <a
                                                href={`/movements/${movementAction.movement.id}`}
                                                className={`full-width ${
                                                    status === Status.DONE
                                                        ? "text-muted"
                                                        : ""
                                                }`}
                                            >
                                                {movementAction.carrier.name}
                                            </a>
                                            {movementAction.expectSms && (
                                                <FaSms
                                                    color={"#56ab47a6"}
                                                    className="ml-2"
                                                />
                                            )}
                                            <br />
                                            <span
                                                className={`mr-3 ${
                                                    status === Status.DONE
                                                        ? "text-muted"
                                                        : "text-black"
                                                }`}
                                            >
                                                {movementAction.movement
                                                    .timeArrivalActual ||
                                                    movementAction.movement
                                                        .timeArrivalExpected}
                                            </span>
                                            {Boolean(
                                                movementAction.scheduleShift
                                            ) &&
                                                PointPresenter.isValidTime(
                                                    movementAction.movement
                                                        .timeArrivalExpected
                                                ) &&
                                                status === Status.WAITING && (
                                                    <span
                                                        className={`mr-3 time ${
                                                            movementAction.scheduleShift >
                                                            0
                                                                ? "time-behind"
                                                                : "time-ahead"
                                                        }`}
                                                    >
                                                        {PointPresenter.formatScheduleShift(
                                                            movementAction.scheduleShift
                                                        )}
                                                    </span>
                                                )}
                                            {movementAction.carrier
                                                .carNumber && (
                                                <span
                                                    className={`plate ${
                                                        status === Status.DONE
                                                            ? "text-muted"
                                                            : "text-black"
                                                    }`}
                                                >
                                                    {
                                                        movementAction.carrier
                                                            .carNumber
                                                    }
                                                </span>
                                            )}
                                        </Col>
                                        {movementAction.carrier.driverPhone && (
                                            <Col
                                                sm={2}
                                                xs={2}
                                                className="pr-0 pl-0"
                                            >
                                                <div className="float-right">
                                                    <CallButton
                                                        phoneNumber={
                                                            movementAction
                                                                .carrier
                                                                .driverPhone
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                </Card.Header>
                                {status !== Status.DONE ? (
                                    <Card.Body
                                        className={`pt-1 pb-2 ${
                                            status === Status.DONE
                                                ? "bg-light"
                                                : ""
                                        }`}
                                    >
                                        {movementAction.actions.unload &&
                                            movementAction.actions.unload
                                                .length > 0 && (
                                                <Row className="pb-1">
                                                    <Col xs={1}>
                                                        <MdFileUpload
                                                            color={"#aaa"}
                                                        />
                                                    </Col>
                                                    <Col className="pl-2">
                                                        <span className="text-black-50">
                                                            {movementAction.actions.unload
                                                                .map(action => {
                                                                    return action.matter;
                                                                })
                                                                .join(", ")}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                        {movementAction.actions.load &&
                                            movementAction.actions.load.length >
                                                0 && (
                                                <Row className="pb-1">
                                                    <Col xs={1}>
                                                        <MdFileDownload
                                                            color={"#aaa"}
                                                        />
                                                    </Col>
                                                    <Col className="pl-2">
                                                        <span className="text-black-50">
                                                            {movementAction.actions.load
                                                                .map(action => {
                                                                    return action.matter;
                                                                })
                                                                .join(", ")}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}

                                        {movementAction.route &&
                                            nextPointIndices &&
                                            nextPointIndices[i] <
                                                movementAction.route.length && (
                                                <Row className="pb-1">
                                                    <Col xs={1}>
                                                        <FiArrowRight
                                                            color={"#aaa"}
                                                        />
                                                    </Col>
                                                    <Col className="pl-2">
                                                        <span className="text-black-50">
                                                            {
                                                                movementAction
                                                                    .route[
                                                                    nextPointIndices[
                                                                        i
                                                                    ]
                                                                ].name
                                                            }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                    </Card.Body>
                                ) : null}
                            </Card>
                        );
                    })}
                </div>
            </>
        );
    }

    renderStatFields() {
        const { info, statMatters, isStatFilled } = this.state;

        return (
            <CollapsibleCard
                title="Отчётность"
                arrowPosition="left"
                className="mb-2"
                isOpen={true}
                buttons={
                    isStatFilled ? (
                        <div className="d-inline-flex float-right pr-1">
                            <Badge variant="success">Заполнена</Badge>
                        </div>
                    ) : (
                        <div className="d-inline-flex float-right pr-1">
                            <Badge variant="warning">Не заполнена</Badge>
                        </div>
                    )
                }
                body={
                    <ListGroup>
                        {this.state.statFieldError && (
                            <AlertDanger text={this.state.statFieldError} />
                        )}
                        <ListGroup.Item variant="primary" className="rounded-0">
                            Люди
                        </ListGroup.Item>
                        <ListGroup.Item
                            className={
                                `d-flex flex-row` +
                                (this.state.correctParticipantsCount === false
                                    ? ` report-missing`
                                    : ` report-ok`)
                            }
                        >
                            <Col>Кол-во участников</Col>
                            <Col>
                                <FieldUpdateTextInput
                                    value={info.statParticipants}
                                    fieldName="statParticipants"
                                    placeholder="Количество"
                                    url={`points/${info.id}`}
                                    patternType="integer"
                                    notStrictPattern
                                    onChange={this.updateState(
                                        (state, value) =>
                                            (state.info.statParticipants = value),
                                        this.onStatFieldChange
                                    )}
                                />
                            </Col>
                        </ListGroup.Item>
                        <ListGroup.Item
                            className={
                                `d-flex flex-row` +
                                (this.state.correctVolunteersCount === false
                                    ? ` report-missing`
                                    : ` report-ok`)
                            }
                        >
                            <Col>Кол-во волонтёров</Col>
                            <Col>
                                <FieldUpdateTextInput
                                    value={info.statVolunteers}
                                    fieldName="statVolunteers"
                                    placeholder="Количество"
                                    url={`points/${info.id}`}
                                    patternType="integer"
                                    notStrictPattern
                                    onChange={this.updateState(
                                        (state, value) =>
                                            (state.info.statVolunteers = value),
                                        this.onStatFieldChange
                                    )}
                                />
                            </Col>
                        </ListGroup.Item>
                        <ListGroup.Item
                            className={
                                `d-flex flex-row border-bottom-0` +
                                (this.state.correctVolunteers === false
                                    ? ` report-missing`
                                    : ` report-ok`)
                            }
                        >
                            <Col>
                                Список волонтёров (
                                {this.state.statVolunteers.length})
                                <a
                                    className="btn btn-outline-primary float-right"
                                    href={`/volunteers/${this.pointId}`}
                                >
                                    <FaRegEdit />
                                </a>
                                {this.state.statVolunteers.length > 0 && (
                                    <ul className="mt-3">
                                        {this.state.statVolunteers.map(
                                            (volunteer, i) => (
                                                <li
                                                    key={i}
                                                    className="mt-1 mb-1"
                                                >
                                                    {volunteer.vkLink ? (
                                                        <a
                                                            href={
                                                                volunteer.vkLink
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {
                                                                volunteer.firstName
                                                            }{" "}
                                                            {volunteer.lastName}
                                                        </a>
                                                    ) : (
                                                        <span>
                                                            {
                                                                volunteer.firstName
                                                            }{" "}
                                                            {volunteer.lastName}
                                                        </span>
                                                    )}
                                                    {volunteer.comment && (
                                                        <span className="text-secondary">
                                                            {" ("}
                                                            {volunteer.comment}
                                                            {")"}
                                                        </span>
                                                    )}
                                                </li>
                                            )
                                        )}
                                    </ul>
                                )}
                            </Col>
                        </ListGroup.Item>
                        <ListGroup.Item
                            className={
                                `d-flex flex-row` +
                                (this.state.correctVolunteers === false
                                    ? ` report-missing`
                                    : ` report-ok`)
                            }
                        >
                            <Col>
                                Прочие волонтёры
                                <FieldUpdateTextInput
                                    value={info.statVolunteersContacts}
                                    fieldName="statVolunteersContacts"
                                    placeholder="Прочие волонтёры"
                                    url={`points/${info.id}`}
                                    onChange={this.updateState(
                                        (state, value) =>
                                            (state.info.statVolunteersContacts = value),
                                        this.onStatFieldChange
                                    )}
                                    maxLength={1024}
                                    multiline
                                />
                            </Col>
                        </ListGroup.Item>
                        <ListGroup.Item variant="primary">
                            Деньги
                        </ListGroup.Item>
                        <ListGroup.Item
                            className={
                                `d-flex flex-row` +
                                (this.state.correctDonations === false
                                    ? ` report-missing`
                                    : ` report-ok`)
                            }
                        >
                            <Col>Пожертвования</Col>
                            <Col>
                                <FieldUpdateTextInput
                                    value={info.statDonations}
                                    fieldName="statDonations"
                                    placeholder="В рублях"
                                    url={`points/${info.id}`}
                                    patternType="number"
                                    notStrictPattern
                                    onChange={this.updateState(
                                        (state, value) =>
                                            (state.info.statDonations = value),
                                        this.onStatFieldChange
                                    )}
                                />
                            </Col>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex flex-row report-ok">
                            <Col>
                                Проданные сувениры{" "}
                                <span className="text-secondary">
                                    (если было)
                                </span>
                                <FieldUpdateTextInput
                                    value={info.statSouvenirsInfo}
                                    fieldName="statSouvenirsInfo"
                                    placeholder="Проданные сувениры"
                                    url={`points/${info.id}`}
                                    onChange={this.updateState(
                                        (state, value) =>
                                            (state.info.statSouvenirsInfo = value),
                                        this.onStatFieldChange
                                    )}
                                    maxLength={1024}
                                    multiline
                                />
                            </Col>
                        </ListGroup.Item>
                        {statMatters.length > 0 && (
                            <ListGroup.Item
                                className="point-matters-header"
                                variant="primary"
                            >
                                <span className="float-left title">
                                    Вторсырьё
                                </span>
                                <Button
                                    variant="link"
                                    onClick={this.showMattersInfoModal}
                                >
                                    <AiOutlineQuestionCircle />
                                </Button>
                                <Button
                                    variant="link"
                                    onClick={this.showMattersSummaryModal}
                                >
                                    &Sigma;
                                </Button>
                            </ListGroup.Item>
                        )}
                        {statMatters.map((statMatter, i) => {
                            return (
                                <ListGroup.Item
                                    className={
                                        `d-flex flex-row` +
                                        (this.state.correctMatters &&
                                        this.state.correctMatters[i] === false
                                            ? ` report-missing`
                                            : ` report-ok`)
                                    }
                                    key={statMatter.matterId}
                                >
                                    <Col xs="3 bag-item">
                                        {statMatter.matter}
                                    </Col>
                                    <Col xs="9">
                                        {this.state.bagTypes.length > 0 && (
                                            <BagsList
                                                pointId={this.pointId}
                                                matterId={statMatter.matterId}
                                                bagTypes={this.state.bagTypes}
                                                bagTypesMap={
                                                    this.state.bagTypesMap
                                                }
                                                onChange={this.onBagsChange}
                                                onMatterChange={
                                                    this.onMatterChange
                                                }
                                                index={i}
                                                {...statMatter}
                                            />
                                        )}
                                    </Col>
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                }
            />
        );
    }

    onMatterChange(index, matterRows) {
        const newStatsMatters = this.state.statMatters.map(item => ({
            ...item
        }));
        newStatsMatters[index].items = matterRows;

        this.setState({ statMatters: newStatsMatters }, this.onStatFieldChange);
    }

    onPointMatteraMountInKgSave(value, matterId, id) {
        this.setState(state => {
            const statMatters = state.statMatters;
            const item = statMatters.find(item => item.matterId === matterId);
            item.items.push({ id, value });

            return state;
        }, this.onStatFieldChange);
    }

    onBagsChange(matterId, matterSum) {
        this.setState(state => {
            const newSummary = [...state.mattersSummary];
            const matter = newSummary.find(m => m.matterId === matterId);
            matter.amount = matterSum;

            return { mattersSummary: newSummary };
        });

        this.onStatFieldChange();
    }

    onStatFieldChange() {
        this.updateIsStatFilled(this.syncWithForms);
    }

    updateIsStatFilled(callback) {
        this.setState(() => {
            const s = {};

            s.isStatFilled = true;
            s.isStatFilled =
                (s.correctParticipantsCount = this.hasCorrectParticipantsCount()) &&
                s.isStatFilled;
            s.isStatFilled =
                (s.correctVolunteersCount = this.hasCorrectVolunteersCount()) &&
                s.isStatFilled;
            s.isStatFilled =
                (s.correctVolunteers = this.hasCorrectVolunteers()) &&
                s.isStatFilled;
            s.isStatFilled =
                (s.correctDonations = this.hasCorrectDonations()) &&
                s.isStatFilled;
            s.isStatFilled =
                (s.correctMatters = this.hasCorrectMatters()).every(
                    item => item
                ) && s.isStatFilled;

            return s;
        }, callback);
    }

    updateState(setter, callback) {
        return value => {
            this.setState(state => setter(state, value), callback);
        };
    }

    renderMattersInfo() {
        // We only need to display the matter info for the matters
        // that are valid for this point (statMatters)
        let ids = this.state.statMatters.map(obj => obj.matterId);
        let neededStatMattersInfo = this.state.mattersInfo.filter(value =>
            ids.includes(value.id)
        );
        return (
            <MattersInfoModal
                show={this.state.showMattersInfoModal}
                onHide={this.hideMattersInfoModal}
                mattersInfo={neededStatMattersInfo}
            />
        );
    }

    renderMattersSummary() {
        return (
            <MattersSummaryModal
                show={this.state.showMattersSummaryModal}
                onHide={this.hideMattersSummaryModal}
                summary={this.state.mattersSummary}
            />
        );
    }

    syncWithForms() {
        if (!this.state.isStatFilled) {
            return;
        }

        authorizedFetch(
            `${config.protocolAndHost}/api/forms/participants/${this.pointId}`,
            { method: "POST" }
        )
            .then(result => {
                if (result.status === 200) {
                    this.setState({
                        statFieldError: ""
                    });
                } else {
                    this.setState({
                        statFieldError:
                            ServerErrorMessages[result.status] ||
                            "Ошибка отправки отчёта"
                    });
                }
            })
            .catch(() => {
                this.setState({
                    statFieldError: "Ошибка отправки отчёта"
                });
            });
    }

    updateScheduleShift(movementActions) {
        const currentTime = getCurrentTime();

        return movementActions
            .map(movement => {
                movement.scheduleShift = PointPresenter.getScheduleShift(
                    movement.scheduleInfo.timeFront,
                    movement.scheduleInfo.timeShift,
                    currentTime
                );

                movement.predictedArrivalTime = PointPresenter.getPredictedArrivalTime(
                    movement.movement,
                    movement.scheduleShift
                );

                return movement;
            })
            .sort((a, b) => a.predictedArrivalTime - b.predictedArrivalTime);
    }

    showMattersInfoModal() {
        window.history.pushState(
            { MattersInfoModal: 1 },
            window.document.title
        );

        this.setState({ showMattersInfoModal: true });
    }

    hideMattersInfoModal() {
        window.history.back();
        this.setState({ showMattersInfoModal: false });
    }

    showMattersSummaryModal() {
        window.history.pushState(
            { MattersSummaryModal: 1 },
            window.document.title
        );

        this.setState({ showMattersSummaryModal: true });
    }

    hideMattersSummaryModal() {
        window.history.back();
        this.setState({ showMattersSummaryModal: false });
    }

    onHistoryChange() {
        if (this.state.showMattersInfoModal) {
            this.setState({ showMattersInfoModal: false });
        }
        if (this.state.showMattersSummaryModal) {
            this.setState({ showMattersSummaryModal: false });
        }
    }

    static getPredictedArrivalTime(movement, scheduleShift) {
        // CASE 1: actual arrival time specified -- so we already have what we need
        if (PointPresenter.isValidTime(movement.timeArrivalActual)) {
            return parseTime(movement.timeArrivalActual);
        }

        // CASE 2: actual departure time specified -- calc the expected/actual difference and apply it to expected arrival time
        else if (PointPresenter.isValidTime(movement.timeDepartureActual)) {
            const timeArrivalExpected = parseTime(movement.timeArrivalExpected);
            const timeDepartureExpected = parseTime(
                movement.timeDepartureExpected
            );
            const timeDepartureActual = parseTime(movement.timeDepartureActual);
            return (
                timeArrivalExpected +
                timeDepartureActual -
                timeDepartureExpected
            );
        }

        // CASE 3: no actual times specified, apply schedule shift to expected arrival time
        else {
            const timeArrivalExpected = parseTime(movement.timeArrivalExpected);
            return timeArrivalExpected + scheduleShift;
        }
    }

    static getScheduleShift(timeFront, timeShift, currentTime) {
        /**
         * Schedule shift remains constant (=timeShift) till some moment of time (timeFront), then it grows one minute per minute
         *
         * Example: carrier is expected to arrive at 15:00, but it left prev point being 5 mins late.
         * So schedule shift will remain +5 (timeShift = 5) till 15:05 (timeFront = 15*60+5 = 905), after that it will grow:
         *
         * currentTime | shift
         * 14:55 | +5
         * 15:00 | +5
         * 15:05 | +5
         * 15:06 | +6
         * 15:07 | +7
         */

        return currentTime < timeFront
            ? timeShift
            : timeShift + currentTime - timeFront;
    }

    static getPageByPointId(pointId) {
        return getHomePageByRole({
            roles: [Roles.COORDINATOR],
            homePointId: pointId
        });
    }

    hasCorrectParticipantsCount() {
        return PointPresenter.isValidInteger(this.state.info.statParticipants);
    }

    hasCorrectVolunteersCount() {
        return PointPresenter.isValidInteger(this.state.info.statVolunteers);
    }

    hasCorrectVolunteers() {
        return (
            (this.state.statVolunteers &&
                this.state.statVolunteers.length > 0) ||
            !!this.state.info.statVolunteersContacts
        );
    }

    hasCorrectDonations() {
        return PointPresenter.isValidNumber(this.state.info.statDonations);
    }

    hasCorrectMatters() {
        return this.state.statMatters.map(
            matter =>
                matter.items.length > 0 && matter.items.every(isMatterItemValid)
        );
    }

    static isValidInteger(value) {
        return !!value && new RegExp(patterns.integer).test(value);
    }

    static isValidNumber(value) {
        return !!value && new RegExp(patterns.number).test(value);
    }

    static isValidTime(time) {
        const timeRegExp = new RegExp(patterns.time);
        return time && timeRegExp.test(time);
    }

    static formatScheduleShift(timeMins) {
        const h = Math.floor(Math.abs(timeMins) / 60);
        const m = Math.floor(Math.abs(timeMins) % 60);

        if (h > 0) {
            return (
                (timeMins > 0 ? "+" : "-") +
                (h < 10 ? "0" + h : h) +
                ":" +
                (m < 10 ? "0" + m : m)
            );
        } else {
            return (timeMins > 0 ? "+" : "") + timeMins + " мин";
        }
    }

    static buildOnlineInfo(lastActivityTime) {
        if (!lastActivityTime) {
            return null;
        }

        const time = new Date(lastActivityTime);
        const elapsedMinutes = (new Date() - time) / msToSecond / secToMinute;

        if (elapsedMinutes < recently) {
            return "Онлайн";
        }

        if (elapsedMinutes < hour) {
            return (
                "Был(а) в сети " +
                PointPresenter.numericForm(
                    elapsedMinutes.toFixed(),
                    "минут",
                    "минуту",
                    "минуты"
                ) +
                " назад"
            );
        }

        if (elapsedMinutes < today) {
            return (
                "Был(а) в сети " +
                PointPresenter.numericForm(
                    (elapsedMinutes / hour).toFixed(),
                    "часов",
                    "час",
                    "часа"
                ) +
                " назад"
            );
        }

        return (
            "Был(а) в сети " +
            time.toLocaleString("ru-RU", {
                dateStyle: "short",
                timeStyle: "short"
            })
        );
    }

    static numericForm(number, noun0, noun1, noun2) {
        if (number > 10 && number < 15) {
            return number + " " + noun0;
        }

        switch (number % 10) {
            case 1:
                return number + " " + noun1;
            case 2:
            case 3:
            case 4:
                return number + " " + noun2;
            default:
                return number + " " + noun0;
        }
    }
}

const mapStateToProps = state => ({
    homePage: getHomePageInfo(state)
});

const mapDispatchToProps = dispatch => ({
    changeHomePageInfo: hp => dispatch(changeHomePageInfo(hp))
});

export const Point = connect(
    mapStateToProps,
    mapDispatchToProps
)(PointPresenter);

function isMatterItemValid(item) {
    const numberRegExp = new RegExp(patterns.number);
    return (
        item.value &&
        numberRegExp.test(item.value) &&
        (item.bagTypeId || item.bagSize)
    );
}
