import React, { Component } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { MdInfoOutline } from "react-icons/md";

export class AlertInfo extends Component {
    render() {
        return (
            <Alert variant="info" className={this.props.className}>
                <Row>
                    <Col xs={1}>
                        <MdInfoOutline />
                    </Col>
                    <Col>{this.props.text}</Col>
                </Row>
            </Alert>
        );
    }
}
