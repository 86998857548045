import React, { PureComponent } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { GoTrash } from "react-icons/go";

import { NewCarrierModal } from "../Movements/NewCarrierModal";
import { FieldUpdateTextInput } from "../FieldUpdateTextInput";
import { FieldUpdateTextInputWithSuggest } from "../FieldUpdateTextInputWithSuggest";
import { CarNumberUpdateInput } from "../CarNumberUpdateInput";
import { DeleteEntityModal } from "../DeleteEntityModal";

import {
    carPlanPopularValues,
    carTypePopularValues
} from "../../types/popularValues";
import { getData } from "../../utils/getData";
import { authorizedFetch } from "../../utils/authorizedFetch";
import config from "../../config";

export class CarriersView extends PureComponent {
    state = {
        carriers: [],
        showNewCarrierModal: false
    };

    constructor(props) {
        super(props);

        this.addNewCarrier = this.addNewCarrier.bind(this);
        this.showNewCarrierModal = this.showNewCarrierModal.bind(this);
        this.hideNewCarrierModal = this.hideNewCarrierModal.bind(this);
        this.deleteCarrier = this.deleteCarrier.bind(this);
        this.showDeleteCarrierModal = this.showDeleteCarrierModal.bind(this);
        this.hideDeleteCarrierModal = this.hideDeleteCarrierModal.bind(this);
        this.downloadOrder = this.downloadOrder.bind(this);
    }

    componentDidMount() {
        this.updateCarriers();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedEvent !== this.props.selectedEvent) {
            this.updateCarriers();
        }
    }

    render() {
        return (
            <Container fluid>
                <div className="carriers-view">
                    <Row className="mb-3 pt-4 pr-3">
                        <Col>
                            <h3>Перевозчики</h3>
                        </Col>
                        <Button
                            variant="link"
                            className="float-right mr-3"
                            onClick={this.downloadOrder}
                        >
                            Скачать заказ
                        </Button>
                        <Button
                            variant="primary"
                            className="float-right"
                            onClick={this.showNewCarrierModal}
                        >
                            Добавить перевозчика
                        </Button>
                    </Row>
                    {this.renderCarriers()}
                    <NewCarrierModal
                        key="newCarrier"
                        eventId={this.props.selectedEvent}
                        show={this.state.showNewCarrierModal}
                        onHide={this.hideNewCarrierModal}
                        onSave={this.addNewCarrier}
                    />
                </div>
            </Container>
        );
    }

    renderCarriers() {
        return (
            <Table responsive>
                <thead className="thead-light">
                    <tr>
                        <th key="name" className="wide-col">
                            Название
                        </th>
                        <th key="carNumber">Номер машины</th>
                        <th key="carPlan">Тариф</th>
                        <th key="carType">Тип</th>
                        <th key="driverName">Имя водителя</th>
                        <th key="driverPhone">Телефон водителя</th>
                        <th key="comment">Комментарий</th>
                        <th key="del"></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.carriers.map(carrier => (
                        <tr key={carrier.id}>
                            <td key="name" className="wide-col">
                                <FieldUpdateTextInput
                                    value={carrier.name}
                                    placeholder="Название"
                                    url={`carriers/${carrier.id}`}
                                    fieldName="name"
                                    onChange={this.props.onCarriersChange}
                                />
                            </td>
                            <td key="carNumber">
                                <CarNumberUpdateInput
                                    value={carrier.carNumber}
                                    url={`carriers/${carrier.id}`}
                                    fieldName="carNumber"
                                />
                            </td>
                            <td key="carPlan">
                                <FieldUpdateTextInputWithSuggest
                                    value={carrier.carPlan}
                                    suggestValues={carPlanPopularValues}
                                    placeholder="Тариф"
                                    url={`carriers/${carrier.id}`}
                                    fieldName="carPlan"
                                />
                            </td>
                            <td key="carType">
                                <FieldUpdateTextInputWithSuggest
                                    value={carrier.carType}
                                    suggestValues={carTypePopularValues}
                                    placeholder="Тип"
                                    url={`carriers/${carrier.id}`}
                                    fieldName="carType"
                                />
                            </td>
                            <td key="driverName">
                                <FieldUpdateTextInput
                                    value={carrier.driverName}
                                    placeholder="Имя водителя"
                                    url={`carriers/${carrier.id}`}
                                    fieldName="driverName"
                                />
                            </td>
                            <td key="driverPhone">
                                <FieldUpdateTextInput
                                    value={carrier.driverPhone}
                                    placeholder="8 900 000 00 00"
                                    url={`carriers/${carrier.id}`}
                                    fieldName="driverPhone"
                                    patternType="phone"
                                />
                            </td>
                            <td key="comment">
                                <FieldUpdateTextInput
                                    value={carrier.comment}
                                    placeholder="Комментарий"
                                    url={`carriers/${carrier.id}`}
                                    fieldName="comment"
                                    multiline
                                />
                            </td>
                            <td key="delButton">
                                <Button
                                    variant="outline-danger"
                                    data-carrier-id={carrier.id}
                                    data-carrier-name={carrier.name}
                                    onClick={this.showDeleteCarrierModal}
                                >
                                    <GoTrash />
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
                {this.renderDeleteCarrierModal()}
            </Table>
        );
    }

    renderDeleteCarrierModal() {
        return (
            <DeleteEntityModal
                key="deleteCarrier"
                show={this.state.showDeleteCarrierModal}
                title="Удалить перевозчика?"
                entityType="carriers"
                entityName={this.state.toDeleteCarrierName}
                entityId={this.state.toDeleteCarrierId}
                onHide={this.hideDeleteCarrierModal}
                onDelete={this.deleteCarrier}
            />
        );
    }

    addNewCarrier(newCarrier) {
        this.props.onCarriersChange();

        this.setState({
            carriers: [...this.state.carriers, newCarrier].sort((a, b) =>
                a.name.localeCompare(b.name, "en")
            )
        });
    }

    showNewCarrierModal() {
        this.setState({ showNewCarrierModal: true });
    }

    hideNewCarrierModal() {
        this.setState({ showNewCarrierModal: false });
    }

    updateCarriers() {
        getData({
            url: `${config.protocolAndHost}/api/data/carriers?eventId=${this.props.selectedEvent}`
        })
            .then(result => {
                this.setState({
                    carriers: result
                });
            })
            .catch(() => {
                this.props.onError("Не удалось получить данные о перевозчиках");
            });
    }

    showDeleteCarrierModal(event) {
        const carrierId = Number(
            event.currentTarget.getAttribute("data-carrier-id")
        );
        const carrierName = event.currentTarget.getAttribute(
            "data-carrier-name"
        );

        this.setState({
            showDeleteCarrierModal: true,
            toDeleteCarrierId: carrierId,
            toDeleteCarrierName: carrierName
        });
    }

    hideDeleteCarrierModal() {
        this.setState({
            showDeleteCarrierModal: false,
            toDeleteCarrierId: null,
            toDeleteCarrierName: null
        });
    }

    deleteCarrier() {
        this.setState({
            carriers: this.state.carriers.filter(
                item => item.id !== this.state.toDeleteCarrierId
            )
        });
    }

    downloadOrder() {
        const eventId = this.props.selectedEvent;
        const eventDate = this.props.events.find(
            e => e.id === this.props.selectedEvent
        ).date;

        authorizedFetch(
            `${config.protocolAndHost}/api/view/orders?eventId=${eventId}`,
            {
                headers: {
                    Accept:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            }
        )
            .then(res => res.blob())
            .then(blob => {
                const link = document.createElement("a");
                link.download = `Заказ_${eventDate}.xlsx`;
                link.href = URL.createObjectURL(blob);
                link.click();

                URL.revokeObjectURL(link.href);
            })
            .catch(() => {
                this.props.onError("Не удалось скачать заказ");
            });
    }
}
