import React from "react";
import ReactDOM from "react-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { mainReducer } from "./store";
import { App } from "./App";

import "./index.css";

const persistedState = localStorage.getItem("reduxState")
    ? JSON.parse(localStorage.getItem("reduxState"))
    : { homePage: null, warning: null };
const store = createStore(mainReducer, persistedState);

store.subscribe(() => {
    localStorage.setItem("reduxState", JSON.stringify(store.getState()));
});

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);
