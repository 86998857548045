import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Button, Container, Form } from "react-bootstrap";

import { AlertDanger } from "../components/alerts/AlertDanger";

import { changeHomePageInfo } from "../store/homePageInfo/actions";
import { parseJwt } from "../utils/parseJwt";
import { getHomePageByRole } from "../utils/getHomePageByRole";

import { Pages } from "../types/pages";
import config from "../config";

import "./Registration.css";

const textFields = {
    username: {
        description: "Логин"
    },
    password: {
        description: "Пароль",
        type: "password"
    },
    confirmPassword: {
        description: "Подтвердите пароль",
        type: "password"
    }
};

class RegistrationPresenter extends PureComponent {
    static runtimeValidations = {
        username: RegistrationPresenter.checkUsername
    };

    constructor(props) {
        super(props);

        const state = {
            points: [],
            homePointId: "",
            errorMessage: "",
            validationMessages: {},
            isSavingStarted: false,
            isCriticalError: false
        };

        Object.keys(textFields).forEach(name => {
            state[name] = "";
        });

        this.state = state;

        this.updatePoints();

        this.onSubmit = this.onSubmit.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.validateValues = this.validateValues.bind(this);
    }

    render() {
        return (
            <Container className="authorization-form">
                {this.state.errorMessage ? (
                    <AlertDanger text={this.state.errorMessage} />
                ) : null}
                <a className="back-to-authorization" href={Pages.AUTHORIZATION}>
                    ← Вернуться к форме авторизации
                </a>
                <Form
                    onSubmit={this.onSubmit}
                    className={this.state.errorMessage ? "invalid-form" : ""}
                >
                    <div className="info">
                        Вы создаёте ваш <b>личный</b> аккаунт.
                        <br />
                        Пожалуйста, выберите логин, по которому вас легко
                        узнать, и <b>никому</b> не сообщайте свой пароль
                    </div>
                    {Object.keys(textFields).map(fieldName => (
                        <Form.Group
                            key={fieldName}
                            className={
                                this.state.validationMessages[fieldName]
                                    ? "invalid"
                                    : ""
                            }
                        >
                            <Form.Label>
                                {textFields[fieldName].description}
                            </Form.Label>
                            <Form.Control
                                name={fieldName}
                                value={this.state[fieldName]}
                                type={textFields[fieldName].type}
                                onChange={this.onInputChange}
                                onBlur={this.validateValues}
                                placeholder={textFields[fieldName].description}
                                disabled={this.state.isCriticalError}
                                autoComplete="off"
                            />
                            {this.state.validationMessages[fieldName] && (
                                <div className="validation-message">
                                    {this.state.validationMessages[fieldName]}
                                </div>
                            )}
                        </Form.Group>
                    ))}
                    <Form.Group controlId="homePage">
                        <Form.Label>Точка координирования</Form.Label>
                        <Form.Control
                            as="select"
                            name="homePointId"
                            value={this.state.homePointId}
                            onChange={this.onInputChange}
                            disabled={this.state.isCriticalError}
                        >
                            <option value=""></option>
                            {this.state.points.map(point => (
                                <option key={point.id} value={point.id}>
                                    {point.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={
                            this.state.isSavingStarted ||
                            Object.keys(this.state.validationMessages).length ||
                            this.state.isCriticalError
                        }
                    >
                        Зарегистрироваться
                    </Button>
                    <div className="privacy-policy text-muted">
                        Нажимая "Зарегистрироваться", вы принимаете{" "}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={Pages.PRIVACY}
                        >
                            соглашение об обработке персональных данных
                        </a>
                    </div>
                </Form>
            </Container>
        );
    }

    onInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({ [name]: value });

        const validationMessages = { ...this.state.validationMessages };
        const validationFunction =
            RegistrationPresenter.runtimeValidations[name];
        const validationMessage =
            validationFunction && validationFunction(value);

        if (validationMessage) {
            validationMessages[name] = validationMessage;
        } else {
            delete validationMessages[name];
        }

        if (name === "password") {
            delete validationMessages["confirmPassword"];
        }

        this.setState({ validationMessages });
    }

    onSubmit(event) {
        const that = this;
        event.preventDefault();

        // if smb call onSubmit by Enter key
        if (this.state.isSavingStarted) {
            return;
        }

        const validationMessages = this.getValidationMessages();

        if (Object.keys(validationMessages).length) {
            this.setState({ validationMessages });

            return;
        }

        this.setState({ isSavingStarted: true });

        fetch(`${config.protocolAndHost}/api/auth/signup`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: this.state.username,
                password: this.state.password,
                homePointId: this.state.homePointId || null
            })
        })
            .then(response => {
                return Promise.all([
                    response.status,
                    response.json()
                ]).then(([status, body]) => ({ ...body, status }));
            })
            .catch(() => {
                throw new Error(
                    "Что-то пошло не по плану. Пожалуйста, обратитесь к администратору"
                );
            })
            .then(result => {
                if (result.status === 200) {
                    localStorage.setItem(
                        "token",
                        `${result.tokenType} ${result.accessToken}`
                    );

                    const userInfo = parseJwt(result.accessToken);
                    const homePage = getHomePageByRole(userInfo);

                    that.props.changeHomePageInfo(homePage);
                    that.props.history.push("/");
                } else {
                    this.setState({ isSavingStarted: false });

                    throw new Error(
                        config.registrationErrors[result.code] ||
                            "Что-то пошло не так, пожалуйста, попробуйте снова"
                    );
                }
            })
            .catch(err => {
                this.setState({
                    isSavingStarted: false,
                    errorMessage: err.message
                });
            });
    }

    validateValues(event) {
        const name = event.target.name;

        if (this.state.validationMessages[name]) {
            // field is already incorrect
            // don't check it again
            return;
        }

        const newValidationMessages = this.getValidationMessages();

        if (newValidationMessages[name]) {
            const validationMessages = { ...this.state.validationMessages };
            validationMessages[name] = newValidationMessages[name];

            this.setState({ validationMessages });
        }
    }

    updatePoints() {
        fetch(`${config.protocolAndHost}/api/data/basepoints/names`)
            .then(res => res.json())
            .then(result => {
                this.setState({
                    points: result
                });
            })
            .catch(() => {
                this.setState({
                    errorMessage:
                        "Простите, у нас возникли технические неполадки. Попробуйте зарегистрироваться позже",
                    isCriticalError: true
                });
            });
    }

    getValidationMessages() {
        const result = this.getLengthValidation();

        if (this.state.password !== this.state.confirmPassword) {
            result["confirmPassword"] = "Пароли не совпадают";
        }

        return result;
    }

    getLengthValidation() {
        const result = {};

        for (let fieldName in textFields) {
            if (!config.lengthLimitation[fieldName]) {
                continue;
            }

            const length = this.state[fieldName].length;
            const { min, max } = config.lengthLimitation[fieldName];
            if (length < min || length > max) {
                result[fieldName] = getLengthValidationMessage(fieldName);
            }
        }

        return result;
    }

    static checkUsername(value) {
        const allowedSymbols = "[\\w-@\\.]+";

        if (RegExp(`^${allowedSymbols}$`).test(value)) {
            // everything is all right
            return;
        }

        if (value.includes(" ")) {
            return "Недопустимо использовать пробел";
        }

        const forbiddenSymbols = value
            .replace(RegExp(allowedSymbols, "g"), "")
            .split("")
            .filter((item, index, self) => self.indexOf(item) === index);

        if (forbiddenSymbols.length === 1) {
            return `Символ "${forbiddenSymbols[0]}" не разрешен`;
        }

        const preparedForbiddenList = forbiddenSymbols
            .map(symbol => `"${symbol}"`)
            .join(", ");

        return `Символы ${preparedForbiddenList} не разрешены`;
    }
}

function getLengthValidationMessage(fieldName) {
    const lengthLimitation = config.lengthLimitation[fieldName];
    return `От ${lengthLimitation.min} до ${lengthLimitation.max} символов`;
}

const mapDispatchToProps = dispatch => ({
    changeHomePageInfo: hp => dispatch(changeHomePageInfo(hp))
});

export const Registration = compose(
    withRouter,
    connect(undefined, mapDispatchToProps)
)(RegistrationPresenter);
