const config = {
    protocolAndHost: "https://rslogs.org:8001",
    vkAppId: 7357495,
    registrationErrors: {
        "400001": "Пользователь с таким именем уже существует",
        "400002": "Выберите другую точку для курирования",
        "500003":
            "Исчерпано максимальное количество зарегистрированных пользователей. Обратитесь к администратору"
    },
    lengthLimitation: {
        username: {
            min: 6,
            max: 32
        },
        password: {
            min: 6,
            max: 32
        }
    },
    defaultBagSizes: 120
};

export default config;
