import React, { PureComponent } from "react";
import { BagInfo } from "./BagInfo";

import { getMatterSum } from "../../utils/getMatterSummary";

import "./BagsList.scss";

export class BagsList extends PureComponent {
    addedItemsCount = 0;

    constructor(props) {
        super(props);

        const items = [
            ...props.items.map(item => {
                item.key = item.id;

                if (item.bagTypeId) {
                    const bagInfo = props.bagTypesMap[item.bagTypeId];

                    item.bagSize = bagInfo?.size;
                    item.colorBackground = bagInfo?.colorBackground;
                }

                return item;
            }),
            this.generateEmptyLine()
        ];

        this.state = {
            items,
            isValid: this.isValid(items)
        };

        this.renderLine = this.renderLine.bind(this);
        this.addNew = this.addNew.bind(this);
        this.onChange = this.onChange.bind(this);
        this.callOnChange = this.callOnChange.bind(this);
        this.onChangeItem = this.onChangeItem.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
    }

    render() {
        return <span>{this.state.items.map(this.renderLine)}</span>;
    }

    renderLine(item, index) {
        return (
            <BagInfo
                key={item.key}
                index={index}
                item={item}
                bagTypes={this.props.bagTypes}
                pointId={this.props.pointId}
                matterId={this.props.matterId}
                onCreate={this.addNew}
                onChange={this.onChangeItem}
                onDelete={this.onDeleteItem}
            />
        );
    }

    addNew(index, item) {
        const newItems =
            index === this.state.items.length - 1
                ? [...this.state.items, this.generateEmptyLine()]
                : [...this.state.items];

        item.key = newItems[index].key;

        if (!item.bagSize) {
            const bagInfo = this.props.bagTypesMap[item.bagTypeId];

            item.bagSize = bagInfo?.size;
            item.colorForeground = bagInfo?.colorForeground;
            item.colorBackground = bagInfo?.colorBackground;
        }

        newItems[index] = item;

        this.setState(
            {
                items: newItems
            },
            this.onChange
        );
    }

    generateEmptyLine() {
        return {
            key: `k-${this.addedItemsCount++}`
        };
    }

    onChange() {
        this.setState(
            { isValid: this.isValid(this.state.items) },
            this.callOnChange
        );
    }

    callOnChange() {
        this.props.onChange(
            this.props.matterId,
            getMatterSum(this.state.items, this.props.bagTypesMap)
        );
        this.props.onMatterChange(
            this.props.index,
            this.state.items.filter(item => item.id)
        );
    }

    onChangeItem(index, item) {
        const items = [...this.state.items];
        items[index] = item;
        this.setState({ items }, this.onChange);
    }

    onDeleteItem(index) {
        const items = this.state.items;
        items[index].id = undefined;
        const newItems = items.filter((_, itemIndex) => itemIndex !== index);
        // have to use separate setState to avoid batching on setState, because we use state value on onChange call
        this.setState(
            {
                items: newItems
            },
            this.onChange
        );
    }

    isValid(items) {
        const nonemptyItems = items.filter(item => item.id);

        return (
            nonemptyItems.length > 0 &&
            nonemptyItems.every(item => {
                const hasBagInfo =
                    item.bagTypeId != null && item.bagSize !== null;
                return item.value && hasBagInfo;
            })
        );
    }
}
