import { Component } from "react";
import React from "react";

import "./Buttons.css";

const noPropagation = e => e.stopPropagation();

export class VKButton extends Component {
    render() {
        return (
            <a
                className="btn-contact btn btn-outline-primary"
                href={this.props.vkLink}
                onClick={noPropagation}
            >
                ВК
            </a>
        );
    }
}
