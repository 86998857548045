import { Col, ListGroup } from "react-bootstrap";
import React from "react";

export function ListIconItem(props) {
    return (
        props.info && (
            <ListGroup.Item className="d-flex flex-row pl-0 border-left-0 border-right-0">
                <Col className="list-item-icon-col">
                    <props.icon size={30} color={"#aaa"} />
                </Col>
                <Col>{props.info}</Col>
            </ListGroup.Item>
        )
    );
}
