import React, { PureComponent } from "react";
import {
    Col,
    Container,
    FormControl,
    ListGroup,
    Row,
    Button
} from "react-bootstrap";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";

import { CarrierMovements } from "../Movements/CarrierMovements";
import { NewCarrierModal } from "../Movements/NewCarrierModal";
import { AlertInfo } from "../alerts/AlertInfo";

import { getSelectedCarriersLocalStorageKeyForEvent } from "../../utils/getSelectedCarriersLocalStorageKey";
import { getData, NotFoundError } from "../../utils/getData";
import config from "../../config";

import "./CarriersLogisticsView.css";

const hideMenuKey = "dispatcher_carriersLogistics_hideMenu";

export class CarriersLogisticsView extends PureComponent {
    static defaultState = {
        searchedCarriers: [],
        selectedCarriers: [],
        carrierMovementsList: [],
        searchText: "",
        hideMenu: false,
        showNewCarrierModal: false
    };

    constructor(props) {
        super(props);

        const state = CarriersLogisticsView.defaultState;

        state.searchedCarriers = this.props.carriers;
        state.hideMenu = Boolean(localStorage.getItem(hideMenuKey));

        this.state = state;

        this.toggleMenuItemSelection = this.toggleMenuItemSelection.bind(this);
        this.searchCarriers = this.searchCarriers.bind(this);
        this.selectCarrier = this.selectCarrier.bind(this);
        this.showMenu = this.showMenu.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
        this.showNewCarrierModal = this.showNewCarrierModal.bind(this);
        this.hideNewCarrierModal = this.hideNewCarrierModal.bind(this);
        this.addNewCarrier = this.addNewCarrier.bind(this);
    }

    componentDidMount() {
        if (this.props.carriers.length) {
            this.setSelectedCarrierFromStorage();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.carriers !== this.props.carriers) {
            this.setState(
                {
                    searchedCarriers: this.state.searchText
                        ? this.props.carriers.filter(carrier =>
                              carrier.name
                                  .toLowerCase()
                                  .includes(this.state.searchText)
                          )
                        : this.props.carriers,
                    carrierMovementsList: []
                },
                () => {
                    this.setSelectedCarrierFromStorage();
                }
            );
        }

        if (
            prevState.selectedCarriers.length !==
            this.state.selectedCarriers.length
        ) {
            localStorage.setItem(
                getSelectedCarriersLocalStorageKeyForEvent(
                    this.props.selectedEvent
                ),
                this.state.selectedCarriers
            );
        }

        if (prevState.hideMenu !== this.state.hideMenu) {
            if (this.state.hideMenu) {
                localStorage.setItem(hideMenuKey, "yes");
            } else {
                localStorage.removeItem(hideMenuKey);
            }
        }
    }

    render() {
        return (
            <div className="dispatcher-view">
                {this.renderMenu()}
                <Container fluid className="main">
                    <Row className="mb-2 p-2 pt-4">
                        <Col>
                            <h3>Перевозчики акции</h3>
                        </Col>
                        <Col>
                            <Button
                                variant="primary"
                                className="float-right"
                                onClick={this.showNewCarrierModal}
                            >
                                Добавить перевозчика
                            </Button>
                            <NewCarrierModal
                                key="newCarrier"
                                eventId={this.props.selectedEvent}
                                show={this.state.showNewCarrierModal}
                                onHide={this.hideNewCarrierModal}
                                onSave={this.addNewCarrier}
                            />
                        </Col>
                    </Row>
                    {this.state.carrierMovementsList.map(carrier => (
                        <div key={carrier.carrier.id} className="carrier">
                            <CarrierMovements
                                carrierId={carrier.carrier.id}
                                carrierName={carrier.carrier.name}
                                movements={carrier.items}
                                matters={this.props.matters}
                                points={this.props.points}
                                pointsInfo={this.props.pointsInfo}
                                onError={this.props.onError}
                            />
                        </div>
                    ))}
                </Container>
            </div>
        );
    }

    renderMenu() {
        if (this.state.hideMenu) {
            return (
                <div className="side-menu">
                    <Row className="p-2 pt-4 ml-2 mt-2">
                        <AiOutlineMenuUnfold
                            size="20"
                            className="menu-visibility-toggler"
                            onClick={this.showMenu}
                        />
                    </Row>
                </div>
            );
        }
        return (
            <Container className="pl-0 pr-0 side-menu border-right">
                <Row className="p-2 pt-4 pb-4 m-0 border-bottom w-100">
                    <Col lg={10}>
                        <FormControl
                            type="text"
                            placeholder="Поиск"
                            aria-label="Поиск по названию перевозчика"
                            value={this.state.searchText}
                            onChange={this.searchCarriers}
                        />
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center p-0">
                        <AiOutlineMenuFold
                            size="20"
                            className="menu-visibility-toggler"
                            onClick={this.hideMenu}
                        />
                    </Col>
                </Row>
                <ListGroup>
                    {this.state.searchText &&
                    this.state.searchedCarriers.length === 0 ? (
                        <AlertInfo text="Ничего не нашлось" />
                    ) : null}
                    {this.state.searchedCarriers &&
                        this.state.searchedCarriers.map(carrier => (
                            <ListGroup.Item
                                data-carrierid={carrier.id}
                                key={carrier.id}
                                className="rounded-0 border-right-0"
                                variant={
                                    this.isSelectedCarrier(carrier.id)
                                        ? "success"
                                        : ""
                                }
                                onClick={this.toggleMenuItemSelection}
                            >
                                {carrier.name}
                            </ListGroup.Item>
                        ))}
                </ListGroup>
            </Container>
        );
    }

    showMenu() {
        this.setState({ hideMenu: false });
    }

    hideMenu() {
        this.setState({ hideMenu: true });
    }

    showNewCarrierModal() {
        this.setState({ showNewCarrierModal: true });
    }

    hideNewCarrierModal() {
        this.setState({ showNewCarrierModal: false });
    }

    addNewCarrier(newCarrier) {
        this.props.onCarriersChange();

        this.setState({
            selectedCarriers: [...this.state.selectedCarriers, newCarrier.id]
        });
    }

    toggleMenuItemSelection(event) {
        const carrierId = Number(event.target.getAttribute("data-carrierid"));

        if (this.isSelectedCarrier(carrierId)) {
            this.deselectCarrier(carrierId);
        } else {
            this.selectCarrier(carrierId);
        }
    }

    selectCarrier(carrierId) {
        this.setState({
            selectedCarriers: [...this.state.selectedCarriers, carrierId]
        });
        this.showDetails(carrierId);
    }

    deselectCarrier(carrierId) {
        this.setState({
            selectedCarriers: this.state.selectedCarriers.filter(
                item => item !== carrierId
            )
        });
        this.removeDetails(carrierId);
    }

    isSelectedCarrier(carrierId) {
        return this.state.selectedCarriers.indexOf(carrierId) !== -1;
    }

    showDetails(carrierId) {
        if (
            this.state.carrierMovementsList.findIndex(
                carrier => carrier.id === carrierId
            ) !== -1
        ) {
            return;
        }

        getData({
            url: `${config.protocolAndHost}/api/view/dispatcher/movements/carrier?carrierId=${carrierId}`
        })
            .then(result => {
                if (
                    this.state.carrierMovementsList.findIndex(
                        carrier => carrier.id === carrierId
                    ) !== -1
                ) {
                    return;
                }

                this.setState({
                    carrierMovementsList: [
                        ...this.state.carrierMovementsList,
                        result
                    ].sort((a, b) =>
                        a.carrier.name.localeCompare(b.carrier.name, "en")
                    )
                });
            })
            .catch(err => {
                if (err instanceof NotFoundError) {
                    this.deselectCarrier(carrierId);
                } else {
                    this.props.onError("Не удалось выбрать перевозчика");
                }
            });
    }

    removeDetails(carrierId) {
        this.setState({
            carrierMovementsList: this.state.carrierMovementsList.filter(
                movement => movement.carrier.id !== carrierId
            )
        });
    }

    searchCarriers(event) {
        const searchText = event.target.value.toLowerCase();

        this.setState({
            searchText: searchText,
            searchedCarriers: this.props.carriers.filter(carrier =>
                carrier.name.toLowerCase().includes(searchText)
            )
        });
    }

    setSelectedCarrierFromStorage() {
        const selectedCarriersString = localStorage.getItem(
            getSelectedCarriersLocalStorageKeyForEvent(this.props.selectedEvent)
        );

        if (!selectedCarriersString) {
            this.setState({ selectedCarriers: [] });
            return;
        }

        const selectedCarriersList = selectedCarriersString
            .split(",")
            .map(item => Number(item));

        selectedCarriersList.forEach(carrierId => this.showDetails(carrierId));

        this.setState({ selectedCarriers: selectedCarriersList });
    }
}
