import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Button, Navbar } from "react-bootstrap";

import { getHomePageInfo } from "../store/homePageInfo/selectors";
import { removeHomePageInfo } from "../store/homePageInfo/actions";
import { Pages } from "../types/pages";
import logo from "../assets/img/logo.png";

import "./Header.css";

class HeaderPresenter extends PureComponent {
    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
        this.onMainRedirectHandler = this.onMainRedirectHandler.bind(this);
    }

    render() {
        return (
            <Navbar
                bg="success"
                variant="dark"
                className="header pr-0 border-bottom"
            >
                <Navbar.Brand
                    href={this.props.homePage || "/"}
                    onClick={this.onMainRedirectHandler}
                >
                    <img
                        src={logo}
                        style={{ height: 30 }}
                        className="d-inline-block align-top"
                        alt="РазДельный Сбор"
                    />
                </Navbar.Brand>
                <Navbar.Toggle />
                {this.props.homePage ? (
                    <Navbar.Collapse className="justify-content-end">
                        <Button variant="success" href={Pages.POINTS}>
                            Точки
                        </Button>
                        <Button
                            variant="success"
                            href="/"
                            onClick={this.logout}
                        >
                            Выйти
                        </Button>
                    </Navbar.Collapse>
                ) : null}
            </Navbar>
        );
    }

    onMainRedirectHandler(event) {
        if (
            window.location.pathname ===
            (this.props.homePage || Pages.AUTHORIZATION)
        ) {
            event.preventDefault();
        }
    }

    logout() {
        localStorage.removeItem("token");
        this.props.removeHomePageInfo();
    }
}

const mapStateToProps = state => ({
    homePage: getHomePageInfo(state)
});

const mapDispatchToProps = dispatch => ({
    removeHomePageInfo: () => dispatch(removeHomePageInfo())
});

export const Header = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderPresenter);
