export const carTypePopularValues = ["4-х метровая газель (высокий тент)"];
export const carPlanPopularValues = [
    "город, 1 час",
    "город, 2 часа",
    "город, 3 часа",
    "город, 4 часа",
    "пригород, 2 часа",
    "пригород, 3 часа",
    "пригород, 4 часа"
];
