import React, { Component } from "react";
import { connect } from "react-redux";

import { BagTypeSuggest } from "./BagTypeSuggest";
import { FieldUpdateBagsCountInput } from "./FieldUpdateBagsCountInput";

import { changeWarning } from "../../store/warning/actions";
import { authorizedFetch } from "../../utils/authorizedFetch";
import { ServerErrorMessages } from "../../types/serverErrorMessages";
import config from "../../config";

import "./BagInfo.scss";

class BagInfoPresenter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item
        };

        this.typeChangeHandler = this.typeChangeHandler.bind(this);
        this.countChangeHandler = this.countChangeHandler.bind(this);
        this.update = this.update.bind(this);
        this.onError = this.onError.bind(this);
    }

    render() {
        const item = this.state.item;

        return (
            <span className="bags-list-item" key={item.id}>
                <BagTypeSuggest
                    value={item.bagSize}
                    background={item.colorBackground}
                    suggestValues={this.props.bagTypes}
                    placeholder="Мешок"
                    url=""
                    id={item.id}
                    item={item}
                    className="bags-type"
                    patternType="integer"
                    notStrictPattern
                    onChange={this.typeChangeHandler}
                    onError={this.onError}
                />
                <div className="multiply-icon ml-1 mr-2">×</div>
                <FieldUpdateBagsCountInput
                    item={item}
                    value={item.value}
                    fieldName="value"
                    placeholder="Количество"
                    url={item.id ? `pointmatteramounts/${item.id}` : ""}
                    patternType="number"
                    notStrictPattern
                    onInput={this.countChangeHandler}
                    onBlur={this.update}
                    className="bags-count"
                    nullable
                />
            </span>
        );
    }

    typeChangeHandler(value) {
        this.setState(
            {
                item: { ...this.state.item, ...value }
            },
            this.update
        );
    }

    countChangeHandler(value) {
        this.setState(
            {
                item: { ...this.state.item, value }
            },
            this.update
        );
    }

    update() {
        if (!this.state.item.id && !this.isEmpty) {
            return this.create();
        }

        if (this.state.item.id && this.isEmpty) {
            return this.delete();
        }

        this.props.onChange(this.props.index, this.state.item);
    }

    get isEmpty() {
        const item = this.state.item;

        return !item.value && !item.bagTypeId && !item.bagSize;
    }

    create() {
        if (this.state.freezeSaving) {
            return;
        }

        this.freezeSaving();
        const item = this.state.item;

        authorizedFetch(
            `${config.protocolAndHost}/api/data/pointmatteramounts`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    pointId: this.props.pointId,
                    matterId: this.props.matterId,
                    bagTypeId: item.bagTypeId,
                    bagSize: item.bagSize,
                    value: item.value
                })
            }
        )
            .then(response => {
                return Promise.all([
                    response.status,
                    response.json()
                ]).then(([status, body]) => ({ body, status }));
            })
            .then(
                result => {
                    if (result.status === 201) {
                        const item = this.state.item;
                        item.id = result.body.id;

                        this.props.onCreate(this.props.index, result.body);
                    } else {
                        this.onError(ServerErrorMessages[result.status]);
                    }

                    this.unfreezeSaving();
                },
                () => {
                    this.unfreezeSaving();
                    this.onError();
                }
            )
            .catch(() => {
                this.unfreezeSaving();
            });
    }

    delete() {
        authorizedFetch(
            `${config.protocolAndHost}/api/data/pointmatteramounts/${this.state.item.id}`,
            {
                method: "DELETE"
            }
        )
            .then(response => {
                if (response.status === 204) {
                    this.setState(state => {
                        const item = state.item;

                        return {
                            item: {
                                ...item,
                                colorForeground: item.bagTypeId
                                    ? item.colorForeground
                                    : undefined,
                                colorBackground: item.bagTypeId
                                    ? item.colorBackground
                                    : undefined,
                                id: undefined
                            }
                        };
                    });
                    this.props.onDelete(this.props.index);
                } else {
                    this.onError(
                        ServerErrorMessages[response.status] ||
                            "Не удалось удалить"
                    );
                }
            })
            .catch(() => {
                this.onError();
            });
    }

    onError(msg = "Не удалось сохранить изменения") {
        this.props.changeWarning(msg);
    }

    freezeSaving() {
        this.setState({ freezeSaving: true });
    }

    unfreezeSaving() {
        this.setState({ freezeSaving: false });
    }
}

const mapDispatchToProps = dispatch => ({
    changeWarning: warning => dispatch(changeWarning(warning))
});

export const BagInfo = connect(null, mapDispatchToProps)(BagInfoPresenter);
