import React, { PureComponent } from "react";
import "./Privacy.css";

export class Privacy extends PureComponent {
    render() {
        return (
            <div className="privacy">
                <h1 className="header title">
                    Политика Ассоциации «РазДельный Сбор» в отношении обработки
                    персональных данных
                </h1>

                <h2 className="header subtitle">1. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
                <p>
                    1.1. Настоящая политика Ассоциации в отношении обработки
                    персональных данных (далее – политика) предоставляет
                    информацию об основных принципах обработки персональных
                    данных (далее – ПДн) и реализуемых требованиях к защите ПДн.
                    Политика разработана в соответствии с требованиями
                    федерального закона от 27.07.2006 г. № 152-ФЗ «О
                    персональных данных» (далее – ФЗ-152).
                </p>
                <p>
                    1.2. Настоящая политика является общедоступным документом и
                    подлежит неограниченному распространению.
                </p>

                <h2 className="header subtitle">
                    2. ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
                </h2>
                <p>
                    2.1. Обработка персональных данных внутри Экологического
                    движения «РазДельный Сбор» (далее Движение) осуществляется
                    на основе принципов:
                </p>
                <ul>
                    <li>
                        законности и справедливости основы обработки ПДн,
                        законности целей и способов обработки ПДн;
                    </li>
                    <li>
                        соответствия целей обработки персональных данных целям,
                        заранее определённым и заявленным при сборе ПДн;
                    </li>
                    <li>
                        соответствия содержания и объёма обрабатываемых ПДн,
                        способов обработки ПДн заявленным целям обработки ПДн;
                    </li>
                    <li>
                        обеспечения точности, достаточности и актуальности ПДн
                        по отношению к целям их обработки, недопустимости
                        избыточности обрабатываемых ПДн по отношению к
                        заявленным целям их обработки;
                    </li>
                    <li>
                        недопустимости объединения созданных для несовместимых
                        между собой целей баз данных ПДн.
                    </li>
                </ul>
                <p>
                    2.2. При определении состава обрабатываемых персональных
                    данных субъектов персональных данных Движение
                    руководствуется минимально необходимым составом персональных
                    данных для достижения целей получения персональных данных.
                </p>

                <h2 className="header subtitle">
                    3. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
                </h2>
                <p>
                    3.1. Движение осуществляет сбор и обработку ПДн для
                    достижения следующих целей:
                </p>
                <ul>
                    <li>
                        подготовка и координация акций по раздельному сбору
                        вторсырья;
                    </li>
                    <li>
                        коммуникация с участниками акций для сбора
                        статистической информации (объёмы собранного сырья,
                        количество участников, количество волонтёров и др.);
                    </li>
                    <li>
                        оповещение участников об изменениях в проводимых акциях.
                    </li>
                </ul>
                <p>3.2. Перечень обрабатываемых персональных данных:</p>
                <ul>
                    <li>имя, фамилия;</li>
                    <li>телефон;</li>
                    <li>адрес электронной почты;</li>
                    <li>
                        адрес личной страницы в социальной сети «ВКонтакте»;
                    </li>
                    <li>
                        публично доступная фотография (аватар) из социальной
                        сети «ВКонтакте».
                    </li>
                </ul>

                <h2 className="header subtitle">
                    4. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
                </h2>
                <p>
                    4.1. Обработка персональных данных осуществляется в
                    соответствии с целями, заранее определёнными и заявленными
                    при сборе персональных данных.
                </p>
                <p>
                    4.2. Согласие на обработку персональных данных может быть
                    дано субъектом персональных данных или его представителем в
                    любой позволяющей подтвердить факт его получения форме, если
                    иное не установлено ФЗ-152.
                </p>
                <p>
                    4.3. Обработка специальных категорий персональных данных,
                    касающихся расовой, национальной принадлежности,
                    политических взглядов, религиозных или философских
                    убеждений, интимной жизни, Движением не осуществляется.
                </p>
                <p>
                    4.4. Право доступа к персональным данным субъектов
                    персональных данных на бумажных и электронных носителях
                    имеет строго ограниченный круг участников Движения в
                    соответствии с реализацией ими заявленных целей сбора ПДн.
                </p>
                <p>
                    4.5. Передача персональных данных субъектов персональных
                    данных третьим лицам Движением не осуществляется, кроме
                    случаев, предусмотренных требованиями действующего
                    законодательства.
                </p>

                <h2 className="header subtitle">
                    5. ПРАВА СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ
                </h2>
                <p>
                    5.1. Субъект персональных данных вправе требовать от
                    Движения уточнения его персональных данных, их блокирования
                    или уничтожения в случае, если персональные данные являются
                    неполными, устаревшими, неточными, незаконно полученными или
                    не являются необходимыми для заявленной цели обработки, а
                    также принимать предусмотренные законом меры по защите своих
                    прав.
                </p>
                <p>
                    5.2. Субъект персональных данных имеет право на получение
                    информации, касающейся обработки его персональных данных, в
                    том числе содержащей:
                </p>
                <ul>
                    <li>
                        подтверждение факта обработки персональных данных
                        Движением;
                    </li>
                    <li>
                        правовые основания и цели обработки персональных данных;
                    </li>
                    <li>
                        цели и применяемые Движением способы обработки
                        персональных данных;
                    </li>
                    <li>
                        сроки обработки персональных данных, в том числе сроки
                        их хранения;
                    </li>
                    <li>
                        иные сведения, предусмотренные ФЗ-152 или другими
                        федеральными законами.
                    </li>
                </ul>
                <p>
                    5.3. Субъект персональных данных имеет право отозвать
                    согласие на обработку персональных данных, направив
                    соответствующее уведомление на адрес{" "}
                    <a href="mailto:rsbor.ru@gmail.com">rsbor.ru@gmail.com</a>.
                </p>

                <h2 className="header subtitle">
                    6. СВЕДЕНИЯ О РЕАЛИЗУЕМЫХ ТРЕБОВАНИЯХ К ЗАЩИТЕ ПЕРСОНАЛЬНЫХ
                    ДАННЫХ
                </h2>
                <p>
                    6.1. Движение при обработке персональных данных принимает
                    необходимые правовые, организационные и технические меры для
                    защиты персональных данных от неправомерного или случайного
                    доступа к ним, уничтожения, изменения, копирования,
                    предоставления, распространения персональных данных, а также
                    от иных неправомерных действий в отношении персональных
                    данных.
                </p>
                <p>
                    6.2. Меры по обеспечению безопасности персональных данных
                    при их обработке, применяемые Движением, планируются и
                    реализуются в целях обеспечения соответствия требованиям,
                    приведенным в статье 19 152-ФЗ «О персональных данных».
                </p>
                <p>
                    6.3. В соответствии со статьей 18 ФЗ-152 Движение
                    самостоятельно определяет состав и перечень мер, необходимых
                    и достаточных для обеспечения выполнения требований
                    законодательства. В частности приняты следующие меры:
                </p>
                <ul>
                    <li>
                        назначен ответственный за организацию обработки ПДн;
                    </li>
                    <li>
                        участники Движения, непосредственно осуществляющие
                        обработку ПДн, ознакомлены с положениями
                        законодательства Российской Федерации о ПДн, в том числе
                        требованиями к защите ПДн, документами, определяющими
                        политику Движения в отношении обработки ПДн, локальными
                        актами по вопросам обработки ПДн.
                    </li>
                </ul>
            </div>
        );
    }
}
