export const Pages = {
    AUTHORIZATION: "/authorization",
    REGISTRATION: "/registration",
    PRIVACY: "/privacy",
    ADMIN: "/admin",
    GOOGLE_DOCS_SYNC: "/googledocssync",
    DISPATCHER: "/dispatcher",
    POINTS: "/points",
    MOVEMENTS: "/movements",
    VOLUNTEERS: "/volunteers"
};
