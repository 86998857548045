import { actions } from "./actions";

export const homePage = (state = [], action) => {
    switch (action.type) {
        case actions.change:
            return action.homePage;
        case actions.remove:
            return null;
        default:
            return state;
    }
};
