export const actions = {
    change: "CHANGE_HOMEPAGEINFO",
    remove: "REMOVE_HOMEPAGEINFO"
};

export function changeHomePageInfo(homePage) {
    return {
        type: actions.change,
        homePage
    };
}

export function removeHomePageInfo() {
    return {
        type: actions.remove
    };
}
