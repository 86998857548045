import React, { PureComponent } from "react";
import { Button, FormControl, Table } from "react-bootstrap";
import { GoTrash } from "react-icons/go";

import { ActionToggler } from "./ActionToggler";

import { TimeUpdateInput } from "../TimeUpdateInput";
import { FieldUpdateTextInput } from "../FieldUpdateTextInput";
import { DeleteEntityModal } from "../DeleteEntityModal";

import { ServerErrorMessages } from "../../types/serverErrorMessages";
import { authorizedFetch } from "../../utils/authorizedFetch";
import config from "../../config";

import "./Movements.css";

export class PointMovements extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            movements: props.movements || [],
            actions: prepareActions(props.movements),
            showDeleteMovementModal: false
        };

        this.addCarrier = this.addCarrier.bind(this);
        this.changeCarrier = this.changeCarrier.bind(this);
        this.deleteMovement = this.deleteMovement.bind(this);
        this.hideDeleteMovementModal = this.hideDeleteMovementModal.bind(this);
        this.showDeleteMovementModal = this.showDeleteMovementModal.bind(this);
    }

    render() {
        const that = this;
        return (
            <Table responsive className="movements">
                <thead className="thead-light">
                    <tr>
                        <th colSpan={100}>{this.props.pointName}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.movements.map(movement => (
                        <tr key={movement.movement.id}>
                            <td key="carrier" className="wide-col">
                                <FormControl
                                    as="select"
                                    movementid={movement.movement.id}
                                    carrierid={movement.movement.carrierId}
                                    value={movement.movement.carrierId}
                                    onChange={this.changeCarrier}
                                >
                                    {this.props.carriers.map(carrier => (
                                        <option
                                            key={carrier.id}
                                            value={carrier.id}
                                        >
                                            {carrier.name}
                                        </option>
                                    ))}
                                </FormControl>
                            </td>
                            <td key="timeArrivalExpected">
                                <TimeUpdateInput
                                    value={
                                        movement.movement.timeArrivalExpected
                                    }
                                    placeholder="приб"
                                    title="Плановое время прибытия"
                                    required
                                    fieldName="timeArrivalExpected"
                                    url={`movements/${movement.movement.id}`}
                                />
                            </td>
                            <td key="timeDepartureExpected">
                                <TimeUpdateInput
                                    value={
                                        movement.movement.timeDepartureExpected
                                    }
                                    placeholder="отпр"
                                    title="Плановое время отправления"
                                    fieldName="timeDepartureExpected"
                                    url={`movements/${movement.movement.id}`}
                                />
                            </td>
                            {this.props.matters.map(matter => (
                                <td key={matter.id}>
                                    <ActionToggler
                                        matter={matter}
                                        action={
                                            that.state.actions[
                                                movement.movement.id
                                            ][matter.id] || { action: {} }
                                        }
                                        movementId={movement.movement.id}
                                        onError={that.props.onError}
                                    />
                                </td>
                            ))}
                            <td key="comment" className="comment-col">
                                <FieldUpdateTextInput
                                    value={movement.movement.comment}
                                    placeholder="Комментарий"
                                    fieldName="comment"
                                    url={`movements/${movement.movement.id}`}
                                    multiline
                                />
                            </td>
                            <td key="delButton">
                                <Button
                                    variant="outline-danger"
                                    data-movement-id={movement.movement.id}
                                    data-movement-name={
                                        that.props.carriersMap[
                                            movement.movement.carrierId
                                        ]
                                    }
                                    onClick={this.showDeleteMovementModal}
                                >
                                    <GoTrash />
                                </Button>
                            </td>
                        </tr>
                    ))}
                    <tr key="last">
                        <td key="point-selector" className="wide-col">
                            <FormControl
                                as="select"
                                value=""
                                onChange={this.addCarrier}
                            >
                                <option disabled value="">
                                    Добавить перевозчика
                                </option>
                                {this.props.carriers.map(carrier => (
                                    <option key={carrier.id} value={carrier.id}>
                                        {carrier.name}
                                    </option>
                                ))}
                            </FormControl>
                        </td>
                        <td key="timeArrivalExpectedStub"></td>
                        <td key="timeDepartureExpectedStub"></td>
                        {this.props.matters.map(matter => (
                            <td key={matter.id}></td>
                        ))}
                        <td key="commentStub" className="comment-col"></td>
                        <td key="delButtonStub"></td>
                    </tr>
                </tbody>
                {this.renderDeleteMovementModal()}
            </Table>
        );
    }

    renderDeleteMovementModal() {
        return (
            <DeleteEntityModal
                key="deleteMovement"
                show={this.state.showDeleteMovementModal}
                title="Удалить точку маршрута?"
                entityType="movements"
                entityName={this.state.toDeleteMovementName}
                entityId={this.state.toDeleteMovementId}
                onHide={this.hideDeleteMovementModal}
                onDelete={this.deleteMovement}
            />
        );
    }

    hideDeleteMovementModal() {
        this.setState({
            showDeleteMovementModal: false,
            toDeleteMovementId: null,
            toDeleteMovementName: null
        });
    }

    showDeleteMovementModal(event) {
        const movementId = Number(
            event.currentTarget.getAttribute("data-movement-id")
        );
        const movementName = event.currentTarget.getAttribute(
            "data-movement-name"
        );

        this.setState({
            showDeleteMovementModal: true,
            toDeleteMovementId: movementId,
            toDeleteMovementName: movementName
        });
    }

    deleteMovement() {
        this.setState({
            movements: this.state.movements.filter(
                item => item.movement.id !== this.state.toDeleteMovementId
            )
        });
    }

    addCarrier(event) {
        const that = this;
        const carrierId = parseInt(event.target.value, 10);

        authorizedFetch(`${config.protocolAndHost}/api/data/movements`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                pointId: this.props.pointId,
                carrierId: carrierId
            })
        })
            .then(response => {
                return Promise.all([
                    response.status,
                    response.json()
                ]).then(([status, body]) => ({ body, status }));
            })
            .then(result => {
                if (result.status === 201) {
                    that.setState({
                        movements: [
                            ...that.state.movements,
                            { actions: [], movement: result.body }
                        ],
                        actions: {
                            ...that.state.actions,
                            [result.body.id]: {}
                        }
                    });
                } else if (that.props.onError) {
                    that.props.onError(
                        ServerErrorMessages[result.status] ||
                            "Не удалось добавить перевозчика в маршрут"
                    );
                }
            })
            .catch(() => {
                if (that.props.onError) {
                    that.props.onError(
                        "Не удалось добавить перевозчика в маршрут"
                    );
                }
            });
    }

    changeCarrier(event) {
        const that = this;
        const carrierId = parseInt(event.target.value, 10);
        const movementId = Number(
            event.currentTarget.getAttribute("movementid")
        );

        authorizedFetch(
            `${config.protocolAndHost}/api/data/movements/${movementId}`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    carrierId: carrierId
                })
            }
        )
            .then(response => {
                return Promise.all([
                    response.status,
                    response.json()
                ]).then(([status, body]) => ({ body, status }));
            })
            .then(result => {
                if (result.status === 200) {
                    const movements = [...that.state.movements];
                    const changedMovement = movements.find(
                        mv => mv.movement.id === movementId
                    );
                    changedMovement.movement.carrierId = carrierId;

                    that.setState({
                        movements
                    });
                } else if (that.props.onError) {
                    that.props.onError(
                        ServerErrorMessages[result.status] ||
                            "Не удалось изменить точку"
                    );
                }
            })
            .catch(() => {
                if (that.props.onError) {
                    that.props.onError("Не удалось изменить точку");
                }
            });
    }
}

function prepareActions(movements) {
    const result = {};

    movements.forEach(movement => {
        result[movement.movement.id] = result[movement.movement.id] || {};

        movement.actions.forEach(action => {
            result[movement.movement.id][action.matterId] = action;
        });
    });

    return result;
}
