import React, { PureComponent } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import { AlertDanger } from "./alerts/AlertDanger";
import { authorizedFetch } from "../utils/authorizedFetch";
import config from "../config";
import { ServerErrorMessages } from "../types/serverErrorMessages";
import { FormInput } from "./FormInput";

const emptyState = {
    error: null,
    firstName: "",
    lastName: "",
    vkLink: "",
    addToEvent: true
};

export class NewVolunteerModal extends PureComponent {
    state = emptyState;

    constructor(props) {
        super(props);

        this.focusInputRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            this.setState(emptyState);
            this.focusInputRef.current.focus();
        }
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                onClick={e => e.stopPropagation()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Новый волонтёр</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.error && (
                        <AlertDanger text={this.state.error} />
                    )}
                    <Form.Group key="firstName">
                        <Form.Label>Имя</Form.Label>
                        <FormInput
                            placeholder="Имя"
                            name="firstName"
                            value={this.state.firstName}
                            extra={{ name: "firstName" }}
                            onChange={this.onInputChange}
                            inputRef={this.focusInputRef}
                        />
                    </Form.Group>
                    <Form.Group key="lastName">
                        <Form.Label>Фамилия</Form.Label>
                        <FormInput
                            placeholder="Фамилия"
                            name="lastName"
                            value={this.state.lastName}
                            extra={{ name: "lastName" }}
                            onChange={this.onInputChange}
                        />
                    </Form.Group>
                    <Form.Group key="vkLink">
                        <Form.Label>Ссылка ВК</Form.Label>
                        <FormInput
                            placeholder="https://vk.com/user"
                            name="vkLink"
                            value={this.state.vkLink}
                            extra={{ name: "vkLink" }}
                            onChange={this.onInputChange}
                        />
                    </Form.Group>
                    <Form.Group key="include">
                        <Form.Check
                            id="add-to-event"
                            type="checkbox"
                            checked={this.state.addToEvent}
                            onChange={this.onChangeAddToEvent}
                            label={
                                <>
                                    Волонтёр точки <b>{this.props.pointName}</b>{" "}
                                    на акции <b>{this.props.eventDate}</b>
                                </>
                            }
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.onHide}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={this.onSave}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    onSave = () => {
        if (
            !(
                (this.state.firstName && this.state.lastName) ||
                this.state.vkLink
            )
        ) {
            this.setState({
                error:
                    "Пожалуйста, укажите имя и фамилию или ссылку на ВК волонтёра"
            });
            return;
        }

        const that = this;

        authorizedFetch(`${config.protocolAndHost}/api/data/persons`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                vkLink: this.state.vkLink
            })
        })
            .then(response => {
                return Promise.all([
                    response.status,
                    response.json()
                ]).then(([status, body]) => ({ ...body, status }));
            })
            .then(result => {
                if (result.status === 201) {
                    if (this.state.addToEvent) {
                        const personId = result.id;

                        authorizedFetch(
                            `${config.protocolAndHost}/api/data/pointvolunteers`,
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    pointId: this.props.pointId,
                                    personId: personId,
                                    comment: null
                                })
                            }
                        )
                            .then(response => {
                                return Promise.all([
                                    response.status,
                                    response.json()
                                ]).then(([status, body]) => ({ body, status }));
                            })
                            .then(result => {
                                if (result.status === 201) {
                                    that.props.onSave(result);
                                    that.props.onHide();
                                } else {
                                    this.setState({
                                        error:
                                            ServerErrorMessages[
                                                result.status
                                            ] ||
                                            "Не удалось добавить волонтёра к текущей акции"
                                    });
                                }
                            })
                            .catch(e => {
                                // TODO: add more informative messages about errors
                                this.setState({
                                    error:
                                        "Не удалось добавить волонтёра к текущей акции: " +
                                        e
                                });
                            });
                    } else {
                        that.props.onSave(result);
                        that.props.onHide();
                    }
                } else if (result.code === 400004) {
                    that.setState({
                        error: "Пользователь с таким VK ID уже существует"
                    });
                } else if (result.code === 400005) {
                    that.setState({
                        error: "Пользователь с таким именем уже существует"
                    });
                } else {
                    that.setState({
                        error:
                            ServerErrorMessages[result.status] ||
                            "Не удалось создать волонтёра"
                    });
                }
            })
            .catch(() => {
                that.setState({ error: "Не удалось создать волонтёра" });
            });
    };

    onHide = () => {
        this.props.onHide();
    };

    onInputChange = (value, extra) => {
        this.setState({ [extra.name]: value });
    };

    onChangeAddToEvent = () => {
        this.setState({ addToEvent: !this.state.addToEvent });
    };
}
