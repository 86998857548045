import React, { useState, useCallback, useEffect } from "react";
import { Button, Col, Row, Card, Collapse } from "react-bootstrap";
import { MdKeyboardArrowRight } from "react-icons/md";

import "./CollapsibleCard.css";

export function CollapsibleCard(props) {
    const [open, setOpen] = useState(props.isOpen);
    const memoizedClickHandler = useCallback(() => {
        setOpen(!open);
    }, [open]);

    useEffect(() => {
        setOpen(props.isOpen);
    }, [props.isOpen]);

    const cardClassName = [
        "collapsible-card",
        open ? "collapsible-card_opened" : "collapsible-card_closed",
        props.className
    ]
        .filter(cls => !!cls)
        .join(" ");

    const headerClassName =
        props.arrowPosition === "left"
            ? "pl-0  border-bottom-0"
            : "border-bottom-0";

    return (
        <Card className={cardClassName}>
            <Card.Header
                className={headerClassName}
                onClick={memoizedClickHandler}
                aria-expanded={open}
            >
                <Row className="d-flex align-items-center h-100">
                    {props.arrowPosition === "left" && (
                        <Col xs={1}>
                            <div className="align-self-center">
                                <Button
                                    variant="primary-outline"
                                    className="transparent"
                                    onClick={memoizedClickHandler}
                                    aria-expanded={open}
                                >
                                    <MdKeyboardArrowRight className="arrow" />
                                </Button>
                            </div>
                        </Col>
                    )}
                    <Col>
                        <div>{props.title}</div>
                        {!!props.subTitle && (
                            <div className="text-black-50">
                                {props.subTitle}
                            </div>
                        )}
                    </Col>
                    <Col xs={3} className="pr-0 pl-0">
                        {props.buttons}
                    </Col>
                    {props.arrowPosition === "right" && (
                        <Col xs={2}>
                            <div className="align-self-center">
                                <Button
                                    variant="primary-outline"
                                    className="transparent"
                                >
                                    <MdKeyboardArrowRight className="arrow" />
                                </Button>
                            </div>
                        </Col>
                    )}
                </Row>
            </Card.Header>
            <Collapse in={open}>{props.body}</Collapse>
        </Card>
    );
}
