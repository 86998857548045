import React from "react";

import {
    FieldUpdateTextInputPresenter,
    updateInputConnect
} from "./FieldUpdateTextInput";
import { SuggestWrapper } from "./SuggestWrapper";

class FieldUpdateTextInputWithSuggestPresenter extends FieldUpdateTextInputPresenter {
    constructor(props) {
        super(props);

        this.showSuggest = this.showSuggest.bind(this);
        this.hideSuggest = this.hideSuggest.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== this.state.value) {
            this.setState({ showSuggest: !Boolean(this.state.value) });
        }
    }

    render() {
        return (
            <SuggestWrapper
                suggestValues={this.props.suggestValues}
                showSuggest={this.state.showSuggest}
                onShow={this.showSuggest}
                onHide={this.hideSuggest}
                onChange={this.onSelectChange}
            >
                {super.render()}
            </SuggestWrapper>
        );
    }

    onError() {
        super.onError();

        this.hideSuggest();
    }

    showSuggest() {
        this.setState({ showSuggest: true });
    }

    hideSuggest() {
        this.setState({ showSuggest: false });
    }

    onSelectChange(value) {
        this.setState({ value }, super.saveValue);
    }
}

export const FieldUpdateTextInputWithSuggest = updateInputConnect(
    FieldUpdateTextInputWithSuggestPresenter
);
