export function throttle(func, ms) {
    let isThrottled = false,
        savedArgs;

    function wrapper() {
        if (isThrottled) {
            savedArgs = arguments;
            return;
        }

        func.apply(this, arguments);
        isThrottled = true;

        setTimeout(function() {
            isThrottled = false;
            if (savedArgs) {
                wrapper.apply(this, savedArgs);
                savedArgs = null;
            }
        }, ms);
    }

    return wrapper;
}
