import React, { PureComponent } from "react";
import {
    Button,
    Col,
    Container,
    Form,
    FormControl,
    InputGroup,
    Row,
    Table
} from "react-bootstrap";
import { FaFilter, FaSearch } from "react-icons/fa";

import { FieldUpdateTextInput } from "../FieldUpdateTextInput";
import { DeleteEntityModal } from "../DeleteEntityModal";
// TODO: use EditBasePointModal for creation too
import { NewBasePointModal } from "./NewBasePointModal";
import { EditBasePointModal } from "./EditBasePointModal";

import { getData } from "../../utils/getData";
import config from "../../config";
import { authorizedFetch } from "../../utils/authorizedFetch";
import { ServerErrorMessages } from "../../types/serverErrorMessages";

import "./PointsView.css";

const searchTextKey = "dispatcher_points_searchText";
const isTableFilteredKey = "dispatcher_points_isTableFiltered";

export class PointsView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            points: [],
            basePoints: props.basePoints,
            shownPoints: [],
            pointsMap: {},
            searchText: localStorage.getItem(searchTextKey) || "",
            isTableFiltered: Boolean(localStorage.getItem(isTableFilteredKey))
        };

        this.addNewBasePoint = this.addNewBasePoint.bind(this);
        this.showNewBasePointModal = this.showNewBasePointModal.bind(this);
        this.hideNewBasePointModal = this.hideNewBasePointModal.bind(this);
        this.deletePoint = this.deletePoint.bind(this);
        this.showDeletePointModal = this.showDeletePointModal.bind(this);
        this.hideDeletePointModal = this.hideDeletePointModal.bind(this);
        this.showEditBasePointModal = this.showEditBasePointModal.bind(this);
        this.hideEditBasePointModal = this.hideEditBasePointModal.bind(this);
        this.getUnusedBasePoints = this.getUnusedBasePoints.bind(this);
        this.updateBasePoint = this.updateBasePoint.bind(this);
        this.searchPoints = this.searchPoints.bind(this);
        this.filterByEvent = this.filterByEvent.bind(this);
        this.updateShownPoints = this.updateShownPoints.bind(this);
        this.addPointToEvent = this.addPointToEvent.bind(this);
    }

    componentDidMount() {
        this.updatePoints();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedEvent !== this.props.selectedEvent) {
            this.setState({
                basePoints: this.props.basePoints
            });

            this.updatePoints();
        }

        if (this.state.basePoints.length !== this.props.basePoints.length) {
            this.setState(
                {
                    basePoints: this.props.basePoints
                },
                this.updateShownPoints
            );
        }

        if (prevState.searchText !== this.state.searchText) {
            localStorage.setItem(searchTextKey, this.state.searchText);
        }

        if (prevState.isTableFiltered !== this.state.isTableFiltered) {
            if (this.state.isTableFiltered) {
                localStorage.setItem(isTableFilteredKey, "yes");
            } else {
                localStorage.removeItem(isTableFilteredKey);
            }
        }
    }

    render() {
        return (
            <Container fluid>
                <div className="points-view">
                    <Row className="mb-3 pt-4 pr-3">
                        <Col>
                            <h3>Точки</h3>
                        </Col>
                        {this.renderAddNewBasePointButton()}
                    </Row>

                    {this.renderPoints()}
                    <NewBasePointModal
                        show={this.state.showNewBasePointModal}
                        onHide={this.hideNewBasePointModal}
                        onSave={this.addNewBasePoint}
                    />
                </div>
            </Container>
        );
    }

    renderPoints() {
        return (
            <Table responsive>
                <thead className="thead-light">
                    <tr>
                        <th key="name" className="wide-col align-middle">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <FaSearch />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    type="text"
                                    placeholder="Название"
                                    aria-label="Поиск по названию точки"
                                    value={this.state.searchText}
                                    onChange={this.searchPoints}
                                />
                            </InputGroup>
                        </th>
                        <th key="isInEvent" className="align-middle">
                            Участие в акции
                            <Button
                                variant="outline-dark"
                                className="border-0 shadow-none"
                                onClick={this.filterByEvent}
                                active={this.state.isTableFiltered}
                            >
                                <FaFilter />
                            </Button>
                        </th>
                        <th key="workingHours" className="align-middle">
                            Время работы
                        </th>
                        <th key="managerName" className="align-middle">
                            Координатор
                        </th>
                        <th key="managerPhone" className="align-middle">
                            Телефон координатора
                        </th>
                        <th key="linkVk" className="align-middle">
                            VK координатора
                        </th>
                        <th key="comment" className="align-middle">
                            Комментарий
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.shownPoints.map(basePoint =>
                        this.renderPoint(basePoint)
                    )}
                </tbody>
                {this.renderDeletePointModal()}
                {this.state.toEditBasePoint && this.renderEditBasePointModal()}
            </Table>
        );
    }

    renderPoint(basePoint) {
        const unusedBasePoints = this.getUnusedBasePoints();

        if (
            unusedBasePoints.find(
                unusedBasePoint => unusedBasePoint.id === basePoint.id
            ) ||
            !this.state.pointsMap[basePoint.id]
        ) {
            return (
                <tr key={basePoint.id} className="bg-light">
                    <td key="name" className="wide-col">
                        <Button
                            variant="link "
                            className="p-0 m-0 point-name"
                            data-base-point-id={basePoint.id}
                            onClick={this.showEditBasePointModal}
                        >
                            {basePoint.name}
                        </Button>
                    </td>
                    <td key="isInEvent">
                        <Form.Check
                            aria-label="isInEvent"
                            checked={false}
                            data-base-point-id={basePoint.id}
                            onChange={this.addPointToEvent}
                        />
                    </td>
                    <td key="workingHours" />
                    <td key="managerName" />
                    <td key="managerPhone" />
                    <td key="linkVk" />
                    <td key="comment" />
                </tr>
            );
        }

        const point = this.state.pointsMap[basePoint.id];
        return (
            <tr key={point.id}>
                <td key="name" className="wide-col">
                    <Button
                        variant="link"
                        className="p-0 m-0 point-name"
                        data-base-point-id={basePoint.id}
                        onClick={this.showEditBasePointModal}
                    >
                        {basePoint.name}
                    </Button>
                </td>
                <td key="isInEvent">
                    <Form.Check
                        aria-label="isInEvent"
                        checked={true}
                        data-point-id={point.id}
                        data-point-name={basePoint.name}
                        onChange={this.showDeletePointModal}
                    />
                </td>
                <td key="workingHours">
                    <FieldUpdateTextInput
                        value={point.workingHours}
                        placeholder="Время работы"
                        url={`points/${point.id}`}
                        fieldName="workingHours"
                    />
                </td>
                <td key="managerName">
                    <FieldUpdateTextInput
                        value={point.managerName}
                        placeholder="Координатор"
                        url={`points/${point.id}`}
                        fieldName="managerName"
                    />
                </td>
                <td key="managerPhone">
                    <FieldUpdateTextInput
                        value={point.managerPhone}
                        placeholder="8 900 000 00 00"
                        url={`points/${point.id}`}
                        patternType="phone"
                        fieldName="managerPhone"
                    />
                </td>
                <td key="linkVk">
                    <FieldUpdateTextInput
                        value={point.linkVk}
                        placeholder="VK"
                        url={`points/${point.id}`}
                        fieldName="linkVk"
                    />
                </td>
                <td key="comment">
                    <FieldUpdateTextInput
                        value={point.comment}
                        placeholder="Комментарий"
                        url={`points/${point.id}`}
                        fieldName="comment"
                        multiline
                    />
                </td>
            </tr>
        );
    }

    renderAddNewBasePointButton() {
        return (
            <span className="float-right">
                <Button
                    className="addNewPoint-button"
                    variant="primary"
                    onClick={this.showNewBasePointModal}
                >
                    Создать точку
                </Button>
            </span>
        );
    }

    renderDeletePointModal() {
        return (
            <DeleteEntityModal
                key="deletePoint"
                show={this.state.showDeletePointModal}
                title="Внимание"
                entityType="points"
                entityName={
                    <span>
                        Удалить точку <b>{this.state.toDeletePointName}</b> из
                        текущей акции? Вся логистика точки будет удалена.
                    </span>
                }
                entityId={this.state.toDeletePointId}
                onHide={this.hideDeletePointModal}
                onDelete={this.deletePoint}
            />
        );
    }

    renderEditBasePointModal() {
        return (
            <EditBasePointModal
                show={this.state.showEditBasePointModal}
                onHide={this.hideEditBasePointModal}
                onSave={this.updateBasePoint}
                basePoint={this.state.toEditBasePoint}
            />
        );
    }

    updateBasePoint(updatedBasePoint) {
        this.props.onBasePointsChange();
        const updatedBasePoints = [...this.state.basePoints];
        const updatedBasePointIndex = updatedBasePoints.findIndex(
            item => item.id === updatedBasePoint.id
        );
        updatedBasePoints[updatedBasePointIndex] = updatedBasePoint;

        this.setState(
            {
                basePoints: updatedBasePoints
            },
            this.updateShownPoints
        );
    }

    addNewBasePoint(newBasePoint) {
        this.props.onBasePointsChange();
        this.setState(
            {
                basePoints: [...this.state.basePoints, newBasePoint]
            },
            this.updateShownPoints
        );
    }

    showNewBasePointModal() {
        this.setState({
            showNewBasePointModal: true
        });
    }

    hideNewBasePointModal() {
        this.setState({ showNewBasePointModal: false });
    }

    showEditBasePointModal(event) {
        const basePointId = Number(
            event.currentTarget.getAttribute("data-base-point-id")
        );
        const basePoint = this.state.shownPoints.find(
            item => item.id === basePointId
        );

        this.setState({
            showEditBasePointModal: true,
            toEditBasePoint: basePoint
        });
    }

    hideEditBasePointModal() {
        this.setState({ showEditBasePointModal: false, toEditBasePoint: null });
    }

    updatePoints() {
        this.props.onPointsChange();
        getData({
            url: `${config.protocolAndHost}/api/data/points?eventId=${this.props.selectedEvent}`
        })
            .then(result => {
                const pointsMap = result.reduce((acc, item) => {
                    acc[item.basePointId] = item;
                    return acc;
                }, {});

                this.setState(
                    {
                        points: result,
                        pointsMap
                    },
                    this.updateShownPoints
                );
            })
            .catch(() => {
                this.props.onError("Не удалось получить данные о точках");
            });
    }

    addPointToEvent(event) {
        const basePointId = Number(
            event.currentTarget.getAttribute("data-base-point-id")
        );

        authorizedFetch(`${config.protocolAndHost}/api/data/points`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                eventId: this.props.selectedEvent,
                workingHours: "",
                managerName: "",
                managerPhone: "",
                linkVk: "",
                comment: "",
                basePointId: basePointId
            })
        })
            .then(response => {
                return Promise.all([
                    response.status,
                    response.json()
                ]).then(([status, body]) => ({ ...body, status }));
            })
            .then(result => {
                if (result.status === 201) {
                    this.updatePoints();
                } else {
                    this.setState({
                        error:
                            ServerErrorMessages[result.status] ||
                            "Не удалось добавить точку"
                    });
                }
            })
            .catch(() => {
                // TODO: add more informative messages about errors
                this.setState({ error: "Не удалось добавить точку" });
            });
    }

    getUnusedBasePoints() {
        return this.state.basePoints.filter(basePoint =>
            this.state.points.every(point => point.basePointId !== basePoint.id)
        );
    }

    showDeletePointModal(event) {
        const pointId = Number(
            event.currentTarget.getAttribute("data-point-id")
        );
        const pointName = event.currentTarget.getAttribute("data-point-name");

        this.setState({
            showDeletePointModal: true,
            toDeletePointId: pointId,
            toDeletePointName: pointName
        });
    }

    hideDeletePointModal() {
        this.setState({
            showDeletePointModal: false,
            toDeletePointId: null,
            toDeletePointName: null
        });
    }

    deletePoint() {
        this.props.onPointsChange();
        this.setState(
            {
                points: this.state.points.filter(
                    item => item.id !== this.state.toDeletePointId
                )
            },
            this.updateShownPoints
        );
    }

    searchPoints(event) {
        const searchText = event.target.value.toLowerCase();

        this.setState(
            {
                searchText: searchText
            },
            this.updateShownPoints
        );
    }

    filterByEvent() {
        this.setState(
            {
                isTableFiltered: !this.state.isTableFiltered
            },
            this.updateShownPoints
        );
    }

    updateShownPoints() {
        const unusedPoints = this.state.isTableFiltered
            ? this.getUnusedBasePoints().map(item => item.id)
            : [];
        const shownPoints = this.state.basePoints.filter(
            item =>
                unusedPoints.indexOf(item.id) === -1 &&
                (!this.state.searchText ||
                    item.name.toLowerCase().includes(this.state.searchText))
        );

        this.setState({
            shownPoints
        });
    }
}
