import React, { PureComponent } from "react";
import {
    Button,
    Col,
    Container,
    FormControl,
    ListGroup,
    Row,
    Spinner
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { AlertDanger } from "../components/alerts/AlertDanger";
import { NewVolunteerModal } from "../components/NewVolunteerModal";

import { getData } from "../utils/getData";
import config from "../config";

import "./Volunteers.css";
import { VolunteerItem } from "./VolunteerItem";

export class Volunteers extends PureComponent {
    state = {
        error: null,
        isLoaded: false,
        pointName: "...",
        eventDate: "...",
        volunteers: [],
        loading: false,
        volunteersCount: undefined,
        searchText: "",
        showNewVolunteerModal: false,
        limit: 10,
        offset: 0,
        total: 0
    };

    constructor(props) {
        super(props);

        this.pointId = props.match.params.pointId;

        this.observerRef = React.createRef();

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onSelectVolunteer = this.onSelectVolunteer.bind(this);
        this.onNewVolunteerAdded = this.onNewVolunteerAdded.bind(this);
        this.onEditVolunteer = this.onEditVolunteer.bind(this);
        this.onError = this.onError.bind(this);
        this.showNewVolunteerModal = this.showNewVolunteerModal.bind(this);
        this.hideNewVolunteerModal = this.hideNewVolunteerModal.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.navigateForward = this.navigateForward.bind(this);
        this.initIntersectionObserver = this.initIntersectionObserver.bind(
            this
        );
        this.submitSearch = this.submitSearch.bind(this);
        this.resetSearch = this.resetSearch.bind(this);

        this.searchQueue = [];
    }

    componentDidMount() {
        this.submitSearch(
            null,
            0,
            this.state.limit,
            this.initIntersectionObserver
        );

        getData({
            url: `${config.protocolAndHost}/api/view/coordinator/points/${this.pointId}`
        }).then(result => {
            this.setState(state => ({
                pointName: result.basePoint.name,
                eventDate: result.event.date
            }));
        });
    }

    componentWillUnmount() {
        this.observerRef.current.disconnect();
    }

    render() {
        const {
            error,
            isLoaded,
            pointName,
            eventDate,
            volunteers,
            volunteersCount,
            searchText,
            total
        } = this.state;

        if (error) {
            return (
                <Container>
                    <AlertDanger text={error.message} />
                </Container>
            );
        } else if (!isLoaded) {
            return (
                <div className="spinner-wrapper">
                    <Spinner
                        animation="border"
                        role="status"
                        variant="secondary"
                    >
                        <span className="sr-only">Загрузка...</span>
                    </Spinner>
                </div>
            );
        } else {
            return (
                <Container className="pl-0 pr-0">
                    <div className="pl-3 pr-3">
                        <Link to={`/points/${this.pointId}`}>{pointName}</Link>
                        <span>&nbsp;/ Волонтёры {eventDate}</span>
                    </div>
                    <div className="list-header pt-3 pb-3">
                        <Row className="mb-2 ml-0 mr-0">
                            <div className="search-panel">
                                <FormControl
                                    key="search"
                                    type="search"
                                    placeholder="Поиск"
                                    aria-label="Поиск"
                                    value={searchText}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <div className="add-button-container">
                                <Button
                                    className="add-button border-0 shadow-none float-right"
                                    onClick={this.showNewVolunteerModal}
                                >
                                    <FaPlus />
                                </Button>
                            </div>
                        </Row>
                        <Row className="mt-1 mb-1 text-black-50">
                            <Col>Выбрано: {volunteersCount}</Col>
                            <Col className="text-right">Найдено: {total}</Col>
                        </Row>
                    </div>

                    <ListGroup key="list" className="volunteers">
                        {volunteers.map(item => (
                            <VolunteerItem
                                key={item.person.id}
                                data={item}
                                pointId={this.pointId}
                                onSelect={this.onSelectVolunteer}
                                onEdit={this.onEditVolunteer}
                            />
                        ))}
                        <div className="leader" id="observedItem">
                            {this.state.loading && (
                                <div className="flex-center pt-3">
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        variant="secondary"
                                    ></Spinner>
                                </div>
                            )}
                        </div>
                    </ListGroup>
                    {this.renderNewVolunteerModal()}
                </Container>
            );
        }
    }

    renderNewVolunteerModal() {
        return (
            <NewVolunteerModal
                show={this.state.showNewVolunteerModal}
                onSave={this.onNewVolunteerAdded}
                onHide={this.hideNewVolunteerModal}
                onError={this.onError}
                pointId={this.pointId}
                pointName={this.state.pointName}
                eventDate={this.state.eventDate}
            />
        );
    }

    onNewVolunteerAdded(volunteer) {
        this.setState(
            {
                volunteers: [],
                offset: 0
            },
            () => this.resetSearch(this.state.searchText, 0, 10)
        );
    }

    showNewVolunteerModal() {
        window.history.pushState(
            { AddVolunteerModal: 1 },
            window.document.title
        );

        this.setState({ showNewVolunteerModal: true });
    }

    hideNewVolunteerModal() {
        window.history.back();
        this.setState({ showNewVolunteerModal: false, volunteer: null });
    }

    onEditVolunteer(volunteer) {
        // TODO: update Google stats
    }

    onSelectVolunteer(volunteer) {
        this.setState({
            volunteersCount:
                this.state.volunteersCount + (!!volunteer.link ? +1 : -1)
        });

        // TODO: update Google stats
    }

    onChangeHandler(event) {
        const searchText = event.target.value.toLowerCase();
        this.setState(
            {
                searchText,
                limit: 10,
                offset: 0,
                volunteers: []
            },
            () => {
                this.resetSearch(searchText, 0, this.state.limit);
            }
        );
    }

    getSelectedVolunteersCount(volunteers) {
        return volunteers.filter(item => !!item.link).length;
    }

    submitSearch(searchText, offset, limit, callback) {
        this.searchQueue.push({ searchText, offset, limit, callback });
        if (!this.state.loading) {
            this.setState({ loading: true }, this.fetchData);
        }
    }

    resetSearch(text, offset, limit) {
        this.searchQueue = [];
        this.submitSearch(text, offset, limit);
    }

    fetchData() {
        const lastSearchTime = this.lastSearchTime || new Date(0);
        const elapsedTime = new Date() - lastSearchTime;

        if (elapsedTime < 1000) {
            return setTimeout(this.fetchData, 1000 - elapsedTime + 100);
        }

        this.lastSearchTime = new Date();

        const { searchText, offset, limit, callback } = this.searchQueue[0];
        this.searchQueue = this.searchQueue.slice(1);

        const append = offset > 0;

        let queryParams = "";

        if (limit) {
            queryParams += `&limit=${limit}&offset=${offset}`;
        }

        if (!!searchText) {
            queryParams += `&search=${searchText}`;
        }

        const url = `${config.protocolAndHost}/api/view/volunteers?pointId=${this.pointId}${queryParams}`;

        return getData({ url })
            .then(result => {
                result.items = result.items || [];

                const volunteers = append
                    ? unique([...this.state.volunteers, ...result.items])
                    : result.items;

                this.setState({
                    isLoaded: true,
                    volunteers,
                    volunteersCount: this.getSelectedVolunteersCount(
                        volunteers
                    ),
                    total: result.total || 0
                });

                if (callback) {
                    callback();
                }

                if (this.searchQueue.length > 0) {
                    this.fetchData();
                } else {
                    this.setState({
                        loading: false
                    });
                }
            })
            .catch(error => {
                this.setState({
                    isLoaded: true,
                    loading: false,
                    error
                });
            });
    }

    onError(message) {
        this.setState({ error: message });
    }

    navigateForward() {
        this.setState(
            {
                offset: this.state.offset + this.state.limit
            },
            () =>
                this.submitSearch(
                    this.state.searchText,
                    this.state.offset,
                    this.state.limit
                )
        );
    }

    initIntersectionObserver() {
        let options = {
            root: null,
            rootMargin: "0px",
            threshold: [0.9]
        };

        const callback = () => {
            if (this.state.volunteers.length < this.state.total) {
                this.navigateForward();
            }
        };

        let observer = new IntersectionObserver(callback, options);
        this.observerRef.current = observer;

        const item = document.querySelector("#observedItem");
        this.observerRef.current.observe(item);
    }
}

// !! For volunteers data type only
function unique(items) {
    if (!items || !Array.isArray(items)) return [];
    if (!items[0]?.person?.id) return [];

    let seenIds = {};

    return items.filter(item => {
        const personId = item.person.id;
        return seenIds.hasOwnProperty(personId)
            ? false
            : (seenIds[personId] = true);
    });
}
