import React from "react";

import {
    FieldUpdateTextInputPresenter,
    updateInputConnect
} from "./FieldUpdateTextInput";

class CarNumberUpdateInputPresenter extends FieldUpdateTextInputPresenter {
    onChangeHandler(event) {
        let value = event.target.value;

        value = value.toLowerCase();

        if (
            value.length > this.state.value.length &&
            /\d[хреновмускат]{2}$/.test(value)
        ) {
            value += " ";
        }

        event.target.value = value;

        super.onChangeHandler(event);
    }
}

const CarNumberUpdateInputConnected = updateInputConnect(
    CarNumberUpdateInputPresenter
);

export function CarNumberUpdateInput(props) {
    return (
        <CarNumberUpdateInputConnected
            {...props}
            patternType="carNumber"
            placeholder={props.placeholder || "х000хх 000"}
        />
    );
}
