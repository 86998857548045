import React, { PureComponent } from "react";
import { FieldUpdateTextInput } from "../components/FieldUpdateTextInput";
import { ListGroup, Spinner } from "react-bootstrap";
import "./VolunteerItem.css";
import { EditVolunteerModal } from "../components/EditVolunteerModal";
import { authorizedFetch } from "../utils/authorizedFetch";
import { ServerErrorMessages } from "../types/serverErrorMessages";
import config from "../config";

export class VolunteerItem extends PureComponent {
    state = {};

    constructor(props) {
        super(props);

        this.state = {
            person: props.data.person,
            basePointNames: props.data.basePointNames,
            link: props.data.link,
            selected: !!props.data.link,
            updating: false
        };

        this.onSelect = this.onSelect.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.createLink = this.createLink.bind(this);
        this.deleteLink = this.deleteLink.bind(this);
        this.onHideEditVolunteerModal = this.onHideEditVolunteerModal.bind(
            this
        );
        this.onUpdateVolunteer = this.onUpdateVolunteer.bind(this);
    }

    render() {
        const item = this.state;

        return (
            <ListGroup.Item
                key={item.person.id}
                data-id={item.person.id}
                className={"item" + (item.selected ? " item-selected" : "")}
                onClick={this.onSelect}
            >
                <div
                    className={
                        "updating-spinner" + (item.updating ? "" : " hidden")
                    }
                >
                    <Spinner
                        animation="border"
                        role="status"
                        variant="secondary"
                    />
                </div>
                <div>
                    <img
                        src={item.person.vkPhoto ? item.person.vkPhoto : null}
                        alt=""
                        className={
                            "avatar" +
                            (!item.person.vkPhoto ? " no-avatar" : "")
                        }
                        data-item={JSON.stringify(item.person)}
                        onClick={this.onEdit}
                    />
                </div>
                <div className="item-name">
                    {`${item.person.firstName} ${item.person.lastName}`}
                    <div className="text-muted small">
                        {item.basePointNames.join(", ")}
                    </div>
                    {item.link &&
                        (item.selected ? (
                            <div
                                className="mt-2"
                                onClick={e => e.stopPropagation()}
                            >
                                <FieldUpdateTextInput
                                    value={item.link.comment}
                                    onInput={value =>
                                        (item.link.comment = value)
                                    }
                                    placeholder="Комментарий"
                                    url={`pointvolunteers/${item.link.id}`}
                                    fieldName="comment"
                                    maxLength={255}
                                    multiline
                                />
                            </div>
                        ) : (
                            <div className="mt-2">
                                <span className="deleted">
                                    {item.link.comment}
                                </span>
                            </div>
                        ))}
                </div>
                {this.state.showEditVolunteerModal && (
                    <EditVolunteerModal
                        show={this.state.showEditVolunteerModal}
                        volunteer={this.state.person}
                        onHide={this.onHideEditVolunteerModal}
                        onSave={this.onUpdateVolunteer}
                    />
                )}
            </ListGroup.Item>
        );
    }

    onSelect() {
        if (this.state.selected) {
            this.deleteLink();
        } else {
            this.createLink();
        }
    }

    createLink() {
        let volunteer = this.state;

        if (volunteer.updating) {
            return;
        }

        this.setState({ updating: true });

        const personId = volunteer.person.id;

        authorizedFetch(`${config.protocolAndHost}/api/data/pointvolunteers`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                pointId: this.props.pointId,
                personId: personId,
                comment: volunteer.link ? volunteer.link.comment : null
            })
        })
            .then(response => {
                return Promise.all([
                    response.status,
                    response.json()
                ]).then(([status, body]) => ({ body, status }));
            })
            .then(result => {
                if (result.status === 201) {
                    this.setState(
                        {
                            updating: false,
                            link: result.body,
                            selected: true
                        },
                        () => {
                            if (this.props.onSelect) {
                                this.props.onSelect(this.state);
                            }
                        }
                    );
                } else {
                    this.setState({
                        updating: false,
                        error:
                            ServerErrorMessages[result.status] ||
                            "Не удалось добавить волонтёра"
                    });
                }
            })
            .catch(() => {
                // TODO: add more informative messages about errors
                this.setState({
                    updating: false,
                    error: "Не удалось добавить волонтёра"
                });
            });
    }

    deleteLink() {
        let volunteer = this.state;

        if (volunteer.updating) {
            return;
        }

        this.setState({ updating: true });

        const that = this;
        const linkId = volunteer.link.id;

        authorizedFetch(
            `${config.protocolAndHost}/api/data/pointvolunteers/${linkId}`,
            {
                method: "DELETE"
            }
        )
            .then(response => {
                if (response.status === 204) {
                    this.setState(
                        {
                            link: null,
                            updating: false,
                            selected: false
                        },
                        () => {
                            if (this.props.onSelect) {
                                this.props.onSelect(this.state);
                            }
                        }
                    );
                } else {
                    this.setState(
                        {
                            updating: false
                        },
                        () => {
                            if (this.props.onError) {
                                this.props.onError(
                                    ServerErrorMessages[response.status] ||
                                        "Не удалось удалить волонтёра"
                                );
                            }
                        }
                    );
                }
            })
            .catch(() => {
                this.setState(
                    {
                        updating: false
                    },
                    () => {
                        if (that.props.onError) {
                            that.props.onError("Не удалось удалить волонтёра");
                        }
                    }
                );
            });
    }

    onEdit(event) {
        event.stopPropagation();

        const person = this.state.person;
        window.history.pushState({ person }, window.document.title);
        this.setState({ showEditVolunteerModal: true });
    }

    onHideEditVolunteerModal() {
        window.history.back();
        this.setState({ showEditVolunteerModal: false });
    }

    onUpdateVolunteer(newVolunteerPerson) {
        this.setState(
            {
                person: newVolunteerPerson
            },
            () => {
                if (this.props.onEdit) {
                    this.props.onEdit(this.state);
                }
            }
        );
    }
}
