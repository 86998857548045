import React, { PureComponent } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import { FormInput } from "../FormInput";
import { AlertDanger } from "../alerts/AlertDanger";

import { ServerErrorMessages } from "../../types/serverErrorMessages";
import { authorizedFetch } from "../../utils/authorizedFetch";
import config from "../../config";

export class EditBasePointModal extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.basePoint.id,
            name: this.props.basePoint.name,
            address: this.props.basePoint.address,
            district: this.props.basePoint.district,
            latitude: this.props.basePoint.latitude,
            longitude: this.props.basePoint.longitude,
            vkGroupUrl: this.props.basePoint.vkGroupUrl,
            recycler: this.props.basePoint.recycler
        };

        this.onHide = this.onHide.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.resetRefs = this.resetRefs.bind(this);
        this.createRef = this.createRef.bind(this);
    }

    resetRefs() {
        this.inputRefs = [];
    }

    createRef() {
        const reference = React.createRef();
        this.inputRefs.push(reference);
        return reference;
    }

    render() {
        this.resetRefs();

        return (
            <Modal show={this.props.show} onHide={this.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Основная информация</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.error && (
                        <AlertDanger text={this.state.error} />
                    )}

                    <Form.Group key="name">
                        <Form.Label>Название</Form.Label>
                        <FormInput
                            placeholder="Название"
                            extra={{ name: "name" }}
                            value={this.state.name}
                            onSubmit={this.onSave}
                            onChange={this.onInputChange}
                            inputRef={this.createRef()}
                            required={true}
                        />
                    </Form.Group>

                    <Form.Group key="address">
                        <Form.Label>Адрес</Form.Label>
                        <FormInput
                            placeholder="Адрес"
                            extra={{ name: "address" }}
                            value={this.state.address}
                            onSubmit={this.onSave}
                            onChange={this.onInputChange}
                            inputRef={this.createRef()}
                            required={false}
                        />
                    </Form.Group>

                    <Form.Group key="district">
                        <Form.Label>Район</Form.Label>
                        <FormInput
                            placeholder="Район"
                            extra={{ name: "district" }}
                            value={this.state.district}
                            onSubmit={this.onSave}
                            onChange={this.onInputChange}
                            inputRef={this.createRef()}
                            required={false}
                        />
                    </Form.Group>

                    <Form.Group key="latitude">
                        <Form.Label>Широта</Form.Label>
                        <FormInput
                            placeholder="Широта"
                            extra={{ name: "latitude" }}
                            value={this.state.latitude}
                            onSubmit={this.onSave}
                            onChange={this.onInputChange}
                            inputRef={this.createRef()}
                            required={true}
                        />
                    </Form.Group>

                    <Form.Group key="longitude">
                        <Form.Label>Долгота</Form.Label>
                        <FormInput
                            placeholder="Долгота"
                            extra={{ name: "longitude" }}
                            value={this.state.longitude}
                            onSubmit={this.onSave}
                            onChange={this.onInputChange}
                            inputRef={this.createRef()}
                            required={true}
                        />
                    </Form.Group>

                    <Form.Group key="vkGroupUrl">
                        <Form.Label>Группа в ВК</Form.Label>
                        <FormInput
                            placeholder="Группа в ВК"
                            extra={{ name: "vkGroupUrl" }}
                            value={this.state.vkGroupUrl}
                            onSubmit={this.onSave}
                            onChange={this.onInputChange}
                            inputRef={this.createRef()}
                            required={false}
                        />
                    </Form.Group>

                    <Form.Group key="recycler">
                        <Form.Check
                            id="checkbox-recycler"
                            type="checkbox"
                            checked={this.state.recycler}
                            onChange={this.onCheckboxChange}
                            label="Является переработчиком"
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.onHide}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={this.onSave}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    onHide() {
        this.props.onHide();
    }

    onSave() {
        const that = this;

        const requiredFieldsFilled = this.inputRefs.every(({ current }) => {
            if (current.required && !current.value) {
                this.setState({
                    error: "Укажите значение"
                });
                current.focus();
                return false;
            }

            return true;
        });

        if (!requiredFieldsFilled) {
            return;
        }

        authorizedFetch(
            `${config.protocolAndHost}/api/data/basepoints/${this.state.id}`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name: this.state.name,
                    address: this.state.address,
                    district: this.state.district,
                    latitude: parseFloat(this.state.latitude),
                    longitude: parseFloat(this.state.longitude),
                    vkGroupUrl: this.state.vkGroupUrl,
                    recycler: this.state.recycler
                })
            }
        )
            .then(response => {
                return Promise.all([
                    response.status,
                    response.json()
                ]).then(([status, body]) => ({ ...body, status }));
            })
            .then(result => {
                if (result.status === 200) {
                    that.props.onSave(result);
                    that.onHide();
                } else {
                    that.setState({
                        error:
                            ServerErrorMessages[result.status] ||
                            "Не удалось изменить информацию о точке"
                    });
                }
            })
            .catch(() => {
                // TODO: add more informative messages about errors
                that.setState({
                    error: "Не удалось изменить информацию о точке"
                });
            });
    }

    onInputChange(value, extra) {
        this.setState({ [extra.name]: value });
    }

    onCheckboxChange(event) {
        this.setState({ recycler: event.target.checked });
    }
}
