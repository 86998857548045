import React, { PureComponent } from "react";
import { FormControl } from "react-bootstrap";

import { KeyCodes } from "../types/keyCodes";

export class FormInput extends PureComponent {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    render() {
        return (
            <FormControl
                type="text"
                as={this.props.textarea ? "textarea" : "input"}
                placeholder={this.props.placeholder}
                value={this.props.value}
                ref={this.props.inputRef}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                required={this.props.required}
            />
        );
    }

    onChange(event) {
        const value = event.target.value;

        this.props.onChange(value, this.props.extra);
    }

    onKeyDown(event) {
        if (event.keyCode === KeyCodes.ENTER) {
            if (event.ctrlKey) {
                this.props.onChange(this.props.value + "\n", this.props.extra);
            } else {
                this.props.onSubmit();
                event.preventDefault();
            }
        }
    }
}
