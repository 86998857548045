import { Status } from "../types/status";

export function getStatus(timeArrivalActual, timeDepartureActual) {
    if (timeDepartureActual?.trim()) {
        return Status.DONE;
    }

    if (timeArrivalActual?.trim()) {
        return Status.RELOADING;
    }

    return Status.WAITING;
}
